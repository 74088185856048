import React from 'react';
import { Box, Stack } from '@mui/material';

export const ContentLayout = ({ children }) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 100%"
        bgcolor="#fff"
        maxWidth={1200}
        position={"relative"}
        mt={14.25}
        width={1}
      >
        <Stack
          px={{xs: 0, md: 6}}
          flex={1}
          gap={7}
          maxWidth={{xs: 664, md: "none"}}
          alignItems={{xs:"center", md: "flex-start"}}
          boxSizing={"border-box"}
          alignSelf={"center"}
          width={1}
        >
          {children}
        </Stack>
      </Box>
    </>
  );
};
