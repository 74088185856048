import React from 'react';

import { Link, Stack, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';

import { useAuth } from 'lib/auth';
import { isoToDateString } from 'utils/dateFormatter';
import { useCompanyFacID } from '../api/getFacID';
import { paths } from 'routes';
import { useFrameworkAgreementsSupplierFilesCount, useFrameworkAgreementsSupplierFilesCountQueries } from '../../frameworkAgreements/api/getFrameworkAgreementsSupplierFilesCount';
import { useFrameworkAgreementCompanyCount } from '../../frameworkAgreements/api/getFrameworkAgreementCompanyCount';
import { useFrameworkAgreementSuppliers } from '../../frameworkAgreements/api/getFrameworkAgreementSuppliers';
import { IconWrapper } from 'components/Icon';

export const RelatedFrameworkAgreementsListItem = ({ companyID, companyType, fa_name: name, Status: status, fa_id: id, ref_number, start_date, end_date, MainBuyer, value, promoted }) => {  
    const { account } = useAuth();
    const {
      //@ts-ignore
      data: { facID },
    } = useCompanyFacID(id, companyID, 
      {
        enabled: companyType === 'supplier',
      }
    );
    const {
      //@ts-ignore
      data: { count: filesCount },
    } = useFrameworkAgreementsSupplierFilesCount(id, facID,
      {
        enabled: companyType === 'supplier',
      }
    );
    const {
      //@ts-ignore
      data: { count: suppliersCount },
    } = useFrameworkAgreementCompanyCount(id, 'supplier',
      {
        enabled: companyType === 'buyer',
      }
    );

    const {
      data: { suppliers },
      isFetched,
    } = useFrameworkAgreementSuppliers(id, {
      includeInactive: account.super_admin ? true : false,
      limit: 200,
      queryKey: [`framework-agreement-${id}-files-suppliers`],
      queryOptions: {
        enabled: companyType === 'buyer',
      },
    });

    const facIds = suppliers.map(supplier => supplier.fac_id);
    const filesCountQueries = useFrameworkAgreementsSupplierFilesCountQueries(id, facIds, {enabled: companyType === 'buyer'});
    const allSuppliersFilesFetched = filesCountQueries.every(query => query.isFetched);
    const { suppliersFilesTotal, isSuppliersFilesTotalReady } = allSuppliersFilesFetched ?
    filesCountQueries.length === 0
    ?
    { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: true }
    :
    filesCountQueries.reduce(
      (acc, query, index, array) => {
        const count = query.data?.count || 0;
        acc.suppliersFilesTotal += count;

        if (index === array.length - 1 && allSuppliersFilesFetched) {
          acc.isSuppliersFilesTotalReady = true;
        }

        return acc;
      },
      { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false }
    ) :
    { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false };

    const totalFiles = isFetched && isSuppliersFilesTotalReady ? suppliersFilesTotal : 0;

    return (
      <ListCard>
        <ImageAvatar img={MainBuyer?.square_logo_url || ''} name={name} />
        <Stack flex={7} justifyContent="center">
          <Link
            component={RouterLink}
            to={`/app/framework-agreements/${id.toString()}`}
            color="inherit"
          >
            <Stack direction={'row'} gap={1}>
              <Typography variant="body1" fontWeight={700}>
                {name}
              </Typography>
              {promoted && (
                <IconWrapper
                  icon="rocket"
                  sx={({ palette }) => ({ svg: { width: 16, height: 16, stroke: palette.primary.main, color: palette.primary.main }, display: "flex" })}
                  height={16}
                  display={"flex"}
                  alignSelf={"center"}
                />
              )}
            </Stack>
          </Link>
          <Stack direction="row" gap={1}>
            <Typography variant="caption" fontWeight={600}>
              {ref_number}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{ borderColor: 'grey.500', height: 15, borderWidth: '1px' }}
            />
            <Typography variant="caption" color="text.primary" fontWeight={700}>
              <Link
                component={RouterLink}
                to={`/app/${MainBuyer?.Type}s/${MainBuyer?.company_id.toString()}`}
                color="inherit"
              >
                {MainBuyer?.Name}
              </Link> 
            </Typography>
          </Stack>
        </Stack>
        {companyType === 'buyer' && (
          <>
          <Stack justifyContent="center" sx={{pr:1, width: 100}}>
            <Typography variant="caption" fontWeight={700}>
              Value
            </Typography>
              <Typography variant="body2" fontWeight={700}>
                {`${value.length > 0 ? value : 0} MSEK`}
              </Typography>
          </Stack>
          <Stack justifyContent="center" sx={{pr:1, width: 60}}>
            <Typography variant="caption" fontWeight={700}>
              Suppliers
            </Typography>
            <Link
              component={RouterLink}
              to={`${paths.frameworkAgreementDetailsView(id)}?tab=suppliers`}
              color="inherit"
            >
              <Typography variant="body2" fontWeight={700}>
                {suppliersCount}
              </Typography>
            </Link>
          </Stack>
          </>
        )}
        <Stack justifyContent="center" sx={{pr:1, width: 60}}>
          <Typography variant="caption" fontWeight={700}>
            Files
          </Typography>
          <Link
            component={RouterLink}
            to={companyType === 'buyer' ? `${paths.frameworkAgreementDetailsView(id)}?tab=files` : `${paths.frameworkAgreementDetailsView(id)}?tab=files&subtab=${facID}`}
            color="inherit"
          >
            <Typography variant="body2" fontWeight={700}>
              {companyType === 'buyer' ? totalFiles : filesCount}
            </Typography>
          </Link>
        </Stack>
        <Stack justifyContent="center" sx={{pr:account.super_admin ? 1 : 0, width: 180}}>
          <Typography variant="caption" fontWeight={700}>
            Agreement Period
          </Typography>
        
          <Typography variant="body2" fontWeight={700}>
            {isoToDateString(start_date, false)} - {isoToDateString(end_date, false)}
          </Typography>
        </Stack>

        {account.super_admin && (
          <StatusComponent status={status} />
        )}
      </ListCard>
    );
  };
