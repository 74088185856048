import React, { useState } from 'react';
import { ContentLayout } from 'website/components/Layout/components/ContentLayout';
import { ContactUsForm } from 'website/components/ContactUsForm';
import { ContactUsThankYouScreen } from 'website/components/ContantUsThankYouScreen';
import { sendEmail } from 'website/api/sendEmail';

export const ContactUs = () => {
  const [ messageSent, setMessageSent ] = useState(false);

  const onSubmit = async (data) => {
    try {
      await sendEmail(data);
      setMessageSent(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContentLayout>
      {!messageSent ?
        <ContactUsForm onSubmit={onSubmit} />
        :
        <ContactUsThankYouScreen />
      }
    </ContentLayout>
  );
};
