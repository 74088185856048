import { axios } from "lib/axios"
import { auth } from "lib/firebase"
import { signInWithEmailAndPassword } from 'firebase/auth';
import { sendVerificationEmail } from './sendVerificationEmail';

export const loginWithEmailAndPassword = async (data, hasUsers = true) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, data.companyEmail, data.password);
    const user = userCredential.user;

    if (!user.emailVerified) {
      const account = await getAccountDataForVerification();
      if (account.data && account.data.status === 'deactivated') {
        const data = {
          "account_id": account.data.account_id,
          "user_id": account.data?.users[0].user_id,
          "company_id": account.data?.users[0].company_id,
          "shadow": account.data?.users[0]?.Company?.is_shadow,
        }

        await sendVerificationEmail(data);
        return {
          success: false,
          requiresVerification: true,
          message: "Email not verified. Verification email sent.",
        };
      }
    }


    let account;
    if (hasUsers) {
      account = await getAccountData();
    } else {
      account = await getAccountDataOnly();
    }

    return {
      success: true,
      data: account.data,
    };
  } catch (error) {
    if (error.code === 'auth/invalid-login-credentials') {
      throw new Error("Invalid credentials");
    } else if (error.code === 'auth/too-many-requests') {
      throw new Error("Too many requests. Please try again later or reset your password");
    } else {
      throw error;
    }
  }
}

export const getAccountData = async () => {
  const account = await axios.post("/auth/login")

  if (account.data.status !== "active") {
    throw new Error("Account inactive");
  }

  return account;
}

export const getAccountDataOnly = async () => {
  const account = await axios.post("/auth/accounts/verify")

  if (account.data.status !== "active") {
    throw new Error("Account inactive");
  }

  return account;
}

export const getAccountDataForVerification = async () => {
  return await axios.get("/auth/accounts/verify");
}