import React, {useRef, useState, useEffect } from 'react';
import { Stack, Box, Typography, InputLabel, Input, Button } from '@mui/material';
import BackgroundImage from '../assets/background.png';
import StockholemImage from '../assets/Stockholm.png';
import { FormProvider } from 'website/components/Form/FormProvider';
import { useForm } from 'react-hook-form';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { contactUsSchema } from '../validation';

const getDefaultValues = () => ({
  name: '',
  email: '',
  phone: '',
  message: '',
});

export const ContactUsForm = ({onSubmit}) => {
  const methods = useForm({
    mode: "onSubmit",
    resolver: useYupValidationResolver(contactUsSchema),
    defaultValues: getDefaultValues(),
  });

  const {
    formState: { errors },
  } = methods; 

  const handleChange = (input) => (e) => {
    methods.setValue(input, e.target.value);
  }

  const absoluteStackRef = useRef(null);
  const [yOffset, setYOffset] = useState(0);
  const [xOffset, setXOffset] = useState(0);

  useEffect(() => {
    function updateOffset() {
      if (absoluteStackRef.current) {
        setYOffset(absoluteStackRef.current.offsetHeight);
        setXOffset(absoluteStackRef.current.offsetWidth);
      }
    }

    updateOffset();
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, [absoluteStackRef]);

  return (
      <Box sx={{
        "& .MuiTypography-body2": {
          color: "text.black",
          fontSize: {xs: '1rem', md: '1.125rem'},
          fontWeight: 400,
          lineHeight: {xs: 1.5, md: 1.75},
        },
        mb: 5,
        position: "relative",
        width: 1,
        display: "flex",
        alignSelf: "center",
        maxWidth: "1200px",
      }}>
        <Stack flex={1} direction="column" width="100%" gap={{xs: 3, md: 10}}>
          <Stack
            display={"flex"}
            sx={{
              position: "relative",
              ml: "-50vw",
              left: "50%",
              width: "100vw",
              minHeight: 300,
              height: {xs: 1, md: yOffset},
            }}
          >
            <img
              className="small-logo"
              src={BackgroundImage}
              alt={'BackgroundImage'}
              loading="lazy"
              style={{
                width: "100%",
                height: yOffset,
                minHeight: 300,
                objectFit: "cover",
              }}
            />
            <Stack
              display={"flex"}
              width={1}
              maxWidth={{xs:664, md: 1200}}
              direction={{xs: "column", md : "row"}}
              gap={{xs: 3, md: 9}}
              pt={5.625}
              ref={absoluteStackRef}
              sx={{
                position: "absolute",
                left: {xs: `calc((100vw - ${xOffset}px)/2)`, md: "50%"},
                transform: {xs: "none", md: "translateX(-50%)"},
                boxSizing: 'border-box',
                px: {xs: 4, md: 6},
                height: "auto",
              }}
            >
              <Stack direction="column" flex={1} gap={2}>
                <Typography variant="h5" color={"primary.light"} fontWeight={600} lineHeight={"normal"}>
                  Contact us
                </Typography>
                <Typography variant="h1" fontSize={"2.5rem"} fontWeight={{xs: 600, md: 700}}>
                  We are always happy to assist you
                </Typography>
                <Typography variant='body1' lineHeight={1.5} fontSize={{xs: '1rem', md: '1.125rem'}} fontWeight={400} paddingBottom={{xs:0, md: 3}}>
                  Delegon is provided by Delegon AB, a company registered in Sweden with organizational number 559484-8375. The board is headquartered in Huddinge.
                </Typography>
              </Stack>
              <Stack direction={{xs: "column", md : "row"}} flex={1} justifyContent={"space-between"} pt={4} gap={5}>
                <Stack direction="column" gap={3.375} flex={1}>
                  <Typography variant='h5' lineHeight={"normal"} fontWeight={600} color={"text.custom"} fontSize={{xs: '1.125rem', md: '1.5rem'}}>
                    Email Address
                  </Typography>
                  <Stack direction={"column"} gap={2}>
                    <Typography variant='body2'>
                      hello@delegon.com
                    </Typography>
                    <Typography variant='body2'>
                      Assistance hours: Monday to Friday 9 - 16 CET
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="column" gap={3.375} pb={{xs:5, md: 0}} flex={1}>
                  <Typography variant='h5' lineHeight={"normal"} fontWeight={600} color={"text.custom"} fontSize={{xs: '1.125rem', md: '1.5rem'}}>
                    Number

                  </Typography>
                  <Stack direction={"column"} gap={2}>
                    <Typography variant='body2'>
                      +46 702 28 51 68
                    </Typography>
                    <Typography variant='body2'>
                      Assistance hours: Monday to Friday 9 - 16 CET
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            direction={{xs: "column", md: "row"}}
            gap={9}
            width={"100vw"}
            maxWidth={{xs:664, md: 1200}}
            px={{xs: 4, md: 6}}
            boxSizing={"border-box"}
            alignSelf={"center"}
            display={"flex"}
          >
            <Stack flex={1} direction="column" gap={2}>
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack
                  gap={4}
                  sx={{
                    '& label': {
                      fontSize: "1.125rem",
                      fontWeight: 400,
                      lineHeight: 1.75,
                      color: "text.black"
                    },
                    '& input': {
                      height: 15,
                      px: '2px!important',
                      pt: "0!important",
                      pb: "7px!important",
                      '&:focus': {
                        outline: 'none',
                      },
                      '&:-internal-autofill-selected, :-webkit-autofill': {
                        backgroundColor: 'transparent!important',
                        '-webkit-box-shadow': '0 0 0 1000px white inset!important',
                        transition: 'background-color 5000s ease-in-out 0s!important',
                      }
                    },
                    '& textarea': {
                      px: '2px!important',
                      pt: "0!important",
                      pb: "7px!important",
                    }
                  }}
                >
                  <Box>
                    <InputLabel htmlFor="name">
                      Your Name *
                    </InputLabel>
                    <Input
                      id="name"
                      name="name"
                      fullWidth
                      onChange={handleChange("name")}
                      error={!!errors.name}
                      aria-describedby="name-error-text"
                    />
                    {errors.name && (
                      <Typography id="name-error-text" style={{ color: 'red' }} fontWeight={500}>
                        {errors.name?.message}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <InputLabel htmlFor="email">
                      Email Address *
                    </InputLabel>
                    <Input
                      id="email"
                      name="email"
                      fullWidth
                      onChange={handleChange("email")}
                      error={!!errors.email}
                      aria-describedby="email-error-text"
                    />
                    {errors.email && (
                      <Typography id="email-error-text" style={{ color: 'red' }} fontWeight={500}>
                        {errors.email?.message}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <InputLabel htmlFor="phone">
                      Phone number (optional)
                    </InputLabel>
                    <Input
                      id="phone"
                      name="phone"
                      fullWidth
                      onChange={handleChange("phone")}
                    />
                  </Box>
                  <Box>
                    <InputLabel htmlFor="message">
                      Message *
                    </InputLabel>
                    <Input
                      id="message"
                      name="message"
                      fullWidth
                      onChange={handleChange("message")}
                      multiline
                      minRows={3}
                      maxRows={3}
                      error={!!errors.email}
                      aria-describedby="message-error-text"
                    />
                    {errors.message && (
                      <Typography id="message-error-text" style={{ color: 'red' }} fontWeight={500}>
                        {errors.message?.message}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "#62A7B7",
                      '&:hover': {
                        backgroundColor: "#62A7B7",
                      },
                      width: 217,
                      alignSelf: {xs: "center", md: "start"},
                    }}
                  >
                    Send a message
                  </Button>
                </Stack>
              </FormProvider>
            </Stack>
            <Stack flex={1} direction="column" gap={2}>
              <Box mb={{xs:0, md:7}}>
                <img
                  className="small-logo"
                  src={StockholemImage}
                  alt={'StockholemImage'}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>
  );
};
