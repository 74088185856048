import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../components/AuthLayout';
import { LoginForm } from '../components/LoginForm';
import { paths } from 'routes';

export const Login = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout title="Log In">
      <LoginForm onSuccess={() => navigate(paths.frameworkAgreement)} />
    </AuthLayout>
  );
};
