import React from 'react';
import { useLocation  } from 'react-router-dom';
import { Button, Box, Stack, Typography } from '@mui/material';
import { PaperCard } from 'components/Card';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';
import { AuthLayout } from '../components/AuthLayout';

export const AccessRequested = () => {
  const { user } = useAuth();
  const { state } = useLocation();
  
  const confirmationRequired = state;
  return (
  <>
    { !confirmationRequired && (
      <Stack
        minHeight="100vh"
        width="100%"
        minWidth={435}
        bgcolor="#d9d9d9"
        justifyContent="center"
        position="absolute"
        zIndex={3}
      >
        <Box display="flex" justifyContent="center" alignItems="center" height={1}>
          <PaperCard width={369} maxWidth={369} /* height={351} */ maxHeight={351} bgcolor="#fff" sx={{mb:5}}>
            <Stack>
              <Typography variant="h5" sx={{ mb: 1, textAlign: "center" }}>
                Access requested!
              </Typography>
              <Stack>
                <Typography variant="body1">We sent an e-mail to administrator.</Typography>
                <Typography variant="body1">Once request is approved, we will send you a confirmation e-mail.</Typography>
              </Stack>
              <Stack>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mb: 0.5,
                    bgcolor: "primary.light",
                    '&:hover': {
                      backgroundColor: "primary.light",
                    },
                  }}
                  href={user ? paths.homepage : paths.logout}
                >
                  Return to home
                </Button>
              </Stack>
            </Stack>
          </PaperCard>
        </Box>
      </Stack>
      )}
      {confirmationRequired && (
        <AuthLayout title="Register">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={1}
            px={{ xs: 5, sm: 1 }}
            pb={{ xs: 2, sm: 0 }}
          >
            <PaperCard width={369} maxWidth={369} maxHeight={351} bgcolor="#fff">
              <Stack>
                <Typography variant="h5" sx={{ mb: 0.5 }}>
                  Access requested!
                </Typography>
                <Stack>
                  <Typography variant="body1">We sent an e-mail to administrator.</Typography>
                  <Typography variant="body1">Additionally, we sent a confirmation e-mail to</Typography>
                  <Typography variant="body1">your address.</Typography>
                  <Typography variant="body1">
                    The link will be valid for 48 hours. Please finish your registration to gain access
                    to the platform.
                  </Typography>

                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mb: 0.5 }}
                    href={paths.homepage}
                  >
                    Return to home
                  </Button>
                </Stack>
              </Stack>
            </PaperCard>
          </Box>
        </AuthLayout>
      )}
      </>
  );
};
