import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { InputWrapper } from './InputWrapper';
import { NumericFormat as ReactNumericFormat } from 'react-number-format';
import { TextField } from './TextField';

export const NumericFormat = ({
  name,
  helperText = '',
  type = 'text',
  label = '',
  fullWidth = false,
  labelSx = {},
  wrapperSx = {},
  required = false,
  url = '',
  prefix = 'MSEK',
  ...other
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputWrapper
          error={!!error}
          label={label}
          fullWidth={fullWidth}
          required={required}
          labelSx={labelSx}
          wrapperSx={{width: "49%", ...wrapperSx}}
        >
          <ReactNumericFormat
            {...field}
            fullWidth
            value={field.value}
            decimalScale={0}
            thousandSeparator={' '}
            valueIsNumericString
            customInput={TextField}
            {...other}
          />
        </InputWrapper>
      )}
    />
  );
};
