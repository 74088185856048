import React from 'react';
import { Box, Typography, Stack } from "@mui/material";
import OfferingsPhoto1 from '../../assets/offerings1.png';
import OfferingsPhoto2 from '../../assets/offerings2.png';
import OfferingsPhoto3 from '../../assets/offerings3.png';

export const Offerings = () => {
  return (
    <Stack
     sx={{
      display: "flex",
      gap: {xs: 4, md: 8},
      maxWidth: "100vw",
     }}
    >
      <Typography
        variant='h3'
        sx={{
          fontSize: "2.5rem",
          fontWeight: 600,
          lineHeight: 1.45,
          textAlign: "center",
          mx: {xs:4, md: 0}
        }}
      >
        What we are offering
      </Typography>
      <Stack
        display={"flex"}
        flexDirection={{xs: "column", md: "row"}}
        gap={0}
        justifyContent={"space-between"}
        sx={{
          '& h5': {
            color: "primary.light",
            fontWeight: 600,
            lineHeight: "normal",
          },
          '& p': {
            color: "#323232",
            fontSize: "1.125rem",
            fontWeight: 400,
            lineHeight: 1.75,
          },
          mx: {xs:4, md: 0},
          gap: {xs: 4, md: 0}
        }}
      >
        <Stack
          flexDirection={{xs: "column-reverse", md:"column"}}
          gap={4}
          flex={1}
        >
          <Stack
            flexDirection={"row"}
            gap={4}
            justifyContent={'space-between'}
          >
            <Box
              maxWidth={{xs: 184, sm: 266}}
              maxHeight={{xs: 209, sm: 332}}
              mt={{xs: 0, md: 12}}
            >
              <img
                className="small-logo"
                src={OfferingsPhoto2}
                alt={'OfferingsPhoto2'}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 8
                }}
              />
            </Box>
            <Box
              maxWidth={{xs: 184, sm: 266}}
              maxHeight={{xs: 209, sm: 332}}
              mr={{xs: 0, md: 8}}
              mb={{xs: 0, md: 12}}
            >
              <img
                className="small-logo"
                src={OfferingsPhoto1}
                alt={'OfferingsPhoto1'}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 8
                }}
              />
            </Box>
          </Stack>
          <Stack gap={2}>
            <Typography variant='h5'>
              Dynamic purchasing systems (DPS)
            </Typography>
            <Typography variant='body2'>
              Delegon gives a complete overview of available dynamic purchasing systems for staffing- and consulting services.
            </Typography>
            <Typography variant='body2'>
              Learn from others and tender your own DPS.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          flexDirection={"column"}
          justifyContent={"space-between"}
          flex={1}
        >
          <Stack gap={2}>
            <Typography variant='h5'>
              Framework agreements and tenders
            </Typography>
            <Typography variant='body2'>
              Delegon gathers, structures and presents information and documents from the complete tendering process – including bids, evaluation documents and award messages.
            </Typography>
            <Typography variant='body2'>
              Learn from others and improve your own tenders.
            </Typography>
          </Stack>
          <Box display={{xs: 'none', md: 'flex'}}>
            <img
              className="small-logo"
              src={OfferingsPhoto3}
              alt={'OfferingsPhoto3'}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 8
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}