import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import { PaperCard } from 'components/Card';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';
import { Loader } from 'components/Loader';
import { Error } from './Error';
import { useLocation } from 'react-router-dom';

export const ConfirmationSentForm = ({ values = {}, initLoad = true, deactivated = false }) => {
  const { register } = useAuth();
  const { state } = useLocation();
  const initialLoad = useRef(state?.stateData?.initLoad !== undefined && state?.stateData.initLoad !== null ? state.stateData.initLoad : initLoad);
  const [loading, setLoading] = useState(true);
  const [ error, setError ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState('');
  const email = state?.stateData?.companyEmail ? state.stateData.companyEmail : values?.companyEmail;

  useEffect(() => {
    const createUser = async () => {
      try {
        await register(values);
      } catch (err) {
        setError(true);
        setErrorMsg(err.message)
      } finally {
        setLoading(false);
      }
    };

    if (initialLoad.current) {
      initialLoad.current = false;
      createUser();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading && (
        <Box height={254} sx={{alignContent: "center", mb: 5}}>
          <Loader />
        </Box>
      )}

      {!loading && (
        <>
        {error && (
          <Error message={errorMsg} />
        )}
        {!error && (
          <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }} sx={{mb:5}}>
            <Stack>
              <Typography variant="h5" sx={{ mb: 3, textAlign: "center" }}>
                Almost done!
              </Typography>
              <Stack>
                {deactivated && (
                  <Typography variant="body1">Your account is deactivated</Typography>
                )}
                <Typography variant="body1">We sent a confirmation e-mail to</Typography>
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  {`${email}`}
                </Typography>
                <Typography variant="body1">
                  The link will be valid for 48 hours. Please finish your registration to gain access
                  to the platform.
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mb: 0.5,
                    bgcolor: "primary.light",
                    '&:hover': {
                      backgroundColor: "primary.light",
                    },
                  }}
                  href={paths.homepage}
                >
                  Return to home
                </Button>
              </Stack>
            </Stack>
          </PaperCard>
        )}
        </>
      )}
    </>
  );
};
