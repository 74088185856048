import React, { useEffect, useState } from 'react';
import { Divider, Stack, Typography, Box } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';

import { NotFound } from 'components/NotFound/NotFound';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'components/Search';
import { Pagination } from 'components/Pagination';

import { useFrameworkAgreementFiles } from '../api/getFrameworkAgreementFiles';
import { FileListItem } from './FileListItem';
import { downloadFile, donwloadFileFromURL } from '../api/downloadFile';
import { FileUploadModal } from './FileUploadModal';
import { useFilePreview } from '../api/previewFile';
import { PDFViewer } from 'components/PDFViewer/PDFViewer';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { useRemoveFile } from '../api/removeFile';
import { ImageViewer } from 'components/ImageViewer/ImageViewer';
import { FilesPremiumBanner } from '../../subscriptions/components/FilesPremiumBanner';
import { Loader } from 'components/Loader';

import { useAuth } from 'lib/auth';
import { DetailViewTabList } from '../components/TabList';
import { useFrameworkAgreementsSupplierFiles } from '../api/getFrameworkAgreementsSupplierFiles';
import { useFrameworkAgreementsConsortiumGroupFiles } from '../api/getFrameworkAgreementsConsortiumGroupFiles';
import { useFrameworkAgreementFilesCount } from '../api/getFrameworkAgreementFilesCount';
import { useQueryClient } from 'react-query';

const filesTabName = 'files';

export const FilesTab = ({ currentTab, suppliers, suppliersFilesCount, totalFilesCount, generalFilesCount }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentSubtab = queryParams.get('subtab') || `${suppliers[0]?.fac_id}`;
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { account, user } = useAuth();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [currentFilePreviewId, setCurrentFilePreviewId] = useState(null);
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [markedForEdit, setMarkedForEdit] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [timer, setTimer] = useState(null);
  const [subTab, setSubTab] = useState(currentSubtab);

  const selectedSupplier = suppliers.find(supplier => `${supplier.fac_id}` === subTab);
  const selectedType = selectedSupplier ? selectedSupplier.company_type : 'general';

  // const {
  //   refetch: refetchGeneralFilesCount,
  // } = useFrameworkAgreementFilesCount(id);

  // const {
  //   data: { files: generalFiles, total: generalFilesTotal },
  //   refetch: refetchGeneralFiles,
  //   isFetching: isFetching2,
  // } = useFrameworkAgreementFiles(id, {
  //   search,
  //   page: page - 1,
  //   queryOptions: { 
  //     onSettled: () => {
  //       clearTimeout(timer);
  //       setTimeout(() => setLoadingFiles(false), 1000);
  //     },
  //     enabled: (currentTab === filesTabName && subTab === 'general') },
  // });

  const {
    data: { files: supplierFiles, total: supplierFilesTotal },
    refetch: refetchSupplierFiles,
    isFetching: isFetching3,
  } = useFrameworkAgreementsSupplierFiles(id, subTab, {
    search,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoadingFiles(false), 1000);
      },
      enabled: (currentTab === filesTabName && selectedSupplier && subTab === `${selectedSupplier.fac_id}` && selectedType === 'supplier'),
    },
  });

  const {
    data: { files: groupFiles, total: groupFilesTotal },
    refetch: refetchGroupFiles,
    isFetching: isFetching4,
  } = useFrameworkAgreementsConsortiumGroupFiles(id, subTab, {
    search,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoadingFiles(false), 1000);
      },
      enabled: (currentTab === filesTabName && selectedSupplier && subTab === `${selectedSupplier.fac_id}` && selectedType === 'group'),
    },
  });

  const isFetchingFiles = /* isFetching2 || */ isFetching3 || isFetching4;

  // const files = subTab === 'general' ? generalFiles : selectedType === 'supplier' ? supplierFiles : groupFiles;
  // const total = subTab === 'general' ? generalFilesTotal : selectedType === 'supplier' ? supplierFilesTotal : groupFilesTotal;

  const files = selectedType === 'supplier' ? supplierFiles : groupFiles;
  const total = selectedType === 'supplier' ? supplierFilesTotal : groupFilesTotal;

  const {
    data: { url, name, MimeType },
  } = useFilePreview({faId: id, fileId: currentFilePreviewId, facId: selectedType !== 'general' ? selectedSupplier?.fac_id : null});

  const handlePageChange = (_, page) => setPage(page);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const onFileRemoval = () => {
    switch (selectedType) {
      case 'supplier':
        refetchSupplierFiles();
        queryClient.invalidateQueries([`framework-agreement-supplier-${subTab}-files-count`]);

        break;
      case 'group':
        refetchGroupFiles();
        queryClient.invalidateQueries([`framework-agreement-supplier-${subTab}-files-count`]);
          break;

      default:
        // refetchGeneralFiles();
        // refetchGeneralFilesCount();
        break;
    }
    setMarkedForDelete(null);
  };
  const handleMutation = () => {
    switch (selectedType) {
      case 'supplier':
        refetchSupplierFiles();
        queryClient.invalidateQueries([`framework-agreement-supplier-${subTab}-files-count`]);
        break;
      case 'group':
        refetchGroupFiles();
        queryClient.invalidateQueries([`framework-agreement-supplier-${subTab}-files-count`]);
          break;

      default:
        // refetchGeneralFiles();
        // refetchGeneralFilesCount();
        break;
    }

    if (markedForEdit) {
      setMarkedForEdit(null);
    }
  };

  const { mutateAsync: removeFile } = useRemoveFile(onFileRemoval);

  useTabReset();
  useSubTabReset();
  useRefetch();

  useEffect(() => {
    if (isFetchingFiles) {
      const timeoutId = setTimeout(() => setLoadingFiles(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetchingFiles]);

  const switchSubTab = (_, newSubTab) => setSubTab(newSubTab);

  let tabs = [/* {label: "General", count: generalFilesCount} */];
  suppliers.forEach((supplier, index) => {
    tabs.push({label: supplier.company_type === 'supplier' ? supplier?.Company.Name : supplier.ConsortiumGroup.cg_name, count: suppliersFilesCount[supplier.fac_id], value: `${supplier.fac_id}`})
  });

  useEffect(() => {
    setSubTab(currentSubtab);
  }, [currentSubtab]);

  if (currentTab !== filesTabName) return null;

  if (!account.super_admin && (!user.Company.is_premium || user.Company.subscription_level !== 'gold')) {
    return <FilesPremiumBanner count={totalFilesCount} />;
  }

  return (
    <>
      <ImageViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('image/')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />

      <PDFViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('application/pdf')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />
      {account.super_admin && (
        <DeleteConfirmationModal
          title={'Remove file'}
          markedForDelete={markedForDelete}
          mutateAsync={() => removeFile({ faId: id, fileId: markedForDelete?.itemId, facId: selectedType === 'supplier' ? selectedSupplier?.fac_id : null, cgId:  selectedType === 'group' ? selectedSupplier?.fac_id : null})}
        >
          <Typography variant="body1" fontWeight={500}>
            Please confirm the removal of the file{' '}
            <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
          </Typography>
        </DeleteConfirmationModal>
      )}

      <Stack gap={2} height={1}>
        <>
          {tabs?.length !== 0 && (
            <DetailViewTabList
              value={subTab}
              onChange={switchSubTab}
              tabs={tabs}
              multi
            />
          )}
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
            <Search fullWidth handleSearchChange={handleSearchChange} sx={{minWidth: 200, width: 270}}/>
            {account.super_admin && (
              <Box sx={{minWidth: 150, width: 200}}>
                <FileUploadModal handleSuccess={handleMutation} markedForEdit={markedForEdit} facID={selectedSupplier?.fac_id} parentType={selectedType} />
              </Box>
            )}
          </Stack>

          <Divider />

          {isFetchingFiles && !loadingFiles ?
          (
            <></>
          ) :
          (!isFetchingFiles || (isFetchingFiles && loadingFiles)) ?
          (
            <>
            {loadingFiles && (
              <Loader />
            )}

            {!loadingFiles && (
              <>
              <Stack flex={1} gap={1}>
                {files?.length === 0 && (
                  <NotFound
                    title="No Files Found"
                    caption={
                      search
                        ? 'Please try another search term'
                        : account.super_admin ? 'Click the "Upload File" button to begin' : ''
                    }
                    wrapperSx={{ mt: 25 }}
                  />
                )}

                {files?.length !== 0 &&
                  files.map((file) => (
                    <FileListItem
                      key={file.file_id}
                      handleFilePreview={() => setCurrentFilePreviewId(file.file_id)}
                      handleDownload={() => downloadFile({faId: id, fileId:file.file_id, facId: selectedType === 'supplier' ? selectedSupplier?.fac_id : null, cgId: selectedType === 'group' ? selectedSupplier?.fac_id : null})}
                      markForDelete={() => setMarkedForDelete({ name: file.name, itemId: file.file_id })}
                      markForEdit={() => setMarkedForEdit({ name: file.name, fileId: file.file_id })}
                      faId={id}
                      {...file}
                    />
                  ))}
              </Stack>

              <Stack direction="row" justifyContent="center">
                <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
              </Stack>
              </>
            )}
          </>
          ):
          (
            <></>
          )}
        </>
      </Stack>
    </>
  );

  function useRefetch() {
    useEffect(() => {
      switch (selectedType) {
        case 'supplier':
          refetchSupplierFiles();
          break;
        case 'group':
          refetchGroupFiles();
            break;

        default:
          // refetchGeneralFiles();
          break;
      }
    }, [page, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== filesTabName) {
        setPage(1);
        setSearch('');
        setSubTab(`${suppliers[0]?.fac_id}`);
      }
    }, [currentTab]);
  }

  function useSubTabReset() {
    useEffect(() => {
      setPage(1);
      setSearch('');
    }, [subTab]);
  }
};
