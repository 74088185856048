import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Homepage } from './Homepage';
import { Homepage as BuyersHomepage } from './buyers/Homepage';
import { TermsAndConditions } from './TermsAndConditions';
import { ContactUs } from './ContactUs';
import { Pricing } from './Pricing';
import { Pricing as BuyersPricing } from './buyers/Pricing';

export const WebsiteRoutes = () => {
  return (
      <Routes>
        <Route path={"/terms-and-conditions"} element={<TermsAndConditions />} />
        <Route path={"/contact-us"} element={<ContactUs />} />
        <Route path={"/buyers"} element={<BuyersHomepage />} />
        <Route path={"/pricing"} element={<Pricing />} />
        <Route path={"/buyers/pricing"} element={<BuyersPricing />} />
        <Route path={"/"} element={<Homepage />} />
      </Routes>
  );
};
