import React from 'react';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
// import { ReactComponent as DelegonLogo } from '../../../assets/Delegon_Main_Logo.svg';
import { ReactComponent as DelegonLogo } from 'assets/Delegon_Main_Logo.svg';
import LoginNew from 'assets/loginNew.png';
import { Head } from 'components/Head';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { IconWrapper } from 'components/Icon';
import { ROOT_PATHS } from 'routes';

export const AuthLayout = ({ title = '', context = '', children }) => {
  const location = useLocation();
  const websiteContext = context === 'buyers' ? context : location?.state?.websiteContext;
  const homeURL = websiteContext === 'buyers' ? ROOT_PATHS.WEBSITE_BUYERS : ROOT_PATHS.WEBSITE;

  return (
    <>
      <Head title={title} />
      <Stack width="100%" height={"100vh"}>
        <AppBar position="relative" sx={({ shadows, palette }) => ({ 
            boxShadow: shadows[25],
            height: "70px",
            bgcolor: '#fff',
            border: `1px solid ${palette.grey[200]}`,
            display: 'flex',
          })}>
          <Toolbar
            sx={{
              justifyContent: "center"
            }}
          >
            <Box
              component={RouterLink}
              to={homeURL}
              sx={{
                height: 70,
              }}
            >
              <DelegonLogo style={{height: 70}} />
            </Box>
            <Box
              component={RouterLink}
              to={homeURL}
              sx={{
                display: "flex",
                height: 30,
                position: "absolute",
                right: "25px",
              }}
            >
              <IconWrapper
                icon="xMark"
                sx={({palette}) => ({
                  height: 30,
                  width: 30,
                  svg: {
                    color: palette.grey[500],
                    stroke: palette.grey[500],
                    width: 30,
                    height: 30,
                  },
                })}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Box flex={1} display="flex" width={"100vw"} overflow="hidden">
          <Box flex={1} bgcolor="#d9d9d9" display={{ xs: 'none', lg: 'initial' }}
            sx={{
              backgroundImage: `url(${LoginNew})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Box
            px={{ xs: 1, sm: 0 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            bgcolor={"background.primary"}
            sx={{
              overflowY: "auto",
            }}
          >
            {children}
          </Box>
          {/* <Box flex={1} bgcolor="#d9d9d9" display={{ xs: 'none', lg: 'initial' }}></Box> */}
        </Box>
      </Stack>
    </>
  );
};
