import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack, Button, Typography, AppBar } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import DelegonMainLogoWhite from '../../../assets/Delegon_Main_Logo_WHITE.png';

import { paths, ROOT_PATHS } from 'routes';
import { IconWrapper } from 'components/Icon';
import { MobileMenu } from 'website/components/MobileMenu';

export const Menu = ({websiteContext = 'suppliers'}) => {
  const location = useLocation();
  const lastSlashIndex = location.pathname.lastIndexOf('/');
  const dynamicPart = lastSlashIndex !== -1 ? location.pathname.substring(lastSlashIndex + 1) : "website";
  const [selected, setSelected] = useState(dynamicPart);
  const [open, setOpen] = useState(false);
  const body = document.body;
  const originalOverflow = useRef(body.style.overflow);
  const homeURL = websiteContext === 'buyers' ? ROOT_PATHS.WEBSITE_BUYERS : ROOT_PATHS.WEBSITE;
  const registerURL = websiteContext === 'buyers' ? paths.auth.buyersRegister : paths.auth.register;
  const pricingURL = websiteContext === 'buyers' ? paths.website.buyersPricing : paths.website.pricing;

  useEffect(() => {
    const pathname = location.pathname;
    const pathSegments = pathname.split('/').filter(segment => segment !== '');
    if (pathSegments.length > 1 && pathSegments[pathSegments.length -1] === 'buyers') {
      setSelected("website");
    } else {
      setSelected(dynamicPart);
    }
  }, [dynamicPart]);

  const menuItems = [
    { label: "Homepage", value: "website", path: homeURL },
    // { label: "Blog", value: "blog", path: "#" },
    { label: "Pricing", value: "pricing", path: pricingURL },
    { label: "Contact us", value: "contact-us", path: paths.website.contactUs },
  ];

  const handleOpen = () => {
    setOpen(true);
    originalOverflow.current = body.style.overflow;
    body.style.overflow = 'hidden';
  };

  const handleClose = () => {
    setOpen(false);
    body.style.overflow = originalOverflow.current;
  };

  return (
    <AppBar
      sx={{
        height: 70,
        py: 1.25,
        width: 1,
        maxWidth: "100vw",
        backgroundColor: "background.primary",
        position: "fixed",
        top: 44,
      }}
    >
      <Stack
        display={"flex"}
        direction={"row"}
        sx={{
          height: 1,
          alignItems: "center",
          mx: {xs: 4, md: 6},
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            '.small-logo': { marginLeft: { xs: "-15px", md: "-20px"} },
          }}
          component={RouterLink}
          to={homeURL}
        >
          <img
            className="small-logo"
            src={DelegonMainLogoWhite}
            alt={'Delegon'}
            loading="lazy"
            style={{
              width: 150,
              flexShrink: 0,
            }}
          />
        </Box>
        <Stack
          display={{ xs: "none", md: "flex"}}
          gap={4.5}
          flexDirection={"row"}
          sx={{
            "& > div > a": {
              textDecoration: "none",
              color: "#fff",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: 1.5,
            },
          }}
        >
          {menuItems.map((item) => (
            <Box
              gap={1}
              key={item.value}
              sx={{
                height: 32,
                position: "relative",
                "&:hover": {
                  "&::after": {
                    width: "50%",
                  },
                },
              }}
            >
              <Typography
                key={item.value}
                component={RouterLink}
                to={item.path}
                state={{websiteContext: websiteContext}}
                onClick={() => setSelected(item.value)}
              >
                {item.label}
              </Typography>
              <Box
                sx={{
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  height: "2px",
                  width: selected === item.value ? "23px" : "0",
                  backgroundColor: "#fff",
                  transition: "width 0.3s ease",
                }}
              />
            </Box>
          ))}
        </Stack>
        <Stack
          display={{ xs: "none", md: "flex"}}
          gap={1}
          flexDirection={"row"}
          sx={{
            "& > a, .MuiButton-root": {
              width: 99,
              height: 34,
              fontSize: "0.875rem",
              fontWeight: "500",
              color: "#fff",
              lineHeight: 1.75,
            },
          }}
        >
          <RouterLink
            to={paths.auth.login}
            state={{websiteContext: websiteContext}}
          >
            <Button
              size="medium"
              variant="contained"
              sx={{
                backgroundColor: "primary.light",
                '&:hover': {
                  backgroundColor: "primary.light",
                },
              }}
            >
              Login
            </Button>
          </RouterLink>
          <RouterLink
            to={registerURL}
            state={{websiteContext: websiteContext}}
          >
            <Button
              size="medium"
              variant="contained"
              color="primary"
              sx = {({palette}) =>({
                width: 99,
                '&:hover': {
                  backgroundColor: palette.primary.main,
                },
              })}
            >
              Sign up
            </Button>
          </RouterLink>
        </Stack>
        <Box display={{ xs: "flex", md: "none"}} height={40} onClick={handleOpen} sx={{mr: "-5px"}}>
          <IconWrapper
            icon="bars3"
            sx={{
              height: 40,
              width: 40,
              svg: {
                color: "white",
                stroke: 'white',
                width: 40,
                height: 40,                
              },
            }}
          />
        </Box>
        {open && (
          <MobileMenu menuItems={menuItems} onClose={handleClose} />
        )}
      </Stack>
    </AppBar>
  )
}