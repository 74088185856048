import React from 'react';
import { Box } from '@mui/material';
import { Footer } from './Footer';
import { Menu } from './Menu';
import { ContextBanner } from './ContextBanner';

export const MainLayout = ({ websiteContext = 'suppliers', isPricing = false, children }) => {
  return (
    <>
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        minHeight="100vh"
        width={1}
        justifyContent="center"
        alignItems="center"
        overflow={{xs: "auto", md: "hidden"}}
      >
        <ContextBanner websiteContext={websiteContext} isPricing={isPricing} />
        <Menu websiteContext={websiteContext} />
        {children}
        <Footer websiteContext={websiteContext} />
      </Box>
    </>
  );
};
