import { DELEGON_URL } from 'configuration';

//  EXTERNAL LINKS
export const EXTERNAL = {
  TERMS_OF_SERVICE: `${DELEGON_URL}/terms-and-conditions/`,
  PRIVACY_POLICE: `${DELEGON_URL}/privacy-policy/`,
  PRICING_SUPPLIERS: `${DELEGON_URL}/pricing/`,
  PRICING_BUYERS: `${DELEGON_URL}/buyers/pricing/`,
  BLOGS: `${DELEGON_URL}/blogs/`,
  CONTACT_US: `${DELEGON_URL}/contact-us/`,
  HOMEPAGE: `${DELEGON_URL}/`,
};

//  ------------------------------------------------------------

//  INTERNAL LINKS
//  ROOT_PATHS & SUB_PATHS are used for the react-router-dom router objects
export const ROOT_PATHS = {
  AUTH: '/auth',
  HOMEPAGE: 'home/',
  USER_SEARCH: 'user-search/',
  COMPANY_PROFILE: 'company-profile/',
  FRAMEWORK_AGREEMENT: 'framework-agreements/',
  FRAMEWORK_AGREEMENT_DETAILS: 'framework-agreements/:id',
  LOGOUT: 'logout/',
  REQUEST_ACCESS: 'reqeuest-access/',
  MARKETPLACE: 'marketplace/',
  BUYERS: 'buyers/',
  BUYERS_DETAILS: 'buyers/:id',
  SUPPLIERS: 'suppliers/',
  SUPPLIERS_DETAILS: 'suppliers/:id',
  USERS: 'contacts/',
  USER_DETAILS: 'contacts/:id',
  SUBSCRIPTIONS: 'subscriptions/',
  TENDERS: 'tenders/',
  TENDERS_DETAILS: 'tenders/:id',
  DPS: 'dps/',
  DPS_DETAILS: 'dps/:id',
  ACCESS_REQUESTED: 'access-requested/',
  WEBSITE: '/website',
  WEBSITE_BUYERS: '/website/buyers',
};

export const SUB_PATHS = {
  auth: {
    login: '/login',
    register: '/register',
    buyersRegister: '/signup',
    verifyEmail: '/verify-email',
    forgotPassword: '/forgot-password',
    passwordReset: '/reset-password',
    action: '/action',
    acceptInvitation: '/accept-invitation',
    accessRequested: '/access-requested',
    confirmationSent: '/confirmation-sent',
  },
  frameworkAgreement: {
    details: ':id',
    consortiumGroup: 'consortium-group',
    consortiumGroupDetails: 'consortium-group/:cgId',
    consortiumGroupFiles: 'consortium-group/:facId/files',
    suppliers: 'suppliers',
    suppliersFiles: 'suppliers/:facId/files',
  },
  tenders: {
    details: ':id',
    consortiumGroup: 'consortium-group',
    consortiumGroupDetails: 'consortium-group/:cgId',
    consortiumGroupFiles: 'consortium-group/:tcId/files',
    suppliers: 'suppliers',
    suppliersFiles: 'suppliers/:tcId/files',
  },
  dps: {
    details: ':id',
    // consortiumGroup: 'consortium-group',
    // consortiumGroupDetails: 'consortium-group/:cgId',
    // consortiumGroupFiles: 'consortium-group/:dcId/files',
    // suppliers: 'suppliers',
    // suppliersFiles: 'suppliers/:dcId/files',
  },
  website: {
    termsAndConditions: '/terms-and-conditions',
    contactUs: '/contact-us',
    pricing: '/pricing',
    buyersPricing: '/buyers/pricing',
  }
};

//  ------------------------------------------------------------------------

const makePublicURI = (uriParts) => uriParts.reduce((URI, part) => (URI += part), '');
const makeProtectedURI = (uriParts) => uriParts.reduce((URI, part) => (URI += part), '/app/');
const makeWebsiteURI = (uriParts) => uriParts.reduce((URI, part) => (URI += part), '');

//  paths are used for the react-router-dom Link component
export const paths = {
  auth: {
    login: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.login]),
    register: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.register]),
    buyersRegister: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.buyersRegister]),
    verifyEmail: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.verifyEmail]),
    forgotPassword: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.forgotPassword]),
    passwordReset: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.passwordReset]),
    action: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.action]),
    acceptInvitation: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.acceptInvitation]),
    accessRequested: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.accessRequested]),
    confirmationSent: makePublicURI([ROOT_PATHS.AUTH, SUB_PATHS.auth.confirmationSent]),
  },

  homepage: makeProtectedURI([ROOT_PATHS.FRAMEWORK_AGREEMENT]),
  userSearch: makeProtectedURI([ROOT_PATHS.USER_SEARCH]),
  companyProfile: makeProtectedURI([ROOT_PATHS.COMPANY_PROFILE]),
  frameworkAgreement: makeProtectedURI([ROOT_PATHS.FRAMEWORK_AGREEMENT]),
  frameworkAgreementDetailsView: (faId) => `/app/${ROOT_PATHS.FRAMEWORK_AGREEMENT + faId}`,
  frameworkAgreementConsortiumGroups: (faId, cgId) =>
    `/app/${ROOT_PATHS.FRAMEWORK_AGREEMENT + faId}/${
      SUB_PATHS.frameworkAgreement.consortiumGroup
    }/${cgId}`,
  frameworkAgreementConsortiumGroupsFiles: (faId, facId) =>
    `/app/${ROOT_PATHS.FRAMEWORK_AGREEMENT + faId}?tab=files&subtab=${facId}`,
  frameworkAgreementSuppliersFiles: (faId, facId) =>
    `/app/${ROOT_PATHS.FRAMEWORK_AGREEMENT + faId}?tab=files&subtab=${facId}`,

  logout: makeProtectedURI([ROOT_PATHS.LOGOUT]),
  requestAccess: makeProtectedURI([ROOT_PATHS.REQUEST_ACCESS]),
  accessRequested: makeProtectedURI([ROOT_PATHS.ACCESS_REQUESTED]),
  marketplace: makeProtectedURI([ROOT_PATHS.MARKETPLACE]),
  buyers: makeProtectedURI([ROOT_PATHS.BUYERS]),
  buyersDetailsView: (id) => makeProtectedURI([ROOT_PATHS.BUYERS, id]),
  suppliers: makeProtectedURI([ROOT_PATHS.SUPPLIERS]),
  suppliersDetailsView: makeProtectedURI([ROOT_PATHS.SUPPLIERS_DETAILS]),
  users: makeProtectedURI([ROOT_PATHS.USERS]),
  subscriptions: makeProtectedURI([ROOT_PATHS.SUBSCRIPTIONS]),

  tenders: makeProtectedURI([ROOT_PATHS.TENDERS]),
  tendersDetailsView: (tId) =>
   `/app/${ROOT_PATHS.TENDERS + tId}`,
  tendersConsortiumGroups: (tId, cgId) =>
    `/app/${ROOT_PATHS.TENDERS + tId}/${
      SUB_PATHS.tenders.consortiumGroup
    }/${cgId}`,
  tendersConsortiumGroupsFiles: (tId, tcId) =>
    `/app/${ROOT_PATHS.TENDERS + tId}?tab=files&subtab=${tcId}`,
  tendersSuppliersFiles: (tId, tcId) =>
    `/app/${ROOT_PATHS.TENDERS + tId}?tab=files&subtab=${tcId}`,

  dps: makeProtectedURI([ROOT_PATHS.DPS]),
  dpsDetailsView: (dpsId) =>
   `/app/${ROOT_PATHS.DPS + dpsId}`,
  // dpsConsortiumGroups: (dpsId, cgId) =>
  //   `/app/${ROOT_PATHS.DPS + dpsId}/${
  //     SUB_PATHS.dps.consortiumGroup
  //   }/${cgId}`,
  // dpsConsortiumGroupsFiles: (dpsId, dcId) =>
  //   `/app/${ROOT_PATHS.DPS + dpsId}/${
  //     SUB_PATHS.dps.consortiumGroup
  //   }/${dcId}/files`,
  // dpsSuppliersFiles: (dpsId, dcId) =>
  //   `/app/${ROOT_PATHS.DPS + dpsId}/${
  //     SUB_PATHS.dps.suppliers
  //   }/${dcId}/files`,

  website: {
    termsAndConditions: makeWebsiteURI([ROOT_PATHS.WEBSITE, SUB_PATHS.website.termsAndConditions]),
    contactUs: makeWebsiteURI([ROOT_PATHS.WEBSITE, SUB_PATHS.website.contactUs]),
    pricing: makeWebsiteURI([ROOT_PATHS.WEBSITE, SUB_PATHS.website.pricing]),
    buyersPricing: makeWebsiteURI([ROOT_PATHS.WEBSITE, SUB_PATHS.website.buyersPricing]),
  },
};
