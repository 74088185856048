import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { paths } from 'routes';

import { useFrameworkAgreement } from '../api/getFrameworkAgreementById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useFrameworkAgreementCompanyCount } from '../api/getFrameworkAgreementCompanyCount';
import { useFrameworkAgreementFilesCount } from '../api/getFrameworkAgreementFilesCount';
import { useFrameworkAgreementSuppliers } from '../api/getFrameworkAgreementSuppliers';
import { useFrameworkAgreementsSupplierFilesCountQueries } from '../api/getFrameworkAgreementsSupplierFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';
import { Loader } from 'components/Loader';
import { BoostToggleButton } from '../components/BoostToggleButton';
import { IconWrapper } from 'components/Icon';

export const DetailsView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get('tab') || 'details';
  const [ loading, setLoading ] = useState(false);

  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(currentTab);
  const [timer, setTimer] = useState(null);

  const { data: frameworkAgreement, refetch: refetchFrameworkAgreement, error, isError, isLoading: agreementLoading, isFetched: isFetched1 } =
    useFrameworkAgreement(id);
  const {
    //@ts-ignore
    data: { count: buyersCount, isLoading: buyersCountLoading, isFetched: isFetched2 },
    refetch: refetchBuyersCount,
  } = useFrameworkAgreementCompanyCount(id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount, isLoading: suppliersCountLoading, isFetched: isFetched3 },
  } = useFrameworkAgreementCompanyCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount, isLoading: filesCountLoading, isFetched: isFetched4 },
  } = useFrameworkAgreementFilesCount(id);

  const {
    data: { suppliers },
    isLoading: isLoadingSuppliers,
    isFetched: isFetched5,
    refetch: refetchSuppliersFiles,
  } = useFrameworkAgreementSuppliers(id, {
    includeInactive: account.super_admin ? true : false,
    limit: 200,
    queryKey: [`framework-agreement-${id}-files-suppliers`],
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      },
    },
  });

  const facIds = suppliers.map(supplier => supplier.fac_id);
  const filesCountQueries = useFrameworkAgreementsSupplierFilesCountQueries(id, facIds);
  const suppliersFilesCount = filesCountQueries.reduce((acc, query, index) => {
    if (facIds[index] != null) {
      acc[facIds[index]] = query.data?.count || 0;
    }

    return acc;
  }, {});
  
  const isLoadingSuppleirsFilesCount = filesCountQueries.some(query => query.isLoading);
  const allSuppliersFilesFetched = filesCountQueries.every(query => query.isFetched);

  const { suppliersFilesTotal, isSuppliersFilesTotalReady } = allSuppliersFilesFetched ? filesCountQueries.length === 0 ?
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: true }
  :
  filesCountQueries.reduce(
    (acc, query, index, array) => {
      const count = query.data?.count || 0;
      acc.suppliersFilesTotal += count;

      if (index === array.length - 1 && allSuppliersFilesFetched) {
        acc.isSuppliersFilesTotalReady = true;
      }

      return acc;
    },
    { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false }
  ) :
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false };

  const switchTab = (_, newTab) => {
    navigate(`?tab=${newTab}`, { replace: true });
  };

  const isLoading = agreementLoading || buyersCountLoading || suppliersCountLoading || filesCountLoading || isLoadingSuppliers || isLoadingSuppleirsFilesCount;
  const isFetched = isFetched1 || isFetched2 || isFetched3 || isFetched4 || isFetched5 || allSuppliersFilesFetched;

  const totalFiles = isFetched && isSuppliersFilesTotalReady ? /* filesCount +  */suppliersFilesTotal : 0;

  useEffect(() => {  
    if (isLoading) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isFetched) {    
    if (loading) {      
      setTimeout(() => setLoading(false), 1000);
    }
  }

  useEffect(() => {
    setTab(currentTab);
  }, [currentTab]);

  if (!loading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  const suppliersSorted = [...suppliers].sort((a, b) => {
    const aName = a.Company.Name.length > 0 ? a.Company.Name : a.ConsortiumGroup.cg_name
    const bName = b.Company.Name.length > 0 ? b.Company.Name : b.ConsortiumGroup.cg_name
    return aName.localeCompare(bName)
  });

  return (
    <ContentLayout>
      {isLoading && !loading ? (
        <></>
      )
      : (
        loading ? (
          <Loader style={{marginTop: "40vh"}}/>
        )
        : (
          !loading && isError ? (
            <PremiumBanner message={`This Agreement is only available for Premium members. Click here for more information`} handleSuccess={refetchFrameworkAgreement} currentLevel={user.Company.subscription_level} />
          ) : (
            <Stack height="100%">
              <BreadCrumbs
                items={[
                  // { name: 'Delegon Admin', to: paths.companyProfile },
                  { name: '', to: paths.frameworkAgreement },
                  { name: 'Framework Agreements', to: paths.frameworkAgreement },
                  { name: `${frameworkAgreement.MainBuyer.Name}`, to: `${paths.buyersDetailsView(frameworkAgreement.MainBuyer.company_id)}?tab=agreements` },
                  { name: frameworkAgreement?.fa_name ?? 'Details View' },
                ]}
              />
              <Box
                height={50}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  paddingBottom: 1.5,
                  paddingTop: 1,
                }}
              >
                <Stack pl={4}>
                  <Typography variant="h3">
                    <Stack flex={1} flexDirection={"row"} gap={1}>
                      {frameworkAgreement?.fa_name || ''}
                      {frameworkAgreement?.promoted && (
                        <IconWrapper
                          icon="rocket"
                          sx={({ palette }) => ({ svg: { width: 16, height: 16, stroke: palette.primary.main, color: palette.primary.main }, display: "flex" })}
                          height={16}
                          display={"flex"}
                          alignSelf={"center"}
                        />
                      )}
                      {account.super_admin && (
                        <Stack flex={1} sx={{justifyContent: "center"}}>
                          <Box
                            borderRadius="100%"
                            width={8}
                            height={8}
                            border="1px solid"
                            borderColor={frameworkAgreement?.Status === 'active' ? 'success.light' : 'error.light'}
                            bgcolor={frameworkAgreement?.Status === 'active' ? 'success.light' : 'error.light'}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Typography>
                  <Typography variant='caption' fontWeight={600}>
                    {`${frameworkAgreement.MainBuyer.Name} (${frameworkAgreement.ref_number})`}
                  </Typography>
                </Stack>

                {(account.super_admin || (user.company_id === frameworkAgreement.main_buyer_id && user.Company.is_premium && user.Company.subscription_level === "gold")) && (
                  <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10 }}>
                    <Stack gap={0.5}>
                      {account.super_admin && (
                        <>
                          <EditForm frameworkAgreement={frameworkAgreement} refetch={refetchFrameworkAgreement} />

                          <StatusToggleButton
                            refetch={refetchFrameworkAgreement}
                            status={frameworkAgreement?.Status}
                          />
                        </>
                      )}

                      <BoostToggleButton
                        refetch={refetchFrameworkAgreement}
                        boosted={frameworkAgreement?.promoted}
                      />
                    </Stack>
                  </AccordionButton>
                )}
              </Box>

              <DetailViewTabList
                value={tab}
                onChange={switchTab}
                tabs={[
                  { label: 'Details' },
                  { label: 'Buyers', count: buyersCount },
                  { label: 'Suppliers', count: suppliersCount },
                  { label: 'Files', count: totalFiles },
                ]}
              />

              <Stack
                px={4}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
                height="100%"
              >
                {error && (
                  <PremiumBanner message={`This Agreement is only available for Premium members. Click here for more information`} handleSuccess={refetchFrameworkAgreement} currentLevel={user.Company.subscription_level} />
                  // <div>{error?.message}</div>
                )}
                {!error && (
                  <>
                {/* {tab === "details" && ( */}
                  <DetailsTab currentTab={tab} frameworkAgreement={frameworkAgreement} />
                {/* )} */}

                {/* {tab === "buyers" && ( */}
                  <BuyersTab
                    currentTab={tab}
                    refetchBuyersCount={refetchBuyersCount}
                  />
                {/* )} */}

                {/* {tab === "suppliers" && ( */}
                  <SuppliersTab currentTab={tab} refetchSuppliersFiles={refetchSuppliersFiles} />
                {/* )} */}

                {/* {tab === "files" && ( */}
                  <FilesTab
                    currentTab={tab}
                    suppliers={suppliersSorted}
                    suppliersFilesCount={suppliersFilesCount}
                    generalFilesCount={filesCount}
                    totalFilesCount={totalFiles}
                  />
                {/* )} */}
                </>
              )}
              </Stack>
            </Stack>
          )
        )
      )}
    </ContentLayout>
  );
};
