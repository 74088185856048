import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const update = ({
  dpsID,
  dpsName,
  mainBuyerId,
  refNumber,
  description,
  startDate,
  endDate,
  moreInfoURL,
  postingDate,
  value,
  competenceAreas,
}) =>
  axios.put(
    `/api/dps/${dpsID}`,
    JSON.stringify({
      dps_name: dpsName,
      ref_number: refNumber,
      Description: description,
      main_buyer_id: mainBuyerId,
      start_date: startDate,
      end_date: endDate,
      more_info_url: moreInfoURL,
      posting_date: postingDate,
      value: value,
      competence_areas: competenceAreas,
    })
  );

export const useUpdate = (refetch) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: update,
    onSuccess: () => {
      enqueueSnackbar('DPS was succesfully updated', {
        variant: 'success',
      });

      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
