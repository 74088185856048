import React, { useRef, useEffect } from 'react';
import { ContentLayout } from 'website/components/Layout/components/ContentLayout';

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Box,
  useMediaQuery,
  Button,
} from '@mui/material';
import { IconWrapper } from 'components/Icon';
import { paths } from 'routes';

export const Pricing = () => {
  const tableRef = useRef(null);
  const rowRef = useRef(null);
  const rowRefMobile = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    const updateWidth = () => {
      if (tableRef.current) {
        const tableWidth = tableRef.current.getBoundingClientRect().width;
        if (rowRef.current) {
          rowRef.current.style.width = `${tableWidth}px`;
        }

        if (rowRefMobile.current) {
          rowRefMobile.current.style.width = `${tableWidth}px`;
        }
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const allFeatures = {
    'Tenders': [
      { id: 't1', name: 'Access open tenders (with link)', description: 'Access tender documents for tenders promoted by the buyer.' },
      { id: 't2', name: 'Access tender documents', description: 'Access tender documents directly on Delegon.' },
      { id: 't3', name: 'Access bidders', description: 'Access a list of organizations who submitted bids after the award has been given.' },
      { id: 't4', name: 'Access award messages', description: 'Access the award message on Delegon.' },
      { id: 't5', name: 'Access evaluation reports', description: 'Access the evaluation report on Delegon.' },
      { id: 't6', name: 'Access bid documents', description: 'Access offer documents submitted by the bidders.' },
      { id: 't7', name: 'Prioritized document collection', description: 'Are you missing a document or a Tender? Notify us and we will collect it for free.' },
    ],
    'Framework agreements': [
      { id: 'a1', name: 'Access promoted agreements', description: 'Access information about agreements promoted by the buyer.' },
      { id: 'a2', name: 'Access all agreements', description: 'Access information about all agreements on Delegon.' },
      { id: 'a3', name: 'Access agreement documents', description: 'Access agreement documents for all suppliers on an agreement.' },
      { id: 'a4', name: 'Access rate cards', description: 'Access rate card per supplier (if shared by the buyer).' },
      { id: 'a5', name: 'Prioritized document collection', description: 'Are you missing a document or a framework agreement? Notify us and we will collect it for free.' },
    ],
    'Dynamic Purchasing Systems': [
      { id: 'd1', name: 'Access promoted DPS\'s', description: 'Access to Dynamic Purchasing Systems promoted by the buyer.' },
      { id: 'd2', name: 'Access all DPS\'s', description: 'Access to all Dynamic Purchasing Systems on Delegon.' },
      { id: 'd3', name: 'Prioritized document collection', description: 'Are you missing a document or a DPS? Notify us and we will collect it for free.' },
    ],
    'Other': [
      { id: 'o1', name: 'Newsletter subscription', description: 'Subscribe to our newsletter to get information about new awards and other industry happenings.' },
      { id: 'o2', name: 'Manage your company profile', description: 'Upload logo and company description to your company profile.' },
      { id: 'o3', name: 'Extended sorting', description: 'Sort list views based on e.g. end dates, start dates and other parameters.' },
    ],
  };

  const tiers = [
    {
      title: 'Free',
      price: '0',
      currency: 'SEK',
      focused: false,
      features: {
        'Tenders': ['t1'],
        'Framework agreements': ['a1'],
        'Dynamic Purchasing Systems': ['d1'],
        'Other': ['o1'],
      },
    },
    {
      title: 'Silver',
      price: '1.995',
      currency: 'SEK',
      focused: true,
      features: {
        'Tenders': ['t1', 't2', 't3', 't4', 't5'],
        'Framework agreements': ['a1', 'a2'],
        'Dynamic Purchasing Systems': ['d1', 'd2'],
        'Other': ['o1', 'o2', 'o3'],
      },
    },
    {
      title: 'Gold',
      price: '2.995',
      currency: 'SEK',
      focused: false,
      features: {
        'Tenders': ['t1', 't2', 't3', 't4', 't5', 't6', 't7'],
        'Framework agreements': ['a1', 'a2', 'a3', 'a4', 'a5'],
        'Dynamic Purchasing Systems': ['d1', 'd2', 'd3'],
        'Other': ['o1', 'o2', 'o3'],
      },
    },
  ];

  return (
    <ContentLayout>
      <Container
        maxWidth={false}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
          margin: '0 auto',
          my: 4,
          p: {xs: 0, md: 0},
          px: {xs: 4, md: 0},
        }}
      >
      <>
      <TableContainer sx={{width: '100%', tableLayout: "fixed", overflowX: "initial"}}>
        <Table sx={{ width: '100%', tableLayout: "fixed", mt: {xs: 6.2, md: 8.6} }} aria-label="comparison table" ref={tableRef}>
          <TableHead>
          <TableRow
            ref={rowRefMobile}
            sx={{
              position: 'fixed',
              top: 114,
              height: 30,
              zIndex: 10,
              bgcolor: '#fff',
              display: { xs: 'flex', md: 'none' },
              width: 1,
            }}
          />
          <TableRow
            ref={rowRefMobile}
            sx={{
              position: 'fixed',
              top: 144,
              zIndex: 10,
              bgcolor: '#fff',
              display: { xs: 'flex', md: 'none' },
            }}
          >
            {tiers.map((tier) => (
              <TableCell key={tier.title} align="center" sx={{ backgroundColor: tier.focused ? 'background.primary' : '#fff', borderBottom: 'none', pb: 1, width: 1, flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 600,
                    lineHeight: "28px",
                    color: tier.focused ? '#fff' : 'text.custom',
                    flex: 1,
                    textAlign: "center"
                  }}
                >
                  {tier.title}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow
            ref={rowRef}
            sx={{
              position: 'fixed',
              top: 114,
              height: 40,
              zIndex: 10,
              bgcolor: '#fff',
              display: { xs: 'none', md: 'flex' },
              width: 1,
            }}
          />
          <TableRow
            ref={rowRef}
            sx={{
              position: 'fixed',
              top: 154,
              flex: 1,
              zIndex: 10,
              display: { xs: 'none', md: 'flex' },
              bgcolor: "#fff",
            }}>
            <TableCell sx={{ borderBottom: 'none', width: 1, flex: 1 }} />
            {tiers.map((tier) => (
              <TableCell key={tier.title} sx={{ backgroundColor: tier.focused ? 'background.primary' : '#fff', borderBottom: 'none', pb: 1, width: 1, flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '2rem',
                    fontWeight: 600,
                    lineHeight: 1.15625,
                    color: tier.focused ? '#fff' : 'text.custom',
                    flex: 1,
                    textAlign: "center"
                  }}
                >
                  {tier.title}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow sx={{display: {xs: 'table-row', md: 'table-row'}}}>
            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}/>
            {tiers.map((tier) => (
              <TableCell
                key={tier.price}
                align="center"
                sx={{ backgroundColor: tier.focused ? 'background.primary' : '#fff', pt: 1 }}
              >
                <Box>
                  <Box justifyContent={"center"} display={"flex"} flexDirection={{xs: "column", sm: "row"}}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: {xs: '1.125rem', md: '2rem'},
                        fontWeight: 600,
                        lineHeight: 1.15625,
                        color: tier.focused ? "#fff" : "text.custom",
                        textAlign: "center"
                      }}
                    >
                      {tier.price}
                    </Typography>
                    <Typography variant="subtitle1" display={{xs: "none", sm: "block"}} sx={{whiteSpace: 'pre-wrap'}}>
                      {' '}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: {xs: '1.125rem', md: '2rem'},
                        fontWeight: 600,
                        lineHeight: 1.15625,
                        color: tier.focused ? "#fff" : "text.custom",
                        textAlign: "center"
                      }}
                    >
                      {tier.currency}
                    </Typography>
                  </Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: {xs: '0.7rem', md: '1.25rem'},
                        fontWeight: 500,
                        lineHeight: 1,
                        color: tier.focused ? "#fff" : "text.custom",
                        textAlign: "center"
                      }}
                    >
                     / Month / User*
                    </Typography>
                  </Box>
              </TableCell>
            ))}
          </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(allFeatures).map((featureGroup) => (
              <>
                <TableRow sx={{ backgroundColor: 'background.pricing' }}>
                  <TableCell colSpan={isMobile ? tiers.length : tiers.length + 1} sx={{p:1.25}}>
                    <Typography variant="subtitle2" sx={{fontWeight: 600, fontSize: "1.125rem", lineHeight: "28px"}}>{featureGroup}</Typography>
                  </TableCell>
                </TableRow>
                {allFeatures[featureGroup].map((feature) => (
                  <>
                  <TableRow
                    sx={{
                      display: { xs: 'table-row', md: 'none' },
                    }}
                  >
                    <TableCell colSpan={tiers.length} sx={{borderBottom: 'none'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', fontWeight: 500, lineHeight: "24px"}}>
                        <Tooltip
                          title={feature.description || 'No description available'}
                          placement="top-start"
                          enterTouchDelay={1}
                          leaveTouchDelay={5000}
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [10, -10]
                                  }
                                }
                              ]
                            },
                            tooltip: {
                              sx: {
                                color: "#fff",
                                backgroundColor: "background.primary",
                                maxWidth: '200px',
                                border: "0.806px solid background.primary",
                                borderRadius: '19.72px',
                                padding: 2,
                                fontSize: '1rem',
                                fontWeight: 400,
                                lineHeight: '1.487',
                                letterSpacing: "-0.08px",
                                textAlign: 'left',
                              },
                            },
                          }}
                        >
                          <Box sx={{mr:0.5}}>
                            <IconWrapper
                              icon="info"
                              sx={({palette}) => ({
                                svg: {
                                  stroke: palette.background.primary,
                                  strokeWidth:2,
                                  height: 16,
                                  width: 16,
                                  mt: -0.3,
                                }
                              })}
                            />
                          </Box>
                        </Tooltip>
                        {feature.name}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={feature}
                    sx={{ 
                      borderBottom: '2px solid #F3F3F3',
                      px: 1,
                      width: 1,
                      height: 40,
                    }}
                  >
                    <TableCell component="th" scope="row" sx={{p:1.25, display: {xs: "none", md: "table-cell"}}}>
                      <Box sx={{display: 'flex', alignItems: 'center', fontWeight: 500, lineHeight: "24px"}}>
                        <Tooltip
                          title={feature.description || 'No description available'}
                          placement="top-start"
                          slotProps={{
                            popper: { 
                              modifiers: [
                                {
                                  name: 'offset', 
                                  options: { 
                                    offset: [10, -10] 
                                  } 
                                }
                              ]
                            },
                            tooltip: {
                              sx: {
                                color: "#fff",
                                backgroundColor: "background.primary",
                                maxWidth: '200px',
                                border: "0.806px solid background.primary",
                                borderRadius: '19.72px',
                                padding: 2,
                                fontSize: '1rem',
                                fontWeight: 400,
                                lineHeight: '1.487',
                                letterSpacing: "-0.08px",
                                textAlign: 'left',
                              },
                            },
                          }}
                        >
                          <Box sx={{mr:0.5}}>
                            <IconWrapper
                              icon="info"
                              sx={({palette}) => ({
                                svg: {
                                  stroke: palette.background.primary,
                                  strokeWidth:2,
                                  height: 16,
                                  width: 16,
                                  mt: -0.3,
                                }
                              })}
                            />
                          </Box>
                        </Tooltip>
                        {feature.name}
                      </Box>
                    </TableCell>
                    {tiers.map((tier, index) => (
                      <TableCell key={tier.title + index} align="center"  sx={{p:1.25}}>
                        {tier.features[featureGroup]?.includes(feature.id) && (
                          <IconWrapper
                          icon="confirm"
                          sx={({palette}) => ({
                            svg: {
                              stroke: "#2A6672",
                              strokeWidth:2,
                              height: 16,
                              width: 16,
                            }
                          })}
                        />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  </>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant='body2' sx={{fontSize: "1rem", fontWeight:500, lineHeight: "28px", mt: 2}}>* All licences are invoiced quarterly</Typography>
      </>
    </Container>
    <Button
      size="medium"
      variant="contained"
      component="a"
      href={paths.auth.register}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        backgroundColor: "#62A7B7",
        '&:hover': {
          backgroundColor: "#62A7B7",
        },
        width: 200,
        height: 48,
        fontSize: "1rem",
        fontWeight: "700",
        color: "#fff",
        lineHeight: 1.5,
        display: {xs: "flex", md: "none"},
        mb: 3,
        mt: -9,
      }}
    >
      Sign up for free
    </Button>
    </ContentLayout>
  );
};