import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { ContentLayout } from 'website/components/Layout/components/ContentLayout';

export const TermsAndConditions = () => {
  return (
    <ContentLayout>
      <Box sx={{
          pt:5.625,
          pb: 3,
          "& .MuiTypography-body2": {
            color: "text.primary",
            fontSize: '0.75rem',
            fontWeight: 500,
          },
          mx: {xs: 3, md: 0},
        }}>
        <Typography variant="h5" color={"primary.light"} fontWeight={600} lineHeight={"normal"} mb={3}>
          v1.0
        </Typography>
        <Typography variant="h1" mb={4}>
          Terms and Conditions
        </Typography>
        <Stack flex={1} direction={{xs: "column", md: "row"}} width="100%" height="100%" gap={2}>
          <Stack flex={1} direction="column" gap={4}>
            <Stack direction="column" gap={2}>
              <Typography>
                1. General
              </Typography>
              <Typography variant='body2'>
                1.1 The following terms applies between Delegon AB, org number 559484-8375 (referred to as “Delegon”) and the organization who has registered an organization at Delegon.com (referred to as “The Customer”).
              </Typography>
              <Typography variant='body2'>
                1.2 Delegon reserves the right to update those terms and conditions at any point.
              </Typography>
              <Typography variant='body2'>
                1.3 Users are notified via the login page when user terms are update to a new version.
              </Typography>
              <Typography variant='body2'>
                1.4 This contract is governed by the laws of Sweden.
              </Typography>
              <Typography variant='body2'>
                1.5 Any disputed shall be settled by the district court of Stockholm, Sweden.
              </Typography>
              <Typography variant='body2'>
                1.6 The court proceedings shall be held in Swedish.
              </Typography>
            </Stack>
            <Stack direction="column" gap={2}>
              <Typography>
                2. User management
              </Typography>
              <Typography variant='body2'>
                2.1 The user registering an organization on Delegon is given administrative user rights.
              </Typography>
              <Typography variant='body2'>
                2.2 The Customer is responsible for ensuring that only authorized users get access.
              </Typography>
            </Stack>
            <Stack direction="column" gap={2}>
              <Typography>
                3. Paid subscriptions
              </Typography>
              <Typography variant='body2'>
                3.1 Paid subscriptions are invoiced with payment terms of 30 days net.
              </Typography>
              <Typography variant='body2'>
                3.2 All prices are stated exclusive of VAT.
              </Typography>
              <Typography variant='body2'>
                3.3 Invoices are distributed via email to the user making the purchase.
              </Typography>
              <Typography variant='body2'>
                3.4 Delayed payment may result in the account being blocked until full payment has been received.
              </Typography>
              <Typography variant='body2'>
                3.5 Downgrades and cancellations of subscriptions take effect at the next extension date.
              </Typography>
              <Typography variant='body2'>
                3.6 Upgrades of subscriptions are managed as a new purchase (with a new binding period) where the remaining amount of the previous subscription period is applied as a discount on the new subscription period.
              </Typography>
              <Typography variant='body2'>
                3.7 Re-funds for already purchased subscriptions are not available.
              </Typography>
              <Typography variant='body2'>
                3.8 Delegon may change the price of subscriptions with 30 days’ notice. The new price will apply to all extensions, not cancelled before the extension date.
              </Typography>
              <Typography variant='body2'>
                3.9 All subscriptions are invoiced up front for 3 months (the “Binding period”) unless otherwise agreed in writing.
              </Typography>
              <Typography variant='body2'>
                3.10 Subscriptions are automatically renewed for a new period at the extension date unless they have been cancelled prior to the end of the binding period.
              </Typography>
              <Typography variant='body2'>
                3.11 Delegon reserves the right to charge statutory fees for reminders and debt collection activities.
              </Typography>
              <Typography variant='body2'>
                3.12 Delegon may change the functionality of a paid module. In case such means a reduction of the available service level. The Customer has the right to cancel the paid subscription of notifying Delegon within 30 days of the change.
              </Typography>
              <Typography variant='body2'>
                3.13 In case of termination of a paid module due to a change in functionality, Delegon shall re-pay the remainder of the pre-paid time period within 30 days of getting access to payment details.
              </Typography>
              <Typography variant='body2'>
                3.14 Promotional codes and rebates are applicable until the next renewal date.
              </Typography>
              <Typography variant='body2'>
                3.15 Promotional codes and rebates are only applicable for new subscriptions where The Customer did not have a paid account within the last 6 months.
              </Typography>
            </Stack>
          </Stack>
          <Stack flex={1} direction="column" gap={4}>
            <Stack direction="column" gap={2}>
              <Typography>
                4. Service limitations
              </Typography>
              <Typography variant='body2'>
                4.1 Delegon collects and presents data and documents on a best-effort basis. There is no guarantee that information is complete, accurate or up to date.
              </Typography>
              <Typography variant='body2'>
                4.2 Delegon is not a tender monitoring platform. Tenders and other documents may be posted with delay or not at all.
              </Typography>
            </Stack>
            <Stack direction="column" gap={2}>
              <Typography>
                5. Data
              </Typography>
              <Typography variant='body2'>
                5.1 Delegon tracks how users interact with the Delegon platform to improve the service.
              </Typography>
              <Typography variant='body2'>
                5.2 Delegon reserves the right to share user data with 3:rd party providers of services used to deliver or market Delegon.
              </Typography>
              <Typography variant='body2'>
                5.3 Delegon reserves the right to share data with, or transfer data to, organizations within the same company group.
              </Typography>
              <Typography variant='body2'>
                5.4 Delegon stores personal data on servers within the European Union.
              </Typography>
              <Typography variant='body2'>
                5.5 For questions related to personal data, please reach out to <a href="mailto:hello@delegon.com" style={{ textDecoration: "none" }}>
                hello@delegon.com</a>.
              </Typography>
            </Stack>
            <Stack direction="column" gap={2}>
              <Typography>
                6. Fair use policy
              </Typography>
              <Typography variant='body2'>
                6.1 A user is not allowed to share documents with individuals outside The Customer.
              </Typography>
              <Typography variant='body2'>
                6.2 Users are not allowed to create shared accounts where information is accessed by multiple individuals though the same user account.
              </Typography>
              <Typography variant='body2'>
                6.3 Users are not allowed to register accounts with functional email addresses.
              </Typography>
              <Typography variant='body2'>
                6.4 Users are not allowed to register accounts with anonymous email service providers. 
              </Typography>
              <Typography variant='body2'>
                6.5 All information stated on Delegon should be accurate and truthful.
              </Typography>
              <Typography variant='body2'>
                6.6 Users must comply with local legislation when using Delegon.
              </Typography>
              <Typography variant='body2'>
                6.7 Users must be an authorized representative of The Customer to register an account with Delegon.
              </Typography>
              <Typography variant='body2'>
                6.8 Delegon reserves the right to transfer a user account over to its rightful owner in case of unrightful registrations.
              </Typography>
              <Typography variant='body2'>
                6.9 Delegon reserves the right to terminate accounts that are in violation of the fair use policy. No refunds will be made for paid subscriptions.
              </Typography>
              <Typography variant='body2'>
                6.10 Delegon reserves the right to charge a penalty fee of 25.000 SEK for each violation of the fair use policy. The penalty fee does not limit liability for damages.
              </Typography>
            </Stack>
            <Stack direction="column" gap={2}>
              <Typography>
                7. Damages
              </Typography>
              <Typography variant='body2'>
                7.1 Damages are only paid by Delegon for proven direct damage.
              </Typography>
              <Typography variant='body2'>
                7.2 Any liability for damages is limited to fees paid by the The Customer for the past twelve (12) months.
              </Typography>
            </Stack>
            <Stack direction="column" gap={2}>
              <Typography>
                8. Intellectual Property
              </Typography>
              <Typography variant='body2'>
                8.1 Delegon is a registered trademark within the European Union.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </ContentLayout>
  );
};
