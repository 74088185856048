import { axios } from 'lib';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

const patchFrameworkAgreement = ({ frameworkAgreementId, name, value }) => {
  try {
    return axios.patch(
      `/api/framework_agreements/${frameworkAgreementId}/field`,
      JSON.stringify({ name: name, value: value })
    );
  } catch (error) {
    return error;
  }
};

export const usePatchFrameworkAgreement = (refetch, message) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: patchFrameworkAgreement,
    onSuccess: () => {
      enqueueSnackbar(message || 'Successfully updated', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      refetch();
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
