import React from 'react';
import { Box, Typography, Stack } from "@mui/material";
import { ReactComponent as ProactiveIcon } from '../../assets/buyers/Proactive.svg';
import { ReactComponent as MarketRates } from '../../assets/buyers/MarketRates.svg';
import { ReactComponent as AttractSuppliers } from '../../assets/buyers/AttractSuppliers.svg';
import { ReactComponent as OutsideBox } from '../../assets/buyers/OutsideBox.svg';
import { ReactComponent as Benchmark } from '../../assets/buyers/Benchmark.svg';
import { ReactComponent as KnowSuppliers } from '../../assets/buyers/KnowSuppliers.svg';


export const Advantages = () => {
  const firstRow = [
    {
      title: "Access market rates",
      text: "Access unbiased market rates per role and experience level.",
      icon: <MarketRates />
    },
    {
      title: "Become proactive",
      text: "Understand how your requirements will impact your supply chain.",
      icon: <ProactiveIcon />
    },
    {
      title: "Think outside the box",
      text: "Analyze tenders from your peers and make use of their best ideas.",
      icon: <OutsideBox />
    },
  ];

  const secondRow = [
    
    {
      title: "Attract more suppliers",
      text: "Increase your visibility and attract more suppliers.",
      icon: <AttractSuppliers />
    },
    {
      title: "Benchmark to peers",
      text: "Compare your contractual terms with peers in the market.",
      icon: <Benchmark />
    },
    {
      title: "Know your suppliers",
      text: "Learn who else your suppliers are providing services to.",
      icon: <KnowSuppliers />
    },
  ];

  return (
    <Stack
     sx={{
      display: "flex",
      gap: 8,
      pb: 8,
      maxWidth: "100vw",
     }}
    >
      <Stack gap={2} flexDirection={{xs: "column", md: "row"}} sx={{mx: {xs:4, md: 0}}}>      
        <Typography
          flex={1}
          variant='h2'
          sx={{
            fontSize: "2.5rem",
            fontWeight: 600,
            lineHeight: 1.25,
            alignSelf: "center",
            textAlign: {xs: "center", md: "start"},
          }}
        >
          Whats in it for you?
        </Typography>
        <Stack
          flex={1}
          gap={1}
          sx={{
            '& p': {
              color: "rgba(0, 0, 0, 0.70)",
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: 1.5,
            }
          }}
        >
          <Typography variant='body2'>
          Delegon offers Swedish buyers of consulting- and staffing services an opportunity to improve their purchases by learning from peers, benchmarking their terms and increasing their visibility in the market.
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{mx: {xs:4, md: 0}}}>
        <Stack flexDirection={{xs: "column", md: "row"}}>
          {firstRow.map((item, index) => (
            <Stack
              flex={1}
              sx={{
                py: 3,
                px: {xs: 0, md: 3},
                gap: 1,
                border: '1px solid #F1F1F1',
                borderLeft: {xs: 'none', md: index === 0 ? 'none' : '1px solid #F1F1F1'},
                borderRight: {xs: 'none', md: index === secondRow.length - 1 ? 'none' : '1px solid #F1F1F1'},
              }}
            >
              <Box
                width={58}
                height={58}
              >
                {item.icon}
              </Box>
              <Typography
                variant='body1'
                sx={{
                  textTransform: "uppercase",
                  color: "text.custom",
                  lineHeight: 1.6,
                  fontSize: "1.25rem",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  color: "#4D4D4D",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  fontWeight: 400
                }}
              >
                {item.text}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack flexDirection={{xs: "column", md: "row"}}>
          {secondRow.map((item, index) => (
            <Stack
              flex={1}
              sx={{
                py: 3,
                px: {xs: 0, md: 3},
                gap: 1,
                border: '1px solid #F1F1F1',
                borderLeft: {xs: 'none', md: index === 0 ? 'none' : '1px solid #F1F1F1'},
                borderRight: {xs: 'none', md: index === secondRow.length - 1 ? 'none' : '1px solid #F1F1F1'},
              }}
            >
              <Box
                width={58}
                height={58}
              >
                {item.icon}
              </Box>
              <Typography
                variant='body1'
                sx={{
                  textTransform: "uppercase",
                  color: "text.custom",
                  lineHeight: 1.6,
                  fontSize: "1.25rem",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  color: "#4D4D4D",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  fontWeight: 400
                }}
              >
                {item.text}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}