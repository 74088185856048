import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, Stack, Typography, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { IconWrapper } from 'components/Icon';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Status } from './Status';

dayjs.extend(utc);
dayjs.extend(timezone);

const userTimeZone = dayjs.tz.guess();

export const AdditionalPeriods = ({ initialPeriod = null, setInitialPeriod = null, additionalPeriods, handleAdditionalPeriods = null, readOnly = false, tender = false, methods = null }) => {
  const [showPeriods, setShowPeriods] = useState(true);
  const [dateFormat, setDateFormat] = useState('YYYY-MM-DD');
  const [allPeriods, setAllPeriods] = useState([]);
  // const [initialPeriod, setInitialPeriod] = useState([]);
  
  // useEffect(() => {
  //   if (initialEndDate) {
  //     const formattedDate = initialEndDate.format('L');
  //     const parts = formattedDate.split('/');
  //     if (parts.length === 3) {
  //       console.log(`${parts}/${parts}/${parts}`);
        
  //       setDateFormat(`${parts[0]}/${parts[1]}/${parts[2]}`);
  //     }
  //   }
  // }, [initialEndDate]);
  

  const addPeriod = () => {
    setShowPeriods(true);
    const lastEndDate = additionalPeriods.length > 0 ? additionalPeriods[additionalPeriods.length - 1].endDate : initialPeriod[0]?.endDate;
    const lastStatus = additionalPeriods.length > 0 ? additionalPeriods[additionalPeriods.length - 1].status : 'NA';
    if (!lastEndDate) return;
    const newStartDate = dayjs(lastEndDate).add(1, 'day');
    handleAdditionalPeriods([
      ...additionalPeriods,
      {
        startDate: newStartDate,
        endDate: null,
        status: tender ? 'used' : lastStatus !== 'used' ? lastStatus : 'NA',
      },
    ]);
  };

  const removePeriod = (index) => {
    const updatedPeriods = [...additionalPeriods];
    updatedPeriods.splice(index, 1);
    handleAdditionalPeriods(updatedPeriods);
  };

  const handleIntialStartDateChange = (index, date) => {
    const updatedPeriods = [...initialPeriod];
    updatedPeriods[index].startDate = date;
    
    setInitialPeriod(updatedPeriods);
    if (methods !== null) {
      methods.setValue("startDate", date);
    }

    if(date !== null) {
      methods.trigger("startDate");
    }
  };

  const handleAdditionalEndDateChange = (index, date) => {
    const adjustedDate = date.tz(userTimeZone);
    if (index === 0) {
      const updatedPeriods = [...initialPeriod];
      updatedPeriods[index].endDate = adjustedDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
      setInitialPeriod(updatedPeriods);
      if (methods !== null) {
        methods.setValue("endDate", adjustedDate.format('YYYY-MM-DDTHH:mm:ss[Z]'));
      }
    } else {
      const updatedPeriods = [...additionalPeriods];
      updatedPeriods[index-1].endDate = adjustedDate.format('YYYY-MM-DDTHH:mm:ss[Z]');
      handleAdditionalPeriods(updatedPeriods);
    }

    if(date !== null) {
      methods.trigger("endDate");
    }
  };

  const handleStatusChange = (index, value) => {
    const updatedPeriods = [...additionalPeriods];
    updatedPeriods[index].status = value;

    if (value !== 'used') {
      for (let i = index + 1; i < updatedPeriods.length; i++) {
        updatedPeriods[i].status = value;
      }
    }
    handleAdditionalPeriods(updatedPeriods);
  };

  useEffect(() => {
    let period = []
    if (initialPeriod.length === 0) {
      period = [
        {
          label: "Initial",
          startDate: null,
          endDate: null,
          status: 'used',
        },
      ];
      setInitialPeriod(period); 
    }

    setAllPeriods([
      ...initialPeriod.length === 0 ? period : initialPeriod,
      ...additionalPeriods,
    ]);
  }, [ additionalPeriods]);
  

  return (
      <Stack spacing={2} width={1}> 
        <Stack direction="row" justifyContent={"space-between"} alignItems="center" width={1} gap={2}>
          <Typography variant="caption" color={"text.primary"} sx={{width: 70, display: "flex"}}>Period</Typography>
          <Typography variant="caption" color={"text.primary"} sx={{width: readOnly ? 120 : "reset", flex: 1, display: "flex"}}>Start Date</Typography>
          <Typography variant="caption" color={"text.primary"} sx={{width: readOnly ? 120 : "reset", flex: 1, display: "flex"}}>End Date</Typography>
          {!tender && (
            <Typography variant="caption" color={"text.primary"} sx={{width: 50, flex: readOnly ? "reset" : 0.5, display: "flex"}}>Status</Typography>
          )}
          {!readOnly && (
            <Box
              sx={{
                flex: 0.1,
                display: "flex",
              }}
            >
            <IconWrapper
              onClick={addPeriod}
              icon="circlePlus"
              sx={{
                height: 24,
                svg: { stroke: 'white' },
                "&:hover": { 
                  cursor: "pointer"
                }
              }}
            />
            </Box>
          )}
        </Stack>

        {showPeriods &&
          allPeriods.map((period, index) => {
            return (
            <Stack direction="row" justifyContent={"space-between"} alignItems="center" key={index} width={1} gap={2}>
              <Typography variant="caption" sx={{width: 70, display: "flex"}}>{period.label ? period.label : `Option ${index}`}</Typography>
              {(index === 0 && !readOnly) ? (
                <DatePicker
                  label=""
                  value={dayjs(period.startDate)}
                  onChange={(date) => handleIntialStartDateChange(index, date)}
                  // slots={{ textField: TextField }} // Specify the TextField slot
                  slotProps={{
                    textField: {
                      error: !!(index === 0 && methods.formState.errors?.startDate),
                      helperText: index === 0 && methods.formState.errors?.startDate?.message,
                      size: 'small',
                    },
                  }}
                  format={dateFormat}
                  maxDate={dayjs(period.endDate)}
                  sx={{
                    flex: 1,
                    display: "flex",
                    minWidth: 165,
                  }}
                />
              ) : (
                <TextField
                  label=""
                  value={dayjs(period.startDate).format(dateFormat)}
                  InputProps={{
                    readOnly: true,
                  }}
                  size="small"
                  sx={{
                    width: readOnly ? 120 : "reset",
                    display: "flex",
                    flex: 1,
                    minWidth: 115,
                  }}
                  disabled
                />
              )}
              {readOnly ? (
                <>
                  <TextField
                    label=""
                    value={dayjs(period.endDate).format(dateFormat)}
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    sx={{
                      width: 120,
                      display: "flex",
                      flex: 1,
                    }}
                    disabled
                  />
                  {!tender && (
                    <Status status={period.status} />
                  )}
                </>
              ) : (
                <>
                  <DatePicker
                    label=""
                    value={period.endDate ? dayjs(period.endDate) : null}
                    onChange={(date) => handleAdditionalEndDateChange(index, date)}
                    slotProps={{
                      textField: {
                        error: !!(index === 0 && methods.formState.errors?.endDate),
                        helperText: index === 0 && methods.formState.errors?.endDate?.message,
                        size: 'small',
                      },
                    }}
                    minDate={dayjs(period.startDate)}
                    format={dateFormat}
                    sx={{
                      flex: 1,
                      display: "flex",
                      minWidth: 165,
                    }}
                    InputProps={{
                      readOnly: (index === 0 && additionalPeriods.length > 0) || (index !== additionalPeriods.length),
                    }}
                    disabled = {(index === 0 && additionalPeriods.length > 0) || (index !== additionalPeriods.length)}
                  />
                  {!tender && (
                    <FormControl
                      size="small"
                      sx={{
                        display: "flex",
                        flex: 0.5,
                        minWidth: 100,
                      }}
                    >
                      <Select
                        value={period.status}
                        label="Status"
                        onChange={(e) => handleStatusChange(index-1, e.target.value)}
                        readOnly={(index === 0 || allPeriods[index-1].status !== 'used')}
                        disabled={(index === 0 || allPeriods[index-1].status !== 'used')}
                      >
                        <MenuItem value="used">Used</MenuItem>
                        <MenuItem value="not used">Not Used</MenuItem>
                        <MenuItem value="NA">Not Set</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </>
              )}
              
              {!readOnly && (
                <Box sx={{display: "flex", flex: 0.1}}>
                  {index !== 0 && index === additionalPeriods.length && (
                  <IconWrapper
                    onClick={() => removePeriod(index-1)}
                    icon="xMark"
                    sx={{
                      height: 24,
                      mt: -0.5,
                      "&:hover": { 
                        cursor: "pointer"
                      }
                    }}
                  />
                )}
                </Box>
              )}
            </Stack>
          )})}
      </Stack>
  );
}