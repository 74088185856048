import React, { useState } from 'react';
import { AuthLayout } from '../components/AuthLayout';
import { PersonalDetailsForm } from '../components/PersonalDetailsForm';
import { CompanyInfoForm } from '../components/CompanyInfoForm';
import { FinishRegistrationForm } from '../components/FinishRegistrationForm';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/material';

export const Register = () => {
  const [state, setState] = useState({
    step: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyEmail: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    country: 'Sweden',
    vat: '',
  });

  const steps = ['', '', ''];

  // proceed to the next step
  const nextStep = () => {
    const { step } = state;
    setState({ ...state, step: step + 1 });
  };

  // Handle fields change
  const handleChange = (input) => (e) => {
    setState({ ...state, [input]: e.target.value });
  };

  const { step } = state;
  // const { firstName, lastName, phoneNumber, companyEmail, password, confirmPassword, companyName, country, vat } = state;
  // const values = { firstName, lastName, phoneNumber, companyEmail, password, confirmPassword, companyName, country, vat }

  return (
    <AuthLayout title="Register">
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          height: `calc(100vh - 70px)`,
          boxSizing: 'border-box',
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: "center",
            px: { xs: 5, sm: 1 },
            pb: { xs: 2, sm: 0 }
          }}
        >
          <Stepper
            // connector={false}
            nonLinear
            activeStep={step}
            sx={{
              mt:5,
              mb: 5,
              "& .MuiStepIcon-root": {
                display: "flex",
              },
              "& .MuiStepLabel-iconContainer": {
                pr: 0,
              },
              "& .MuiSvgIcon-root" : {
                mx: 1,
              }
            }}
          >
            {steps.map((label, index) => (
              <Step key={index} sx={{ px: 1.5 }}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {step === 0 && (
            <PersonalDetailsForm nextStep={nextStep} handleChange={handleChange} values={state} />
          )}
          {step === 1 && (
            <CompanyInfoForm nextStep={nextStep} handleChange={handleChange} values={state} />
          )}
          {step === 2 && (
            <FinishRegistrationForm values={state} />
          )}
        </Box>
      </Box>
    </AuthLayout>
  );
};
