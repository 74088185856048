import { Stack } from '@mui/material';
import React from 'react';
import { Advantages } from 'website/components/buyers/Advantages';
import { CompanyValues } from 'website/components/CompanyValues';
import { ContentLayout } from 'website/components/Layout/components/ContentLayout';
import { Offerings } from 'website/components/buyers/Offerings';
import { TopSection } from 'website/components/buyers/TopSection';

export const Homepage = () => {

  return (
    <ContentLayout>
      <TopSection />
      <Offerings />
      <CompanyValues />
      <Advantages />
    </ContentLayout>
  );
};
