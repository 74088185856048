import React, {useState, useEffect} from 'react';
import { Stack, Typography, Checkbox, FormControlLabel, FormGroup, Button, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DateField, FormProvider, TextField } from 'components/Form';
import { useAuth } from 'lib/auth';
import { paths } from 'routes';
import { AdditionalPeriods } from 'components/AdditionalPeriod/AdditionalPeriod';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const detailsTabName = 'details';

const getDefaultValues = () => ({
  mainBuyer: '',
  refNumber: '',
  description: '',
  moreInfoURL: '',
  startDate: null,
  endDate: null,
  relatedTender: '',
  value: null,
  additionalPeriods: [],
  competenceAreas: [],
});

export const DetailsTab = ({ currentTab, frameworkAgreement }) => {
  const { account } = useAuth();
  const [ loading, setLoading ] = useState(true);
  const [ additionalPeriods, setAdditionalPeriods ] = useState(frameworkAgreement?.additional_periods);
  const [ initialPeriod, setInitialPeriod ] = useState([
    {
      label: "Initial",
      startDate: frameworkAgreement?.start_date,
      endDate: frameworkAgreement?.end_date,
      status: "used",
    }
  ]);

  const methods = useForm({
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    const {
      Description,
      end_date,
      MainBuyer: { Name },
      ref_number,
      start_date,
      more_info_url,
      Tender: {tender_name},
      value,
      additional_periods,
      competence_areas,
    } = frameworkAgreement;

    methods.reset({
      mainBuyer: Name,
      refNumber: ref_number,
      description: Description,
      moreInfoURL: more_info_url,
      startDate: dayjs(new Date(start_date)),
      endDate: dayjs(new Date(end_date)),
      relatedTender: tender_name,
      value: value,
      additionalPeriods: additional_periods,
      competenceAreas: competence_areas,
    });

    setAdditionalPeriods(frameworkAgreement?.additional_periods);
    const tempPeriod = [...initialPeriod];
    tempPeriod[0].startDate = frameworkAgreement?.start_date;
    tempPeriod[0].endDate = frameworkAgreement?.end_date;
    setInitialPeriod(tempPeriod);
    setLoading(false);
  }, [frameworkAgreement])  

  const onSubmit = () => {};

  if (currentTab !== detailsTabName) return null;

  if (loading) {
    return null;
  }

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" /* width="100%" height="100%" */ bgcolor={'background.paper'} gap={2}>
        <Stack flex={1} direction="column" gap={2}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Description
          </Typography>
          <TextField
            name="description"
            // label="Description"
            disabled
            type="text"
            fullWidth
            sx={{
              '& .MuiInputBase-root, .MuiInputBase-input': {
                p: 0,
              },
            }}
            multiline
            minRows={2}
          />
        </Stack>
        <Stack flex={1} direction="column" gap={2}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Details
          </Typography>
          <TextField
            name="value"
            label="Value"
            disabled
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  MSEK
                </InputAdornment>
              )
            }}
            wrapperSx={{width: "48.7%"}}
          />
          <TextField name="relatedTender" label="Related Tender" disabled type="text" fullWidth url={frameworkAgreement?.tender_id ? paths.tendersDetailsView(frameworkAgreement?.tender_id) : ''} />
          {methods.getValues("competenceAreas") && methods.getValues("competenceAreas").length > 0 && (
            <TextField name="competenceAreas" label="Competence Areas" disabled type="text" fullWidth isChip />
          )}
          <AdditionalPeriods initialPeriod={initialPeriod} additionalPeriods={additionalPeriods} handleAdditionalPeriods={null} readOnly />
          {methods.getValues("moreInfoURL") && methods.getValues("moreInfoURL").length > 0 && (
            <>
              <Typography variant="h6" sx={{ my: 1 }}>
                Additional Info
              </Typography>

              <Button
                name="moreInfoURL"
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mb: 0.5, width: 250 }}
                href={methods.getValues("moreInfoURL")}
                target={"_blank"}
              >
                Link to external website
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      </LocalizationProvider>
    </FormProvider>
  );
};
