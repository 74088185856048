import React from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { PaperCard } from 'components/Card';
import { paths } from 'routes';

export const Error = ({message = ''}) => {
  return (
    <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }} sx={{mb:5}}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 3, textAlign: "center" }}>
          Error!
        </Typography>
        <Stack>
          <Typography variant="body1">Error ocurred. Please try again.</Typography>
          {message.length > 0 && (
            <Typography variant="body1">Message: {message}</Typography>
          )}
          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mb: 0.5,
              bgcolor: "primary.light",
              '&:hover': {
                backgroundColor: "primary.light",
              },
            }}
            href={paths.homepage}
          >
            Return to home
          </Button>
        </Stack>
      </Stack>
    </PaperCard>
  );
};
