import React, { useState } from 'react';
import { TextField } from './TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Tooltip } from '@mui/material';
import { IconWrapper } from 'components/Icon';


export const VATNumber = ({
  name,
  label = '',
  sx={},
  required = false,
  onChange = null,
  value = null,
  registration = false,
  ...other
}) => {
  const [vat, setVat ] = useState(value);
  const handleChange = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");
    if (val.length > 10) {
      val = val.slice(0,10);
    }

    setVat(val);
    if (onChange) {
      e.target.value = val;
      onChange(e);
    }
  };

  const handlePaste = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");
    if (val.length > 10) {
      val = val.slice(0,10);
    }

    setVat(val);
  };

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={({ palette }) => ({
              mr:"3px",
              '& .MuiTypography-root': {
                color: palette.grey[300],
              },
              })
            }
          >
            SE
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            sx={({ palette }) => ({
              ml:"3px",
              '& .MuiBox-root': {
                color: palette.grey[300],
              },
              })
            }
          >
            <Box display={"flex"} alignItems="center">
              01
              <Tooltip title="VAT Number without leading 'SE' and trailing '01'" arrow>
                <Box display="flex" alignItems="center" sx={{  position: "absolute", right: 0, mr: 1.6 }}>
                  <IconWrapper
                    icon="info"
                    sx={({palette}) => ({
                      svg: {
                        stroke: palette.grey[400],
                        strokeWidth:2,
                        height: 20,
                        width: 20,
                      }
                    })}
                  />
                </Box>
              </Tooltip>
            </Box>
          </InputAdornment>
        ),
      }}
      required
      fullWidth
      name={name}
      label={label}
      placeholder="xxxxxxxxxxx"
      value={vat}
      sx={({ palette }) => ({
        '& .MuiInputBase-input': {
          pl: "0px!important",
          pr: "0px!important",
          width: registration ? "25%" : "21%",
        },
        '& .MuiInputBase-input::placeholder': {
          // color: palette.grey[700]
        },
        ...sx
      })}
      onChange={handleChange}
      onPaste={handlePaste}
      {...other}
    />
  );
};
