import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { publicRoutes } from './public';
import { protectedRoutes } from './protected';
import { websiteRoutes } from './website';
import { paths, ROOT_PATHS } from './paths';
import { useAuth } from 'lib/auth';

export * from './paths';

export const AppRoutes = () => {
  const { account } = useAuth();

  const commonRoutes = [
    {
      path: '/auth/*',
      element: <Navigate to={account ? paths.frameworkAgreement : ROOT_PATHS.WEBSITE} replace={true} />,
    },
    {
      path: '/*',
      element: <Navigate to={/* account ? paths.frameworkAgreement : */ ROOT_PATHS.WEBSITE} replace={true} />,
    },
  ];
  const routes = account ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes, ...websiteRoutes, ...commonRoutes]);  

  return <>{element}</>;
};
