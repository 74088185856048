import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePatchFrameworkAgreement } from '../api/updateFrameworkAgreementSingleField';
import { AccordionListItem } from 'components/Button';
import { Button, Stack, Typography } from '@mui/material';
import { Modal } from 'components/Modal';

export const BoostToggleButton = ({ boosted, refetch }) => {
  const { id } = useParams();
  const message = `Framework agreement successfully ${!boosted ? "boosted" : "unboosted"}`;
  const { mutateAsync } = usePatchFrameworkAgreement(refetch, message);

  const [open, setOpen] = useState(false);

  const handleCloseModal = () => setOpen(false);

  const handleBoostUpdate = () =>
    mutateAsync({
      frameworkAgreementId: id,
      name: "is_promoted",
      value: boosted ? false : true,
    })
      .then(handleCloseModal)
      .catch(() => {});

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>
        {boosted ? 'Unboost' : 'Boost'}
      </AccordionListItem>

      <Modal open={open} handleClose={handleCloseModal} title="Boost update">
        <Typography variant="body1" fontWeight={500}>
          Are you sure you want to{' '}
          <span style={{ fontWeight: 'bold' }}>
            {boosted ? 'unboost' : 'boost'}
          </span>
          {' '}the framework agreement?{' '}
        </Typography>

        <Stack direction="row" justifyContent="center" gap={1}>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleBoostUpdate}>
            Confirm
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
