import React, {useState, useEffect} from 'react';
import { Stack, Typography, Checkbox, FormControlLabel, FormGroup, Button, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';

import { DateField, FormProvider, TextField } from 'components/Form';
import { useAuth } from 'lib/auth';

const detailsTabName = 'details';

const getDefaultValues = () => ({
  mainBuyer: '',
  refNumber: '',
  description: '',
  moreInfoURL: '',
  postingDate: null,
  startDate: null,
  endDate: null,
  value: null,
  competenceAreas: [],
});

export const DetailsTab = ({ currentTab, dps }) => {
  const { account } = useAuth(); 
  const [ loading, setLoading ] = useState(true);

  const methods = useForm({
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    const {
      Description,
      more_info_url,
      end_date,
      MainBuyer: { Name },
      ref_number,
      start_date,
      posting_date,
      value,
      competence_areas,
    } = dps;   
  
    methods.reset({
      mainBuyer: Name,
      refNumber: ref_number,
      description: Description,
      moreInfoURL: more_info_url,
      postingDate: new Date(posting_date),
      startDate: new Date(start_date),
      endDate: new Date(end_date),
      value: value,
      competenceAreas: competence_areas,
    });
  
    setLoading(false);
  }, [dps]);

  const onSubmit = () => {};

  if (currentTab !== detailsTabName) return null;

  if (loading) {
    return null;
  }

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack direction="row" width="100%" height="100%" bgcolor={'background.paper'} gap={2}>
        <Stack flex={1} direction="column" gap={2}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Description
          </Typography>
          <TextField
            name="description"
            disabled
            type="text"
            fullWidth
            sx={{
              '& .MuiInputBase-root, .MuiInputBase-input': {
                p: 0,
              },
            }}
            multiline
            minRows={2}
          />
        </Stack>
        <Stack flex={1} direction="column" gap={2}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Details
          </Typography>
          <TextField
            name="value"
            label="Value"
            disabled
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  MSEK
                </InputAdornment>
              )
            }}
            wrapperSx={{width: "48.7%"}}
          />
          {methods.getValues("competenceAreas") && methods.getValues("competenceAreas").length > 0 && (
            <TextField name="competenceAreas" label="Competence Areas" disabled type="text" fullWidth isChip />
          )}
          <Stack direction="row" justifyContent="space-between" gap={2} width={"48.7%"}>
            <DateField name="postingDate" label="Posting Date" disabled fullWidth />
          </Stack>
          <Stack direction="row" justifyContent="space-between" gap={2}>
            <DateField name="startDate" label="Start Date" disabled fullWidth />
            <DateField name="endDate" label="End Date" disabled fullWidth />
          </Stack>
          {methods.getValues("moreInfoURL") && methods.getValues("moreInfoURL").length > 0 && (
            <>
              <Typography variant="h6" sx={{ my: 1 }}>
                Additional Info
              </Typography>
              <Button
                name="moreInfoURL"
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mb: 0.5, width: 250 }}
                href={methods.getValues("moreInfoURL")}
                target={"_blank"}
              >
                Link to external website
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </FormProvider>
  );
};
