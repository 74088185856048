import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getFrameworkAgreementCompanyCount = (frameworkAgreemenId, companytype, companyID, userID) => {
  const queryString = qs.stringify({ companytype });
  return axios.get(
    `/api/framework_agreements/${frameworkAgreemenId}/companies/count?${queryString}`, {
      headers: {
        "Company_id": `${companyID}`,
        "userID": `${userID}`
      }
    }
  );
};

export const useFrameworkAgreementCompanyCount = (frameworkAgreementId, type, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `framework-agreement-${frameworkAgreementId}-${type}-count`,
    () => getFrameworkAgreementCompanyCount(frameworkAgreementId, type, user?.Company?.company_id, user?.user_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}