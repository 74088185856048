import React, {useRef, useState, useEffect } from 'react';
import { Stack, Box, Typography, Button } from '@mui/material';
import BackgroundImage from '../assets/background.png';
import { useLocation } from 'react-router-dom';
import { paths, ROOT_PATHS } from 'routes';

export const ContactUsThankYouScreen = () => {
  const location = useLocation();
  const websiteContext = location.state && location.state.websiteContext ? location.state.websiteContext : 'suppliers';
  const absoluteStackRef = useRef(null);
  const [yOffset, setYOffset] = useState(0);
  const [xOffset, setXOffset] = useState(0);

  useEffect(() => {
    function updateOffset() {
      if (absoluteStackRef.current) {
        setYOffset(absoluteStackRef.current.offsetHeight);
        setXOffset(absoluteStackRef.current.offsetWidth);
      }
    }

    updateOffset();
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, [absoluteStackRef]);
  return (
          <Box
            display={"flex"}
            sx={{
              position: "relative",
              width: "100vw",
              height: {xs: yOffset, md: 'calc(100vh - 385px)'},
              marginLeft: "-50vw",
              left: "50%",
              "& .MuiTypography-body2": {
                color: "#323232",
                fontSize: '1.125rem',
                fontWeight: 500,
                lineHeight: 1.55,
              },
            }}
          >
            <img
              className="small-logo"
              src={BackgroundImage}
              alt={'BackgroundImage'}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Stack
              width={1}
              maxWidth={664}
              direction="column"
              gap={3}
              ref={absoluteStackRef}
              sx={{
                position: "absolute",
                left: "50%",
                transform: {xs: "translateX(-50%)", md: "translate(-40%, 10vh)"},
                boxSizing: 'border-box',
                px: {xs: 4, md: 7},
                py: {xs:10, md: 0},
              }}
            >
                <Typography variant="h5" color={"primary.light"} fontWeight={600} lineHeight={"normal"}>
                  Contact us
                </Typography>
                <Typography variant="h1" fontSize={{xs:"2rem", md:"3.5rem"}}>
                  Thank you for contacting us!
                </Typography>
                <Typography variant='body2' fontSize={{xs:"1rem", md:"1.125rem"}}>
                  We have received your message.<br />
                  We will reach you out as soon as possible!
                </Typography>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  component="a"
                  href={websiteContext === 'buyers' ? ROOT_PATHS.WEBSITE_BUYERS : ROOT_PATHS.WEBSITE}
                  rel="noopener noreferrer"
                  sx={{
                    width: 240,
                    fontSize: "1rem",
                    backgroundColor: "#62A7B7",
                    '&:hover': {
                      backgroundColor: "#62A7B7",
                    },
                  }}
                >
                  Back to homepage
                </Button>
              </Stack>
          </Box>
  );
};
