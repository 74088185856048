import React from 'react';
import { Box, Typography, Stack } from "@mui/material";
import { ReactComponent as SimplicityIcon } from '../assets/Simplicity.svg';
import { ReactComponent as TransparencyIcon } from '../assets/Transparency.svg';
import { ReactComponent as EfficencyIcon } from '../assets/Efficency.svg';

export const CompanyValues = () => {
  return (
    <Box
      display={"flex"}
      sx={{
        position: "relative",
        width: "100vw",
        // minWidth: 392,
        backgroundColor: "#FAFAFA",
        justifyContent: "center",
        marginLeft: { md: "-50vw" },
        left: { md: "50%" },
      }}
    >
      <Stack
        sx={{
          display: "flex",
          gap: 8,
          py: 5,
          alignItems: "center",
          px: 4,
          maxWidth: {xs: 600, md: 1104},
        }}
      >
        <Stack gap={2}>      
          <Typography
            variant='h3'
            sx={{
              fontSize: "2.5rem",
              fontWeight: 600,
              lineHeight: 1.45,
              // alignSelf: "center",
              textAlign: "center",
            }}
          >
            Why we exist
          </Typography>
          <Typography
            variant='body2'
            sx={{
              color: "#333",
              fontSize: "1.125rem",
              fontWeight: 500,
              lineHeight: 1.55,
              width: 1,
              alignSelf: "center",
              textAlign: "center",
            }}
            
          >
            Delegon is all about streamlining the use of public funds by making it easier to sell staffing- and consulting services to the public sector.
          </Typography>
        </Stack>
        <Stack
          display={"flex"}
          width={1}
          flexDirection={{xs: "column", md: "row"}}
          justifyContent={"space-between"}
          sx={{
            '& h5': {
              color: "#333",
              fontWeight: 600,
              lineHeight: "normal",
            },
            '& p': {
              color: "#505050",
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: 1.5,
            },
            textAlign: "center",
            gap: {xs: 2, md: 0}
          }}
        >
          <Stack
            flexDirection={"column"}
            gap={2}
            flex={1}
          >
            <Box
              sx={{
                alignSelf: "center",
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SimplicityIcon />
            </Box>
            <Typography variant='h5'>
              Simplicity
            </Typography>
            <Typography variant='body2'>
              We believe that reduced entry barriers means more suppliers to choose from.
            </Typography>
          </Stack>
          <Stack
            flexDirection={"column"}
            gap={2}
            flex={1}
          >
            <Box
              sx={{
                alignSelf: "center",
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TransparencyIcon />
            </Box>
            <Typography variant='h5'>
              Transparency
            </Typography>
            <Typography variant='body2'>
              We believe that true transparency is a foundation for fair competition.
            </Typography>
          </Stack>
          <Stack
            flexDirection={"column"}
            gap={2}
            flex={1}
          >
            <Box
              sx={{
                alignSelf: "center",
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EfficencyIcon />
            </Box>
            <Typography variant='h5'>
              Efficiency
            </Typography>
            <Typography variant='body2'>
              We believe that reduced transactional costs benefit all actors in the market.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}