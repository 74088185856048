//@ts-nocheck
import { useQuery } from 'react-query';
import qs from 'qs';

import { axios } from 'lib/axios';
import { useAuth } from 'lib/auth';

const getCompetenceAreas = async (companyID, userID) => {
  const { data } = await axios.get(`/api/competence_areas`, {
    headers: {
      "Company_id": `${companyID}`,
      "userID": `${userID}`
    }
  });

  return data;
};

export const useCompetenceAreas = () => {
  const { user } = useAuth();

  return useQuery('competences',
    () => getCompetenceAreas(user.Company.company_id, user?.user_id), {
    select: ({ data: competences }) => ({ competences }),
    initialData: {
      competences: [],
    },
  });
};