import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, TextField } from 'components/Form';
import { Button, Stack, Typography } from '@mui/material';
import { PaperCard } from 'components/Card';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { forgotPasswordSchema } from '../validation';
import { useAuth } from 'lib/auth';
import { paths } from 'routes';

export const ForgotPasswordForm = () => {
  const { forgotpassword } = useAuth();
  const [companyEmail, setCompanyEmail] = useState('');
  const [ requested, setRequested ] = useState(false);

  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(forgotPasswordSchema),
    values: { companyEmail: companyEmail },
  });

  const onSubmit = async () => {
    try {
      methods.setError(null);
      await forgotpassword(companyEmail);
      setRequested(true);
    } catch (error) {
      methods.setError('companyEmail', { type: 'custom', message: error.message });
    }
  };

  const titleMargin = 3;
  return (
    <>
    {!requested && (
      <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }} sx={{my:5}}>
        <Stack>
          <Typography variant="h5" sx={{ mb: titleMargin, textAlign: "center" }}>
            Forgot Password
          </Typography>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack>
              <TextField
                name="companyEmail"
                label="Company email"
                placeholder="Please enter your email"
                required={true}
                type="email"
                value={companyEmail}
                onChange={(e) => {
                  setCompanyEmail(e.target.value);
                }}
                sx={{ mb: 2 }}
                fullWidth
              />

              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  mb: 0.5,
                  bgcolor: "primary.light",
                  '&:hover': {
                    backgroundColor: "primary.light",
                  },
                }}
              >
                Send password reset email
              </Button>
            </Stack>
          </FormProvider>
        </Stack>
      </PaperCard>
    )}
    {requested && (
      <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }} sx={{my:5}}>
        <Stack>
          <Typography variant="h5" sx={{ mb: titleMargin, textAlign: "center" }}>
            Forgot Password
          </Typography>

          <Stack>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>If an account with entered email address exists, a password reset link has been sent. Please check your inbox (and spam folder).</Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                mb: 0.5,
                bgcolor: "primary.light",
                '&:hover': {
                  backgroundColor: "primary.light",
                },
              }}
              href={paths.auth.login}
            >
              Return to login
            </Button>
          </Stack>
        </Stack>
      </PaperCard>
    )}
  </>
  );
};
