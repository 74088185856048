import React, { useState } from 'react';

import { Box, SpeedDial, SpeedDialAction, Link, Stack, Typography, Tooltip } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { IconWrapper } from 'components/Icon';
import { useAuth } from 'lib/auth';

import { isoToDateString } from 'utils/dateFormatter';
import { Status as StatusComponent } from 'components/Status';
import { Switch } from 'components/Switch';
import { useUpdateUserVisibility } from '../../users/api/updateUserVisibility';

export const UserListItem = ({
  user_id,
  first_name,
  last_name,
  phone_number,
  Company,
  status,
  last_active_on,
  title,
  email,
  created_on,
  markForDelete,
  markForAccept,
  markForReject,
  companyID,
  image_url,
  visible,
}) => {
  const { account, user } = useAuth();
  const [ userVisible, setUserVisible ] = useState(visible);
  const { mutateAsync } = useUpdateUserVisibility();

  const handleVisibleChange = async () => {
    if (!account.super_admin && user.role !== 'company_admin') {
      return;
    }

    const newValue = !userVisible;
    setUserVisible(newValue);
    await mutateAsync({
      userID: user_id,
      visible: newValue,
    })
  }
  return (
    <ListCard>
        <ImageAvatar img={image_url || ''} name={`${first_name} ${last_name}`} />
        
        <Stack flex={2} justifyContent="center">
          <Link
            component={RouterLink}
            to={`/app/contacts/${user_id.toString()}`}
            color="inherit"
          >
            <Typography variant="body1" fontWeight={700}>
            {first_name} {last_name}
            </Typography>
          </Link>
          <Stack direction="row" gap={1}>
            <Typography variant="caption" fontWeight={600} color={'grey.500'}>
              {title}
            </Typography>
          </Stack>
        </Stack>

      
        <Stack justifyContent="center" flex={1} minWidth={135} height={1}>
          <Typography variant="caption" fontWeight={700}>
            Phone
          </Typography>
          <Typography variant="body2" fontWeight={700} height={phone_number.length === 0 ? 0.5 : 'initial'}>
            <Link component={RouterLink} to={`tel:${phone_number}`} color="inherit">
              {`${phone_number}`}
            </Link>
          </Typography>
        </Stack>
        <Stack justifyContent="center" flex={(account.super_admin || (user.role === 'company_admin' && user.Company.company_id === companyID)) ? 2 : 1.5} minWidth={200}>
          <Typography variant="caption" fontWeight={700}>
            Email
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            <Link
              component={RouterLink}
              to={`mailto:${email}`}
              color="inherit"
            >
              {`${email}`}
            </Link>
          </Typography>
        </Stack>
        {(account.super_admin || (user.role === 'company_admin' && user.Company.company_id === companyID)) && (
        <>
          <Stack justifyContent="center" flex={1}>
            <Typography variant="caption" fontWeight={700} height={1}>
              Last Active
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(last_active_on, true)}
            </Typography>
          </Stack>
          {account.super_admin && (
          <Box flex={status === 'access_requested' ? 0.5 : 'none'} position="relative" alignContent={"center"} width={status !== 'access_requested' ? 10 : 'auto'}>
            <StatusComponent status={status} />
            {status === 'access_requested' && (
              <>
              <SpeedDial
                direction="left"
                ariaLabel="Contact actions"
                icon={
                  <IconWrapper
                    icon="elipsisVertical"
                    sx={({ palette }) => ({
                      svg: { stroke: palette.grey[500], color: palette.grey[500] },
                    })}
                  />
                }
                sx={({ palette }) => ({
                  '.MuiSpeedDial-actions': { bgcolor: 'white', borderRadius: 1 },
                  '.MuiSpeedDial-actionsClosed': { bgcolor: 'transparent' },
                  '& > button': {
                    boxShadow: 'none',
                    bgcolor: palette.background.paper,
                    width: 36,
                    height: 36,
                  },
                  '& > button:hover': { bgcolor: palette.background.paper },
                  position: 'absolute',
                  right: -14,
                  top: 'calc(50% - 28px)',
                  zIndex: 1,
                })}
              >
                <SpeedDialAction
                  tooltipTitle="Reject"
                  onClick={markForReject}
                  sx={({ palette }) => ({
                    bgcolor: palette.grey[50],
                    '&:hover': { bgcolor: palette.grey[200] },
                    '.MuiSpeedDial-actions': { bgcolor: 'white', borderRadius: 1 },
                    '.MuiSpeedDial-actionsClosed': { bgcolor: 'transparent' },
                  })}
                  icon={
                    <IconWrapper
                      icon="xMark"
                      sx={({ palette }) => ({
                        svg: {
                          width: 20,
                          height: 20,
                          stroke: palette.grey[500],
                          color: palette.grey[500],
                        },
                      })}
                    />
                  }
                />
                <SpeedDialAction
                  tooltipTitle="Accept"
                  onClick={markForAccept}
                  sx={({ palette }) => ({
                    bgcolor: palette.primary.main,
                    '&:hover': { bgcolor: palette.success.main },
                    '.MuiSpeedDial-actions': { bgcolor: 'white', borderRadius: 1 },
                    '.MuiSpeedDial-actionsClosed': { bgcolor: 'transparent' },
                  })}
                  icon={
                    <IconWrapper
                      icon="confirm"
                      sx={{
                        svg: {
                          width: 20,
                          height: 20,
                          stroke: 'white',
                          color: 'white',
                        },
                      }}
                    />
                  }
                />
              </SpeedDial>
              </>
            )}
          </Box>
          )}
        </>
      )}
      {!account.super_admin && (
        <>
          { user.role === 'company_admin' && user.Company.company_id === companyID && (
            <Box flex={0.5} position="relative">
              <Stack justifyContent="center" flex={1}>
                <Tooltip
                  arrow
                  title={"Visible on Company profile"}
                  placement="top"
                  slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
                >
                  <Typography variant="caption" fontWeight={700}>
                    Visible
                  </Typography>
                </Tooltip>

                <Typography variant="body2" fontWeight={700} minWidth={100}>
                  <Switch
                    checked={userVisible}
                    onChange={handleVisibleChange}
                    name="visible"
                    sx={{mt:0.5}}
                    disabled = {user.role !== 'company_admin' ? true : false}
                    subscriptionTypes={['silver', 'gold']}
                    tooltip="Available only for Premium members"
                  />
                </Typography>
              </Stack>
              {status === 'access_requested' && (
                <>
                <SpeedDial
                  direction="left"
                  ariaLabel="User actions"
                  icon={
                    <IconWrapper
                      icon="elipsisVertical"
                      sx={({ palette }) => ({
                        svg: { stroke: palette.grey[500], color: palette.grey[500] },
                      })}
                    />
                  }
                  sx={({ palette }) => ({
                    '& > button': {
                      boxShadow: 'none',
                      bgcolor: palette.background.paper,
                      width: 36,
                      height: 36,
                    },
                    '& > button:hover': { bgcolor: palette.background.paper },
                    position: 'absolute',
                    right: -14,
                    top: 'calc(50% - 28px)',
                    zIndex: 1,
                  })}
                >
                  <SpeedDialAction
                    tooltipTitle="Reject"
                    onClick={markForReject}
                    sx={({ palette }) => ({
                      bgcolor: palette.grey[50],
                      '&:hover': { bgcolor: palette.grey[200] },
                    })}
                    icon={
                      <IconWrapper
                        icon="xMark"
                        sx={({ palette }) => ({
                          svg: {
                            width: 20,
                            height: 20,
                            stroke: palette.grey[500],
                            color: palette.grey[500],
                          },
                        })}
                      />
                    }
                  />
                  <SpeedDialAction
                    tooltipTitle="Accept"
                    onClick={markForAccept}
                    sx={({ palette }) => ({
                      bgcolor: palette.primary.main,
                      '&:hover': { bgcolor: palette.success.main },
                    })}
                    icon={
                      <IconWrapper
                        icon="confirm"
                        sx={{
                          svg: {
                            width: 20,
                            height: 20,
                            stroke: 'white',
                            color: 'white',
                          },
                        }}
                      />
                    }
                  />
                </SpeedDial>
              </>
              )}
            </Box>
          )}
        </>
      )}
    </ListCard>
  );
};
