import React from 'react';
import { Stack, ThemeProvider, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { QueryClientProvider as TC, QueryClient } from '@tanstack/react-query';

import theme from 'theme';
import { queryClient } from 'lib';
import SnackbarProvider from 'components/Snackbar/SnackbarProvider';
import { AuthProvider } from 'lib/auth';

import localeSv from 'date-fns/locale/sv';
import { NavbarProvider } from 'lib/navbarContext';
import ScrollToTop from 'components/Layout/components/ScrollToTop';

const ErrorFallback = () => {
  <Stack>
    <Typography variant="h5">Ooops, something went wrong</Typography>
  </Stack>;
};

const tqc = new QueryClient();

export const AppProvider = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeSv}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary fallback={<ErrorFallback />}>
          <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
              <TC client={tqc}>
                <AuthProvider>
		              <NavbarProvider>
                    <Router>
                      <ScrollToTop />
                      {children}
                    </Router>
		              </NavbarProvider>
                </AuthProvider>
              </TC>
            </QueryClientProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
