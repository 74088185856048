import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {Select, Box, Chip, OutlinedInput, FormHelperText} from '@mui/material';
import { MenuItem } from '@mui/material';
import { InputWrapper } from './InputWrapper';
import { useState } from 'react';

export function MultiSelect({
  name,
  native = false,
  maxHeight = 220,
  helperText = '',
  // children,
  PaperPropsSx = {},
  disabled = false,
  fullWidth = false,
  required = false,
  labelSx = {},
  wrapperSx = {},
  label = '',
  defaultValue={},
  options = [],
  value= [],
  onChange,
  ...other
}) {
  const { control } = useFormContext();
  const [selectedValues, setSelectedValues] = useState([...value]);

  const handleChange = (event) => {
    const { value } = event.target;
    const selectedIds = typeof value === 'string' ? value.split(',') : value;
    const newSelectedValues = options.filter(option => selectedIds.includes(option.id));    
    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  const handleDelete = (deletedId) => (event) => {
    event.stopPropagation();    
    const updatedSelectedValues = selectedValues.filter((item) => item.id !== deletedId);
    setSelectedValues(updatedSelectedValues);
    onChange(updatedSelectedValues);
  };
  const getNameById = (id) => {
    const selectedOption = options.find((option) => option.id === id);    
    return selectedOption ? selectedOption.name : '';
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputWrapper
            disabled={disabled}
            error={!!error}
            label={label}
            fullWidth={fullWidth}
            required={required}
            labelSx={labelSx}
            wrapperSx={wrapperSx}
          >
            <Select
              {...field}
              multiple
              fullWidth
              disabled={disabled}
              value={selectedValues.map((item) => item.id)}
              // onChange={handleChange}
              onChange={(event) => {
                field.onChange(event);
                handleChange(event);
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selectedValues.map((item) => {
                    return (
                    <Chip key={item.id} label={item.name} onMouseDown={(event) => {event.stopPropagation();}} onDelete={handleDelete(item.id)} sx ={{fontSize: "0.875rem"}} />
                  )})}
                </Box>
              )}
              MenuProps= {{
                PaperProps: {
                  sx: {
                    ...(!native && {
                      maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                    }),
                    ...PaperPropsSx,
                  },
                },
              }}
              // error={!!error}
              {...other}
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText sx={{color: "error.main"}}>{error?.message}</FormHelperText>
            )}
          </InputWrapper>
        );
      }}
    />
  );
}
