import React from 'react';

import { Link, Stack, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';

import { useAuth } from 'lib/auth';
import { isoToDateString } from 'utils/dateFormatter';
import { useCompanyTcID } from '../api/getTcID';
import { paths } from 'routes';
import { useTendersSupplierFilesCount } from '../../tenders/api/getTendersSupplierFilesCount';
import { useTenderFilesCount } from '../../tenders/api/getTendersFilesCount';
import { useTenderCompanyCount } from '../../tenders/api/getTendersCompanyCount';
import dayjs from "dayjs";
import { IconWrapper } from 'components/Icon';

export const RelatedTendersListItem = ({ companyID, companyType, tender_name: name, Status: status, tender_id: id, ref_number, start_date, end_date, MainBuyer, value, last_offer_date, promoted }) => {  
    const { account } = useAuth();
    const {
      //@ts-ignore
      data: { tcID },
    } = useCompanyTcID(id, companyID, 
      {
        enabled: companyType === 'supplier',
      }
    );
    const {
      //@ts-ignore
      data: { count: filesCount },
    } = useTendersSupplierFilesCount(id, tcID, 
      {
        enabled: companyType === 'supplier',
      }
    );
    const {
      //@ts-ignore
      data: { count: generalFilesCount },
    } = useTenderFilesCount(id,
      {
        enabled: companyType === 'buyer',
      }
    );
    const {
      //@ts-ignore
      data: { count: suppliersCount },
    } = useTenderCompanyCount(id, 'supplier',
      {
        enabled: companyType === 'buyer',
      }
    );

    const today = dayjs();
    const lastOfferDate = dayjs(last_offer_date);
    const isWithin7Days = lastOfferDate.isAfter(today) && lastOfferDate.diff(today, 'day') < 7;

    return (
      <ListCard>
        <ImageAvatar img={MainBuyer?.square_logo_url || ''} name={name} />
        <Stack flex={7} justifyContent="center">
          <Link
            component={RouterLink}
            to={`/app/tenders/${id.toString()}`}
            color="inherit"
          >
            <Stack direction={'row'} gap={1}>
              <Typography variant="body1" fontWeight={700}>
                {name}
              </Typography>
              {promoted && (
                <IconWrapper
                  icon="rocket"
                  sx={({ palette }) => ({ svg: { width: 16, height: 16, stroke: palette.primary.main, color: palette.primary.main }, display: "flex" })}
                  height={16}
                  display={"flex"}
                  alignSelf={"center"}
                />
              )}
            </Stack>
          </Link>
          <Stack direction="row" gap={1}>
            <Typography variant="caption" fontWeight={600}>
              {ref_number}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{ borderColor: 'grey.500', height: 15, borderWidth: '1px' }}
            />
            <Typography variant="caption" color="text.primary" fontWeight={700}>
              <Link
                component={RouterLink}
                to={`/app/${MainBuyer?.Type}s/${MainBuyer?.company_id.toString()}`}
                color="inherit"
              >
                {MainBuyer?.Name}
              </Link> 
            </Typography>
          </Stack>
        </Stack>
        {companyType === 'buyer' && (
          <>
          <Stack justifyContent="center" sx={{pr:1, width: 100}}>
            <Typography variant="caption" fontWeight={700}>
              Value
            </Typography>
            <Typography variant="body2" fontWeight={700}>
              {`${value.length > 0 ? value : 0} MSEK`}
            </Typography>
          </Stack>
          <Stack justifyContent="center" sx={{pr:1, width: 60}}>
            <Typography variant="caption" fontWeight={700}>
              Bidders
            </Typography>
            <Link
              component={RouterLink}
              to={`${paths.tendersDetailsView(id)}?tab=bidders`}
              color="inherit"
            >
              <Typography variant="body2" fontWeight={700}>
                {suppliersCount}
              </Typography>
            </Link>
          </Stack>
          </>
        )}
        <Stack justifyContent="center" sx={{pr:1, width: 60}}>
          <Typography variant="caption" fontWeight={700}>
            Files
          </Typography>
          <Link
            component={RouterLink}
            to={companyType === 'buyer' ? `${paths.tendersDetailsView(id)}?tab=files` : `${paths.tendersDetailsView(id)}?tab=files&subtab=${tcID}`}
            color="inherit"
          >
            <Typography variant="body2" fontWeight={700}>
              {companyType === 'buyer' ? generalFilesCount : filesCount}
            </Typography>
          </Link>
        </Stack>
        {companyType === 'buyer' && (
          <Stack justifyContent="center" sx={{pr:1, width: 95}}>
            <Typography variant="caption" fontWeight={700}>
              Last Offer Date
            </Typography>
            <Typography variant="body2" fontWeight={700} sx={{color: isWithin7Days ? '#B82E46' : '' }}>
              {isoToDateString(last_offer_date, false)}
            </Typography>
          </Stack>
        )}
        <Stack justifyContent="center" sx={{pr:account.super_admin ? 1 : 0, width: 180}}>
          <Typography variant="caption" fontWeight={700}>
            Agreement Period
          </Typography>
        
          <Typography variant="body2" fontWeight={700}>
            {isoToDateString(start_date, false)} - {isoToDateString(end_date, false)}
          </Typography>
        </Stack>

        {account.super_admin && (
          <StatusComponent status={status} />
        )}
      </ListCard>
    );
  };
