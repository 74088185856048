import React, { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { paths } from 'routes';

import { Search } from 'components/Search';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound/NotFound';
import { Pagination } from 'components/Pagination';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';

import { useTenders } from '../api/getAllTenders';
import { useTendersCount } from '../api/getTendersCount';
import { TenderListItem } from '../components/TenderListItem';
import { Add } from '../components/Add';
import { useAuth } from 'lib/auth';
import { CheckboxFilter } from 'components/Filter/Inputs/CheckboxFilter';
import { OrderByDropdown } from 'components/Filter/Inputs/OrderByDropdown';
import { Loader } from 'components/Loader';
import { YellowMessageBar } from 'components/YellowMessageBar';

export const List = () => {
  const { account, user } = useAuth();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    includeInactive: false,
    acceptingOffers: false,
    sortBy: !account.super_admin && user.Company.subscription_level === 'free' ? 'dateCreated' : sessionStorage.getItem('tender_sortBy') || 'dateCreated',
  });
  const [loading, setLoading] = useState(false);

  const [additionalTender, setAdditionalTender] = useState(0);
  const [timer, setTimer] = useState(null);

  const {
    data: { tenders, total },
    refetch: refetchTenders,
    isFetching: isFetching1,
  } = useTenders({
    search,
    ...filters,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      }
    },
  });

  const {
    //@ts-ignore
    data: { count: tendersCount },
    isFetching: isFetching2,
  } = useTendersCount();

  const {
    //@ts-ignore
    data: { count: expiredTendersCount },
    isFetching: isFetching3,
  } = useTendersCount(true);

  const isFetching = isFetching1 || isFetching2 || isFetching3;

  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const handleFilterUpdate = ({ target: { name, value, checked } }) => {
    setFilters((filters) => {
      switch (name) {
        case 'sortBy':
          sessionStorage.setItem('tender_sortBy', value);
          return { ...filters, [name]: value };

        default:
          return { ...filters, [name]: checked };
      }
    });
  };

  const handlePageChange = (_, page) => setPage(page);

  const caption = account.super_admin ? 'Click the "Add Tender" button to begin' : '';

  useTendersRefetch();

  useEffect(() => {
    setAdditionalTender(tendersCount + expiredTendersCount - total);
  }, [total, tendersCount, expiredTendersCount])

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  return (
    <ContentLayout>
      <Stack height={1}>
        <Stack>
          <BreadCrumbs
            items={[
              { name: '', to: paths.tenders },
              { name: `Tenders${total > 0 ? ` (${total})` : ''}` },
            ]}
          />
        </Stack>
        <Stack>
          
        </Stack>
        {!account.super_admin && (
          <>
          {(!user.Company.is_premium || user.Company.subscription_level === 'free') && (
            <PremiumBanner sx={{my: 0}} message={`There are ${additionalTender} more tenders available for Premium members. Click here for more information`} handleSuccess={refetchTenders} currentLevel={user.Company.subscription_level} />
          )}

          {(user.Company.is_premium && user.Company.subscription_level !== 'gold') && (
            <PremiumBanner sx={{my: 0}} message={`There are ${additionalTender} more tenders available for Gold members. Click here for more information`} handleSuccess={refetchTenders} currentLevel={user.Company.subscription_level} />
          )}
              
          </>
        )}

        <Stack
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          height="100%"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 270 }} />
            <Stack direction="row" gap={2} sx={{ flex: '1' }}>
              <CheckboxFilter
                adminOnly
                name="includeInactive"
                onChange={handleFilterUpdate}
                checked={filters.includeInactive}
                label="Inactive"
              />
              <CheckboxFilter
                name="acceptingOffers"
                onChange={handleFilterUpdate}
                checked={filters.acceptingOffers}
                label="Open"
                subscriptionTypes={['silver', 'gold']}
                tooltip="Premium feature"
              />
            </Stack>
            <OrderByDropdown
              name="sortBy"
              onChange={handleFilterUpdate}
              selected={filters.sortBy}
              options={[
                { value: 'dateCreated', text: 'Date Created' },
                {
                  value: 'expiringDate',
                  text: 'Expiring Date',
                  subscriptionType: ['silver', 'gold'],
                  tooltip: 'premium feature',
                },
                {
                  value: 'postingDate',
                  text: 'Posting Date',
                  subscriptionType: ['silver', 'gold'],
                  tooltip: 'premium feature',
                },
              ]}
            />
            {account.super_admin && (
              <Add refetchTenders={refetchTenders} />
            )}
          </Stack>

          {isFetching && !loading ? 
          (
            <></>
          ) :
          (!isFetching || (isFetching && loading)) ?
          (
            <>
            {loading && (
              <Loader />
            )}

            {!loading && (
            <>
            {!tenders && (
              <Stack
                height="100%"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h5">No tenders Added</Typography>
                {account.super_admin && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 500,
                      fontSize: '0.875rem',
                      letterSpacing: '-0.14px',
                    }}
                  >
                    Click the "Add Tender" button to begin
                  </Typography>
                )}
              </Stack>
            )}

            {tenders && (
              <>
              <Stack
                flex={1}
                mt={2}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
              >
                {tenders.length === 0 && (
                  <NotFound
                    wrapperSx={{ mt: 19 }}
                    title="No tenders found"
                    caption={caption}
                  />
                )}
                {tenders.length !== 0 && (
                  <>
                  <YellowMessageBar message={"Delegon offers a tender archive, not a tender monitoring service."} sx={{height: 40, mb: 1}} />
                  {tenders.map((item) => (
                    <TenderListItem key={item.tender_id} {...item} />
                  ))}
                  </>
                )}
              </Stack>
              </>
            )}

            <Stack direction="row" justifyContent="center">
              <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
          </>
            )}
            </>
          ) :
          (
            <></>
          )}
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useTendersRefetch() {
    useEffect(() => {
      refetchTenders();
    }, [page, filters, search]);
  }
};
