import React, {useState, useEffect} from 'react';
import { Stack, Typography, Button, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DateField, FormProvider, TextField } from 'components/Form';
import { useAuth } from 'lib/auth';
import { paths } from 'routes';
import { AdditionalPeriods } from 'components/AdditionalPeriod/AdditionalPeriod';

const detailsTabName = 'details';

const getDefaultValues = () => ({
  mainBuyer: '',
  refNumber: '',
  description: '',
  moreInfoURL: '',
  postingDate: null,
  startDate: null,
  lastOfferDate: null,
  endDate: null,
  relatedFA: '',
  value: null,
  additionalPeriods: [],
  competenceAreas: [],
});

export const DetailsTab = ({ currentTab, tender }) => {
  const { account } = useAuth();
  const [ loading, setLoading ] = useState(true);
  const [ additionalPeriods, setAdditionalPeriods ] = useState(tender?.additional_periods);
  const [ initialPeriod, setInitialPeriod ] = useState([
      {
        label: "Initial",
        startDate: tender?.start_date,
        endDate: tender?.end_date,
        status: "used",
      }
    ]);

  const methods = useForm({
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    const {
      Description,
      more_info_url,
      end_date,
      MainBuyer: { Name },
      ref_number,
      start_date,
      posting_date,
      last_offer_date,
      FrameworkAgreement: {fa_name},
      value,
      additional_periods,
      competence_areas,
    } = tender;
  
    methods.reset({
      mainBuyer: Name,
      refNumber: ref_number,
      description: Description,
      moreInfoURL: more_info_url,
      postingDate: new Date(posting_date),
      lastOfferDate: new Date(last_offer_date),
      startDate: new Date(start_date),
      endDate: new Date(end_date),
      relatedFA: fa_name,
      value: value,
      additionalPeriods: additional_periods,
      competenceAreas: competence_areas,
    });
  
    setAdditionalPeriods(tender?.additional_periods);
    const tempPeriod = [...initialPeriod];
    tempPeriod[0].startDate = tender?.start_date;
    tempPeriod[0].endDate = tender?.end_date;
    setInitialPeriod(tempPeriod);
    setLoading(false);
  }, [tender])
  

  const onSubmit = () => {};

  if (currentTab !== detailsTabName) return null;  

  if (loading) {
    return null;
  }

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack direction="row" width="100%" height="100%" bgcolor={'background.paper'} gap={2}>
        <Stack flex={1} direction="column" gap={2}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Description
          </Typography>
          <TextField
            name="description"
            disabled
            type="text"
            fullWidth
            sx={{
              '& .MuiInputBase-root, .MuiInputBase-input': {
                p: 0,
              },
            }}
            multiline
            minRows={2}
          />
        </Stack>
        <Stack flex={1} direction="column" gap={2}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Details
          </Typography>
          <TextField
            name="value"
            label="Value"
            disabled
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  MSEK
                </InputAdornment>
              )
            }}
            wrapperSx={{width: "48.7%"}}
          />
          <TextField name="relatedFA" label="Related Agreement" disabled type="text" fullWidth url={tender?.FrameworkAgreement?.fa_id ? paths.frameworkAgreementDetailsView(tender?.FrameworkAgreement?.fa_id) : ''} />
          {methods.getValues("competenceAreas") && methods.getValues("competenceAreas").length > 0 && (
            <TextField name="competenceAreas" label="Competence Areas" disabled type="text" fullWidth isChip />
          )}
          <Stack direction="row" justifyContent="space-between" gap={2}>
            <DateField name="postingDate" label="Posting Date" disabled fullWidth />
            <DateField name="lastOfferDate" label="Last Offer Date" disabled fullWidth />
          </Stack>
          <AdditionalPeriods initialPeriod={initialPeriod} additionalPeriods={additionalPeriods} handleAdditionalPeriods={null} readOnly tender />
          {methods.getValues("moreInfoURL") && methods.getValues("moreInfoURL").length > 0 && (
            <>
              <Typography variant="h6" sx={{ my: 1 }}>
                Additional Info
              </Typography>
              <Button
                name="moreInfoURL"
                size="medium"
                variant="contained"
                color="primary"
                sx={{ mb: 0.5, width: 250 }}
                href={methods.getValues("moreInfoURL")}
                target={"_blank"}
              >
                Link to external website
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </FormProvider>
  );
};
