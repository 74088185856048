import { axios } from "lib/axios"

export const sendEmail = async (data) => {
  const jsonData = {
    "name": data.name,
    "email": data.email,
    "phone": data.phone,
    "message": data.message,
  }

  return await axios.post("/website/contact-us", JSON.stringify(jsonData));
}

