import { axiosAuth } from "lib/axios"

export const activateProfile = async (firebaseUID, accountID, userID, companyID, shadow) => {
    const jsonData = {
        "firebase_uid": firebaseUID,
        "account_id": accountID,
        "user_id": userID,
        "company_id": companyID,
        "shadow": shadow,
    }
    
    return axiosAuth.put(`/auth/activate`, JSON.stringify(jsonData))
}