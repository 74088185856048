import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, Divider, Checkbox, FormControlLabel, FormGroup, InputAdornment } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput, OnLoadSelect, NumericFormat, MultiSelect } from 'components/Form';
import { useForm } from 'react-hook-form';
import { AccordionListItem } from 'components/Button';
import { useParams } from 'react-router-dom';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { additionalDetailsSchema } from '../validation';
import { useUpdateFrameworkAgreement } from '../api/updateFrameworkAgreement';
import { useFrameworkAgreementTenders } from '../api/getFrameworkAgreementTenders';
import { AdditionalPeriods } from 'components/AdditionalPeriod/AdditionalPeriod';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCompetenceAreas } from 'api/getCompetenceAreas';

const getDefaultValues = () => ({
  faName: '',
  description: '',
  startDate: null,
  endDate: null,
  moreInfoURL: '',
  tenderID: null,
  value: null,
  additionalPeriods: [{}],
  competenceAreas: [],
});

const ItemCard = ({ tender_name, rest }) => {
  return (
    <Typography variant="body1" fontWeight="bold">
      {tender_name}
    </Typography>
)};

export const EditForm = ({frameworkAgreement, refetch}) => { 
  let { id } = useParams();

  const [open, setOpen] = useState(false);
  const [ tenderID, setTenderID] = useState(frameworkAgreement?.tender_id)
  const [ additionalPeriods, setAdditionalPeriods ] = useState(frameworkAgreement?.additional_periods);
  const [ initialPeriod, setInitialPeriod ] = useState([
    {
      label: "Initial",
      startDate: frameworkAgreement?.start_date,
      endDate: frameworkAgreement?.end_date,
      status: "used",
    }
  ]);
  const [ selectedCompetenceAreas, setSelectedCompetenceAreas ] = useState(frameworkAgreement?.competence_areas);
  const [ competencesArray, setCompetencesArray ] = useState([]);

  const { data: competences } = useCompetenceAreas();

  const handleTenderChange = (value) => {
    setTenderID(value?.tender_id);
    methods.setValue("tenderID", value?.tender_id);
  }

  const handleAdditionalPeriods = (value) => {
    setAdditionalPeriods(value);
    methods.setValue("additionalPeriods", value);
  }

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: useYupValidationResolver(additionalDetailsSchema),
  });

  const { mutateAsync } = useUpdateFrameworkAgreement(refetch);

  const handleClose = () => {
    setOpen(false);
    methods.reset({ ...getDefaultValues() });
  };

  const onSubmit = (values) => {
    mutateAsync({
      ...values,
      frameworkAgreementId: id,
    }).then(handleClose);
  };

  const handleCompetenceAreasChange = (value) => {
    setSelectedCompetenceAreas(value);
    methods.setValue("competenceAreas", value);
    if (value.length > 0) {
      methods.trigger("competenceAreas");
    }
  };

  useEffect(() => {
    if (!open) {
      methods.reset({ ...frameworkAgreement });
      document.body.style.overflow = '';
    } else if (open) {
      const {
        fa_name,
        Description,
        more_info_url,
        end_date,
        main_buyer_id,
        start_date,
        value,
        additional_periods,
        competence_areas,
      } = frameworkAgreement;

      methods.reset({
        faName: fa_name,
        mainBuyerId: main_buyer_id,
        description: Description,
        moreInfoURL: more_info_url,
        startDate: dayjs(new Date(start_date)),
        endDate: dayjs(new Date(end_date)),
        tenderID: tenderID,
        value: value,
        additionalPeriods: additional_periods,
        competenceAreas: competence_areas,
      });
    
      setTenderID(frameworkAgreement?.tender_id);
      setAdditionalPeriods(frameworkAgreement?.additional_periods);
      const tempPeriod = [...initialPeriod];
      tempPeriod[0].startDate = frameworkAgreement?.start_date;
      tempPeriod[0].endDate = frameworkAgreement?.end_date;
      setInitialPeriod(tempPeriod);
      setSelectedCompetenceAreas(frameworkAgreement?.competence_areas);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  const {
    data: { tenders },
  } = useFrameworkAgreementTenders(id, {
    mainBuyerID: frameworkAgreement?.main_buyer_id,
    queryOptions: { 
      enabled: open },
  });

  useEffect(() => {
    if (competences && Array.isArray(competences.competences)) {
      const allCompetences = competences.competences.map(item => ({
        value: item.id,
        label: item.name,
        ...item,
      }));

      setCompetencesArray(allCompetences);
    }
  }, [competences]);

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Edit</AccordionListItem>
      {open && (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#F3F4F6',
          justifyContent: 'center',
          display: open ? 'flex' : 'none',
          zIndex: 40,
          alignItems: 'flex-start',
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: '100%',
            height: `calc(100% - 8 * 26.25px)`,
            marginTop: 26.25,
            overflowY: 'auto',
            backgroundColor: '#fff',
          }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <PaperCard
              height={69}
              sx={{
                background: '#fff',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                px: 4,
                py: 2,
                border: 0,
                borderRadius: 0,
                boxShadow: 26,
                display: 'flex',
                boxSizing: 'border-box',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography>Editing ‘{frameworkAgreement?.fa_name}’</Typography>
                <Box
                  sx={{
                    width: 165,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="medium"
                    onClick={() => setOpen(false)}
                    variant="contained"
                    sx={({ palette }) => ({
                      width: 82,
                      boxShadow: 25,
                      color: palette.primary.main,
                      backgroundColor: '#fff',
                    })}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ width: 67 }}
                  >
                    Save
                  </Button>
                </Box>
              </Stack>
            </PaperCard>

            <Stack
              direction="row"
              width="100%"
              minHeight={`calc(100vh - 8 * 26.25px)`}
              height="100%"
              bgcolor={'#fff'}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width={'50%'} direction="column" px={8} py={4}>
                <TextField
                  name="description"
                  label="Description"
                  placeholder="Please enter description"
                  type="text"
                  fullWidth
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root, .MuiInputBase-input': {
                      p: 0,
                    },
                  }}
                  multiline
                  minRows={2}
                />
              </Stack>

              <Stack
                width={'50%'}
                direction="column"
                px={8}
                py={4}
                gap={2}
              >
                <TextField
                  name="faName"
                  label="Framework Agreement Name"
                  placeholder="Please enter Framework agreement name"
                  required={true}
                  type="text"
                  fullWidth
                />
                <NumericFormat
                  name="value"
                  label="Value"
                  placeholder="Please enter value"
                  type="input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        MSEK
                      </InputAdornment>
                    )
                  }}
                />
                <OnLoadSelect
                  label={"Related Tender"}
                  fullWidth
                  onChange={handleTenderChange}
                  ItemCard={(props) => <ItemCard {...props} />}
                  options={tenders}
                  value={frameworkAgreement?.Tender}
                  search_key='tender_name'
                  unique_key='tender_id'
                />
                <MultiSelect multiple value={selectedCompetenceAreas} onChange={handleCompetenceAreasChange} placeholder="Please select" required fullWidth name="competenceAreas" label="Competence Areas" options={competencesArray} /* sx={{ mb: 2 }} */ />
                <TextField
                  name="moreInfoURL"
                  label="More Information URL"
                  placeholder="Please enter URL with more information"
                  required={false}
                  type="text"
                  // sx={{ mb: 2 }}
                  fullWidth
                />
                <AdditionalPeriods initialPeriod={initialPeriod} setInitialPeriod={setInitialPeriod} additionalPeriods={additionalPeriods} handleAdditionalPeriods={handleAdditionalPeriods} methods={methods}/>
              </Stack>
            </Stack>
            </LocalizationProvider>
          </FormProvider>
        </Stack>
      </Box>
      )}
    </>
  );
};
