import { palette } from './palette';

export const typography = {
  htmlFontSize: 16,
  //fontFamily: ['"Montserrat"', '"Helvetica Neue"'].join(','),
  fontFamily: 'Montserrat',
  fontSize: 16,
  allVariants: {
    fontWeight: 700,
    lineHeight: '150%',
  },
  caption: {
    fontSize: '0.75rem',
    letterSpacing: '-0.12px',
    color: palette.text.secondary,
  },
  button: {
    textTransform: 'none !important',
    fontSize: '0.875rem',
    letterSpacing: '-0.14px',
  },
  body2: {
    fontSize: '0.875rem',
  },
  h1: {
    fontSize: '3.5rem',
    lineHeight: 1.25,
  },
  h2: {
    fontSize: '1.875rem',
    lineHeight: 1.2,
    fontWeight: 700,
  },
  h3: {
    fontSize: '1.25rem',
    letterSpacing: '-0.2px',
    lineHeight: 1.5,
  },
  h4: {
    fontSize: '1.875rem',
    lineHeight: 1.2,
  },
  h5: {
    color: palette.grey[700],
    fontSize: '1.5rem',
    lineHeight: 1.5,
    letterSpacing: '-0.24px',
  },
  h6: {
    color: palette.grey[900],
    fontSize: '1.125rem',
    lineHeight: 1.5,
    letterSpacing: '-1%',
  },
  body1: {
    fontSize: '0.875rem',
    letterSpacing: '-0.14px',
  },
};
