import React from 'react';
import { Typography, AppBar } from '@mui/material';
import { paths, ROOT_PATHS } from 'routes';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

export const ContextBanner = ({ websiteContext = 'suppliers', isPricing = false, sx = {}}) => {  
  let message = "You are viewing as a <strong>Supplier</strong>. Please click here if your want to view as a <strong>Buyer</strong>.";
  if (websiteContext === 'buyers') {
    message = "You are viewing as a <strong>Buyer</strong>. Please click here if your want to view as a <strong>Supplier</strong>.";
  }

  let url = '';
  if (websiteContext === 'buyers') {
    if (isPricing) {
      url = paths.website.pricing;
    } else {
      url = ROOT_PATHS.WEBSITE;
    }
  } else {
    if (isPricing) {
      url = paths.website.buyersPricing;
    } else {
      url = ROOT_PATHS.WEBSITE_BUYERS;
    }
  }

  return (
    <Link
      to={url}
      state={{websiteContext: websiteContext === 'suppliers' ? 'buyers' : 'suppliers'}}
      style={{ textDecoration: 'none' }}
      color="inherit"
    >
      <AppBar
        sx={{
          height:44,
          wifth: 1,
          justifyContent: 'center',
          bgcolor: "#EDF3FD",
          cursor: "pointer",
          alignItems: "center",
          textAlign: "center",
          position: "fixed",
          top: 0,
          ...sx,
        }}
      >
        <Typography variant="body1" color="text.primary"
          component={"span"}
          sx={{
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          {ReactHtmlParser(message)}
        </Typography>
      </AppBar>
    </Link>
  );
};
