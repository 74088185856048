import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Stack, useMediaQuery } from "@mui/material";
import BackgroundImage from '../assets/homepage_top_picture.png';
import { ReactComponent as VectorIcon } from '../assets/Vector.svg';

import { paths } from 'routes';

export const TopSection = () => {
  const boxRef = useRef(null);
  const [boxInnerHeight, setBoxInnerHeight] = useState(0);

  useEffect(() => {
    function updateHeight() {
      if (boxRef.current) {
        setBoxInnerHeight(boxRef.current.offsetHeight);
      }
    }

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const isXs = useMediaQuery('(max-width: 899px)');
  const isSmallMd = useMediaQuery('(min-width: 900px) and (max-height: 700px)');

  const xsStylesBody = {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
  };

  const mdStylesBody = {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.55,
  };

  const xsStylesH = {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1.15,
  };

  const mdStylesH = {
    fontSize: '3.5rem',
    fontWeight: 700,
    lineHeight: 1.25,
  };

  const bodyStyles = isXs || isSmallMd ? xsStylesBody : mdStylesBody;
  const hStyles = isXs || isSmallMd ? xsStylesH : mdStylesH;
  const boxHeight = isXs || isSmallMd ? "auto" : 486;

  return (
    <Box
      display={"flex"}
      sx={{
        position: "relative",
        width: "100vw",
        height: "calc(100vh - 70px - 44px - env(safe-area-inset-bottom))",
        // minWidth: "440px",
        marginLeft: { md: "-50vw" },
        left: { md: "50%" },
      }}
    >
        <img
          className="small-logo"
          src={BackgroundImage}
          alt={'BackgroundImage'}
          // loading="lazy"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          display={"flex"}
          ref={boxRef}
          sx={{
            maxWidth: {xs: "392px", md: "650px"},
            height: {xs: "auto", md: boxHeight},
            p: {xs: 3, md:"50px"},
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 3.25,
            borderRadius: "0px 30px 30px 0",
            background: "rgba(237, 243, 253, 0.8)",
            position: "absolute",
            top: `calc((100vh - 70px - env(safe-area-inset-bottom) - ${boxInnerHeight}px) / 2)`,
            left: 0,
          }}
        >
          <Typography variant="h2" sx={hStyles} color="#000B0D">
            A staffing providers guide to the Swedish public sector
          </Typography>
          <Box>
            <Typography variant="body2" sx={bodyStyles} color="#000B0D">
              Delegon gathers public information about tenders, bids, agreements and dynamic purchasing systems for consulting- and staffing services.
            </Typography>
            <Typography variant="body2" sx={bodyStyles} color="#000B0D">
              Increase your profit with better market insights.
            </Typography>
          </Box>
          <Stack width={1}>
            <Typography
              sx={{
                color: "text.black",
                textAlign: "center",
                fontFamily: "Dancing Script",
                fontSize: "2.5rem",
                // fontStyle: "normal",
                fontWeight: 700,
                // lineHeight: "normal",
                transform: "rotate(-10.057deg)",
                alignSelf: "flex-start",
              }}
            >
              Sign up for free here
            </Typography>
            <VectorIcon
            style={{
              left: "27.5%",
              top: "5%",
              position: "relative"
            }}
            />
            <Button
              size="medium"
              variant="contained"
              component="a"
              href={paths.auth.register}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: "#62A7B7",
                '&:hover': {
                  backgroundColor: "#62A7B7",
                },
                width: 200,
                height: 48,
                fontSize: "1rem",
                fontWeight: "700",
                color: "#fff",
                lineHeight: 1.5,
                alignSelf: "center",
              }}
            >
              Sign up for free
            </Button>
          </Stack>
        </Box>
    </Box>
  )
}