import React from 'react';
import { Box, Stack, Divider, Typography, Link, Container } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import DelegonMainLogoWhite from '../../../assets/Delegon_Main_Logo_WHITE.png';
import { ReactComponent as FacebookLogo } from '../../../assets/facebook logo.svg';
import { ReactComponent as TwiterLogo } from '../../../assets/Twitter logo.svg';
import { ReactComponent as LinkedinLogo } from '../../../assets/linkedin logo.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/instagram logo.svg';
import { paths, ROOT_PATHS } from 'routes';

export const Footer = ({websiteContext = 'suppliers'}) => {
  const homeURL = websiteContext === 'buyers' ? `${ROOT_PATHS.WEBSITE_BUYERS}` : `${ROOT_PATHS.WEBSITE}`;
  const pricingURL = websiteContext === 'buyers' ? `${paths.website.buyersPricing}` : `${paths.website.pricing}`;
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Box
        component="footer"
        display={"flex"}
        flexDirection={"column"}
        gap={3}
        sx={{
          height: {xs: 1, md: 309},
          width: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "background.primary",
          // minWidth: "440px",
          py: {xs: 6, md: 0},
          
        }}
      >
        <Stack
          direction={"column"}
          sx={{
            verticalAlign: "middle",
            width: {xs: 1, md: 787},
            height: {md: 187},
            alignItems: "center",
            justifyContent: "center",
            gap: {xs:1, md:3},
            mx: {md:0},
            maxWidth:{ md: 1},
            px: 4,
            boxSizing: 'border-box'
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              width: 1,
              height: 80,
              alignItems: "center",
              justifyContent: {xs: "space-between", sm: "flex-start", md: "center"},
            }}
          >
            <Box
              sx={{
                '.small-logo': { marginLeft: "-19px" },
              }}
            >
              <img
                className="small-logo"
                src={DelegonMainLogoWhite}
                alt={'Delegon'}
                loading="lazy"
                style={{
                  width: 185,
                  flexShrink: 0,
                }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ borderColor: '#fff', height: 26}} />
            <Stack
              direction={"row"}
              gap={3}
              sx={{
                ml:2,
                height: 18
              }}
            >
              <Link component={RouterLink} to={"https://www.facebook.com/people/Delegon/61552848309424"} target='_blank'>
                <FacebookLogo style={{verticalAlign: "middle"}} />
              </Link>
              {/* <Link component={RouterLink} to={"#"} target='_blank'>
                <TwiterLogo />
              </Link> */}
              <Link component={RouterLink} to={"https://www.linkedin.com/company/delegon"} target='_blank'>
                <LinkedinLogo style={{verticalAlign: "middle"}} />
              </Link>
              {/* <Link component={RouterLink} to={"#"} target='_blank'>
                <InstagramLogo />
              </Link> */}
            </Stack> 
          </Stack>
          <Box
            gap={{xs: 3, md: 4}}
            display={"flex"}
            flexDirection={{xs:"column-reverse", md: "column"}}
            width={1}
          >
            <Stack
              flex={1}
              direction={{xs:"column", md:"row"}}
              gap={2}
              sx={{
                "& > a": {
                  textDecoration: "none",
                  color: "#fff",
                  height: {xs: 52, md: 1},
                  display: "flex",
                  alignItems: "center",
                },
                "& > a > .MuiTypography-root": {
                  fontWeight: 400,
                  fontSize: "1.125rem",
                  lineHeight: "normal",
                },
                height: "42px",
                alignItems: {xs: "flex-start", md:"center"},
                alignContent: "center",
                justifyContent: "center"
              }}
            >
              <Link component={RouterLink} to={homeURL} state={{websiteContext: websiteContext}}>
                <Typography>Homepage</Typography>
              </Link>
              {/* <Divider orientation="vertical" sx={{ borderColor: '#fff', height: 12, display:{xs:"none", md:"flex"}}} />
              <Link component={RouterLink} to={"#"}>
                <Typography>Blog</Typography>
              </Link>*/}
              <Divider orientation="vertical" sx={{ borderColor: '#fff', height: 12, display:{xs:"none", md:"flex"}}} />
              <Link component={RouterLink} to={pricingURL} state={{websiteContext: websiteContext}}>
                <Typography>Pricing</Typography>
              </Link> 
              <Divider orientation="vertical" sx={{ borderColor: '#fff', height: 12, display:{xs:"none", md:"flex"}}} />
              <Link component={RouterLink} to={paths.website.contactUs} state={{websiteContext: websiteContext}}>
                <Typography>Contact us</Typography>
              </Link>
            </Stack>
            <Typography
              variant='body2'
              fontWeight={400}
              lineHeight={1.75}
              sx={{
                color:"#fff",
                textAlign: {xs: "flex-start" ,md: "center"},
              }}
            >
              Delegon is a registered trademark within the European Union.
            </Typography>
          </Box>
        <Stack
          direction={{xs:"column", md: "row"}}
          gap={2}
          sx={{
            "& > a, & > div > a": {
              textDecoration: "none",
              color: "#fff",
            },
            "& > a > .MuiTypography-root, & > div > a > .MuiTypography-root": {
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: "normal",
            },
            alignSelf: {xs: "flex-start", md:"center"},
            justifyContent: "center",
            pt: {xs:3 , md:4},
          }}
        >
          <Typography fontSize={"1rem"} fontWeight={400} lineHeight={"normal"} color="#fff">
            &copy; {currentYear} Delegon
          </Typography>
          <Divider orientation="vertical" sx={{ borderColor: '#fff', height: 12, display: {xs: "none", md: "flex"}, alignSelf: "center"}} />
          <Stack
            direction={"row"}
            gap={2}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Link component={RouterLink} to={"#"} target='_blank'>
              <Typography>Privacy Policy</Typography>
            </Link>
            <Divider orientation="vertical" sx={{ borderColor: '#fff', height: 12}} /> */}
            <Link component={RouterLink} to={paths.website.termsAndConditions} state={{websiteContext: websiteContext}}>
              <Typography>Terms and Conditions</Typography>
            </Link>
          </Stack>
        </Stack>
        </Stack>
      </Box>
    </>
  );
};
