import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, Divider, InputAdornment } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput, NumericFormat, MultiSelect } from 'components/Form';
import { useForm } from 'react-hook-form';
import { AccordionListItem } from 'components/Button';
import { useGetByID } from '../api/getById';
import { useParams } from 'react-router-dom';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { additionalDetailsSchema } from '../validation';
import { useUpdate } from '../api/update';
import { logDOM } from '@testing-library/react';
import { useCompetenceAreas } from 'api/getCompetenceAreas';

const getDefaultValues = () => ({
  dpsName: '',
  // refNumber: '',
  description: '',
  moreInfoURL: '',
  postingDate: null,
  startDate: null,
  endDate: null,
  value: null,
  competenceAreas: [],
});

export const EditForm = ({dps, refetch}) => { 
  let { id } = useParams();

  const [open, setOpen] = useState(false);
  const [ selectedCompetenceAreas, setSelectedCompetenceAreas ] = useState(dps?.competence_areas);
  const [ competencesArray, setCompetencesArray ] = useState([]);
  
  const { data: competences } = useCompetenceAreas();

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: useYupValidationResolver(additionalDetailsSchema),
  });

  const { mutateAsync } = useUpdate(refetch);

  const handleClose = () => {
    setOpen(false);
    methods.reset({ ...getDefaultValues() });
  };

  const onSubmit = (values) => {
    mutateAsync({
      ...values,
      mainBuyerId: dps?.mainBuyerId,
      dpsID: id,
    }).then(handleClose);
  };

  const handleCompetenceAreasChange = (value) => {
    setSelectedCompetenceAreas(value);
    methods.setValue("competenceAreas", value);
    if (value.length > 0) {
      methods.trigger("competenceAreas");
    }
  };

  useEffect(() => {
    if (!open) {
      methods.reset({ ...getDefaultValues() });
      document.body.style.overflow = '';
    } else if (open) {
      const {
        dps_name,
        Description,
        more_info_url,
        end_date,
        ref_number,
        start_date,
        posting_date,
        value,
        competence_areas,
      } = dps;   

      methods.reset({
        dpsName: dps_name,
        refNumber: ref_number,
        description: Description,
        moreInfoURL: more_info_url,
        postingDate: new Date(posting_date),
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        value: value,
        competenceAreas: competence_areas,
      });
    
      setSelectedCompetenceAreas(dps?.competence_areas);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);  

  useEffect(() => {
    if (competences && Array.isArray(competences.competences)) {
      const allCompetences = competences.competences.map(item => ({
        value: item.id,
        label: item.name,
        ...item,
      }));

      setCompetencesArray(allCompetences);
    }
  }, [competences]);

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Edit</AccordionListItem>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#F3F4F6',
          justifyContent: 'center',
          display: open ? 'flex' : 'none',
          zIndex: 40,
          alignItems: 'flex-start',
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: '100%',
            height: `calc(100% - 8 * 26.25px)`,
            marginTop: 26.25,
            overflowY: 'auto',
            backgroundColor: '#fff',
          }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <PaperCard
              height={69}
              sx={{
                background: '#fff',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                px: 4,
                py: 2,
                border: 0,
                borderRadius: 0,
                boxShadow: 26,
                display: 'flex',
                boxSizing: 'border-box',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography>Editing ‘{dps?.dps_name}’</Typography>
                <Box
                  sx={{
                    width: 165,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="medium"
                    onClick={() => setOpen(false)}
                    variant="contained"
                    sx={({ palette }) => ({
                      width: 82,
                      boxShadow: 25,
                      color: palette.primary.main,
                      backgroundColor: '#fff',
                    })}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ width: 67 }}
                  >
                    Save
                  </Button>
                </Box>
              </Stack>
            </PaperCard>

            <Stack
              direction="row"
              width="100%"
              minHeight={`calc(100vh - 8 * 26.25px)`}
              height="100%"
              bgcolor={'#fff'}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width={'50%'} direction="column" px={8} py={4}>
                <TextField
                  name="description"
                  label="Description"
                  placeholder="Please enter description"
                  type="text"
                  fullWidth
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root, .MuiInputBase-input': {
                      p: 0,
                    },
                  }}
                  multiline
                  minRows={2}
                />
              </Stack>
              <Stack width={'50%'} direction="column" px={8} py={4}>
                <TextField
                  name="dpsName"
                  label="DPS Name"
                  placeholder="Please enter DPS name"
                  required
                  type="text"
                  sx={{ mb: 2 }}
                  fullWidth
                />
                <NumericFormat
                  name="value"
                  label="Value"
                  placeholder="Please enter value"
                  type="input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        MSEK
                      </InputAdornment>
                    )
                  }}
                  sx={{ mb: 2 }}
                />
                <MultiSelect multiple value={selectedCompetenceAreas} onChange={handleCompetenceAreasChange} placeholder="Please select" required fullWidth name="competenceAreas" label="Competence Areas" options={competencesArray} /* sx={{ mb: 2 }} */ />
                <TextField
                  name="moreInfoURL"
                  label="More Information URL"
                  placeholder="Please enter Link to external website"
                  required
                  type="text"
                  sx={{ mb: 2 }}
                  fullWidth
                />
                <Stack
                  width={'100%'}
                  direction="column"
                  gap={2}
                >
                  <Stack
                    width={'49%'}
                    direction="row"
                  >
                    <DateInput
                      name="postingDate"
                      label="Posting Date"
                      required
                      fullWidth
                    />
                  </Stack>
                  <Stack
                    width={'100%'}
                    direction="row"
                    gap={2}
                  >
                    <DateInput
                      name="startDate"
                      label="Start Date"
                      required
                      fullWidth
                    />
                    <DateInput
                      name="endDate"
                      label="End Date"
                      required
                      fullWidth
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Box>
    </>
  );
};
