import React from 'react';
import { Box, Typography, Stack } from "@mui/material";
import { ReactComponent as ProactiveIcon } from '../assets/Proactive.svg';
import { ReactComponent as OfferingIcon } from '../assets/Offering.svg';
import { ReactComponent as PartnersIcon } from '../assets/Partners.svg';
import { ReactComponent as LearnIcon } from '../assets/Learn.svg';
import { ReactComponent as TimeAndMoneyIcon } from '../assets/TimeAndMoney.svg';
import { ReactComponent as IntermediariesIcon } from '../assets/Intermediaries.svg';


export const Advantages = () => {
  const firstRow = [
    {
      title: "Become proactive",
      text: "With complete insight into current framework agreements, you can reach out to the customers at the right time.",
      icon: <ProactiveIcon />
    },
    {
      title: "Develop your offering",
      text: "With better market insights you can adjust your offerings to better meet current market expectations. ",
      icon: <OfferingIcon />
    },
    {
      title: "Team up with partners",
      text: "With insights into current suppliers, and local market conditions, you can team up with the winners for your next bid.",
      icon: <PartnersIcon />
    },
  ];

  const secondRow = [
    {
      title: "Learn from the best",
      text: "With access to bids from the market leaders you can learn how to improve your own bids.",
      icon: <LearnIcon />
    },
    {
      title: "Save time and money",
      text: "With complete insight into agreements, relations and bids you can reduce your costs for market research.",
      icon: <TimeAndMoneyIcon />
    },
    {
      title: "Find intermediaries ",
      text: "With full insight into current suppliers, and their terms, you can choose an intermediary that can meet your commercial expectations.",
      icon: <IntermediariesIcon />
    },
  ];

  return (
    <Stack
     sx={{
      display: "flex",
      gap: 8,
      pb: 8,
      maxWidth: "100vw",
     }}
    >
      <Stack gap={2} flexDirection={{xs: "column", md: "row"}} sx={{mx: {xs:4, md: 0}}}>      
        <Typography
          flex={1}
          variant='h2'
          sx={{
            fontSize: "2.5rem",
            fontWeight: 600,
            lineHeight: 1.25,
            alignSelf: "center",
            textAlign: {xs: "center", md: "start"},
          }}
        >
          Whats in it for you?
        </Typography>
        <Stack
          flex={1}
          gap={1}
          sx={{
            '& p': {
              color: "rgba(0, 0, 0, 0.70)",
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: 1.5,
            }
          }}
        >
          <Typography variant='body2'>
            In contrast to tender monitoring services, Delegon is focused on the period before a tender is posted and after the award has been given.
          </Typography>
          <Typography variant='body2'>
            Delegon offers its users a competitive advantage compared to organizations not using Delegon.
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{mx: {xs:4, md: 0}}}>
        <Stack flexDirection={{xs: "column", md: "row"}}>
          {firstRow.map((item, index) => (
            <Stack
              flex={1}
              sx={{
                py: 3,
                px: {xs: 0, md: 3},
                gap: 1,
                border: '1px solid #F1F1F1',
                borderLeft: {xs: 'none', md: index === 0 ? 'none' : '1px solid #F1F1F1'},
                borderRight: {xs: 'none', md: index === secondRow.length - 1 ? 'none' : '1px solid #F1F1F1'},
              }}
            >
              <Box
                width={58}
                height={58}
              >
                {item.icon}
              </Box>
              <Typography
                variant='body1'
                sx={{
                  textTransform: "uppercase",
                  color: "text.custom",
                  lineHeight: 1.6,
                  fontSize: "1.25rem",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  color: "#4D4D4D",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  fontWeight: 400
                }}
              >
                {item.text}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack flexDirection={{xs: "column", md: "row"}}>
          {secondRow.map((item, index) => (
            <Stack
              flex={1}
              sx={{
                py: 3,
                px: {xs: 0, md: 3},
                gap: 1,
                border: '1px solid #F1F1F1',
                borderLeft: {xs: 'none', md: index === 0 ? 'none' : '1px solid #F1F1F1'},
                borderRight: {xs: 'none', md: index === secondRow.length - 1 ? 'none' : '1px solid #F1F1F1'},
              }}
            >
              <Box
                width={58}
                height={58}
              >
                {item.icon}
              </Box>
              <Typography
                variant='body1'
                sx={{
                  textTransform: "uppercase",
                  color: "text.custom",
                  lineHeight: 1.6,
                  fontSize: "1.25rem",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  color: "#4D4D4D",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  fontWeight: 400
                }}
              >
                {item.text}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}