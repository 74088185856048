import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, Divider, InputAdornment } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput, OnLoadSelect, NumericFormat, MultiSelect } from 'components/Form';
import { useForm } from 'react-hook-form';
import { AccordionListItem } from 'components/Button';
import { useTender } from '../api/getTenderById';
import { useParams } from 'react-router-dom';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { additionalDetailsSchema } from '../validation';
import { useUpdateTender } from '../api/updateTender';
import { useTenderFrameworkAgreements } from '../api/getTenderFrameworkAgreements';
import { AdditionalPeriods } from 'components/AdditionalPeriod/AdditionalPeriod';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCompetenceAreas } from 'api/getCompetenceAreas';

const getDefaultValues = () => ({
  tenderName: '',
  // refNumber: '',
  description: '',
  moreInfoURL: '',
  postingDate: null,
  lastOfferDate: null,
  startDate: null,
  endDate: null,
  faID: null,
  value: null,
  additionalPeriods: [{}],
  competenceAreas: [],
});

const ItemCard = ({ fa_name, rest }) => {
  return (
    <Typography variant="body1" fontWeight="bold">
      {fa_name}
    </Typography>
)};

export const EditForm = ({tender, refetch}) => { 
  let { id } = useParams();

  const [open, setOpen] = useState(false);
  const [ faID, setFaID] = useState(tender?.FrameworkAgreement?.fa_id);
  const [ additionalPeriods, setAdditionalPeriods ] = useState(tender?.additional_periods);
  const [ initialPeriod, setInitialPeriod ] = useState([
    {
      label: "Initial",
      startDate: tender?.start_date,
      endDate: tender?.end_date,
      status: "used",
    }
  ]);

  const [ selectedCompetenceAreas, setSelectedCompetenceAreas ] = useState(tender?.competence_areas);
  const [ competencesArray, setCompetencesArray ] = useState([]);
  
  const { data: competences } = useCompetenceAreas();

  const handleAgreementChange = (value) => {
    setFaID(value?.fa_id);
    methods.setValue("faID", value?.fa_id);
  }

  const handleAdditionalPeriods = (value) => {
    setAdditionalPeriods(value);
    methods.setValue("additionalPeriods", value);
  }

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: useYupValidationResolver(additionalDetailsSchema),
  });

  const { mutateAsync } = useUpdateTender(refetch);

  const handleClose = () => {
    setOpen(false);
    methods.reset({ ...getDefaultValues() });
  };

  const onSubmit = (values) => {
    mutateAsync({
      ...values,
      tenderID: id,
    }).then(handleClose);
  };

  const {
    data: { agreements },
  } = useTenderFrameworkAgreements(id, {
    mainBuyerID: tender?.main_buyer_id,
    queryOptions: { 
      enabled: open },
  });

  const handleCompetenceAreasChange = (value) => {
    setSelectedCompetenceAreas(value);
    methods.setValue("competenceAreas", value);
    if (value.length > 0) {
      methods.trigger("competenceAreas");
    }
  };

  useEffect(() => {
    if (!open) {
      methods.reset({ ...getDefaultValues() });
      document.body.style.overflow = '';
    } else if (open) {
      const {
        tender_name,
        Description,
        more_info_url,
        end_date,
        main_buyer_id,
        start_date,
        posting_date,
        last_offer_date,
        value,
        additional_periods,
        competence_areas,
      } = tender;

      methods.reset({
        tenderName: tender_name,
        mainBuyerId: main_buyer_id,
        description: Description,
        moreInfoURL: more_info_url,
        postingDate: dayjs(new Date(posting_date)),
        lastOfferDate: dayjs(new Date(last_offer_date)),
        startDate: dayjs(new Date(start_date)),
        endDate: dayjs(new Date(end_date)),
        faID: faID,
        value: value,
        additionalPeriods: additional_periods,
        competenceAreas: competence_areas,
      });
    
      setFaID(tender?.FrameworkAgreement?.fa_id);
      setAdditionalPeriods(tender?.additional_periods);
      const tempPeriod = [...initialPeriod];
      tempPeriod[0].startDate = tender?.start_date;
      tempPeriod[0].endDate = tender?.end_date;
      setInitialPeriod(tempPeriod);
      setSelectedCompetenceAreas(tender?.competence_areas);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  useEffect(() => {
    if (competences && Array.isArray(competences.competences)) {
      const allCompetences = competences.competences.map(item => ({
        value: item.id,
        label: item.name,
        ...item,
      }));

      setCompetencesArray(allCompetences);
    }
  }, [competences]);

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Edit</AccordionListItem>
      {open && (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#F3F4F6',
          justifyContent: 'center',
          display: open ? 'flex' : 'none',
          zIndex: 40,
          alignItems: 'flex-start',
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: '100%',
            height: `calc(100% - 8 * 26.25px)`,
            marginTop: 26.25,
            overflowY: 'auto',
            backgroundColor: '#fff',
          }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <PaperCard
              height={69}
              sx={{
                background: '#fff',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                px: 4,
                py: 2,
                border: 0,
                borderRadius: 0,
                boxShadow: 26,
                display: 'flex',
                boxSizing: 'border-box',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography>Editing ‘{tender?.tender_name}’</Typography>
                <Box
                  sx={{
                    width: 165,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="medium"
                    onClick={() => setOpen(false)}
                    variant="contained"
                    sx={({ palette }) => ({
                      width: 82,
                      boxShadow: 25,
                      color: palette.primary.main,
                      backgroundColor: '#fff',
                    })}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ width: 67 }}
                  >
                    Save
                  </Button>
                </Box>
              </Stack>
            </PaperCard>

            <Stack
              direction="row"
              width="100%"
              minHeight={`calc(100vh - 8 * 26.25px)`}
              height="100%"
              bgcolor={'#fff'}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width={'50%'} direction="column" px={8} py={4}>
                <TextField
                  name="description"
                  label="Description"
                  placeholder="Please enter description"
                  type="text"
                  fullWidth
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root, .MuiInputBase-input': {
                      p: 0,
                    },
                  }}
                  multiline
                  minRows={2}
                />
              </Stack>

              <Stack
                width={'50%'}
                direction="column"
                px={8}
                py={4}
                gap={2}
              >
                <TextField
                  name="tenderName"
                  label="Tender Name"
                  placeholder="Please enter Tender name"
                  required={true}
                  type="text"
                  fullWidth
                />
                <NumericFormat
                  name="value"
                  label="Value"
                  placeholder="Please enter value"
                  type="input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        MSEK
                      </InputAdornment>
                    )
                  }}
                />
                <OnLoadSelect
                  label={"Related Agreement"}
                  fullWidth
                  onChange={handleAgreementChange}
                  ItemCard={(props) => <ItemCard {...props} />}
                  options={agreements}
                  value={tender?.FrameworkAgreement}
                  search_key='fa_name'
                  unique_key='fa_id'
                />
                <MultiSelect multiple value={selectedCompetenceAreas} onChange={handleCompetenceAreasChange} placeholder="Please select" required fullWidth name="competenceAreas" label="Competence Areas" options={competencesArray} />
                <TextField
                  name="moreInfoURL"
                  label="More Information URL"
                  placeholder="Please enter URL with more information"
                  type="text"
                  fullWidth
                />
                <Stack
                  width={'100%'}
                  direction="row"
                  gap={2}
                >
                  <DateInput
                    name="postingDate"
                    label="Posting Date"
                    required
                    fullWidth
                  />
                  <DateInput
                    name="lastOfferDate"
                    label="Last Offer Date"
                    required
                    fullWidth
                  />
                </Stack>
                <AdditionalPeriods initialPeriod={initialPeriod} setInitialPeriod={setInitialPeriod} additionalPeriods={additionalPeriods} handleAdditionalPeriods={handleAdditionalPeriods} methods={methods} tender />
              </Stack>
            </Stack>
            </LocalizationProvider>
          </FormProvider>
        </Stack>
      </Box>
      )}
    </>
  );
};
