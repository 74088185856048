//@ts-nocheck
import { useQuery } from 'react-query';
import qs from 'qs';

import { axios } from 'lib/axios';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: false,
  includeExpired: false,
  sortBy: 'dateCreated',
  queryKey: ['tenders'],
  queryOptions: {},
};

const getAllTenders = async (queryStringParams, companyID) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const { data } = await axios.get(`/api/tenders?${queryString}`, {headers: {"company_id": companyID}});

  return data;
};

export const useTenders = (queryParams = {}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions, page, limit, search, includeInactive, acceptingOffers, sortBy  } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    includexpired: !acceptingOffers,
    sortby: sortBy,
    offset: page * limit,
  };

  return useQuery([...queryKey], () => getAllTenders(queryStringParams, user.Company.company_id), {
    select: ({ data: tenders, total }) => ({ tenders, total }),
    initialData: {
      tenders: [],
      total: 0,
    },
    ...queryOptions,
  });
};
