import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { AddButton } from 'components/Button';
import { useForm } from 'react-hook-form';

import { MainInfo } from './MainInfo';
import { AdditionalDetails } from './AdditionalDetails';
import { useCreateTender } from '../api/createTender';
import { useValidateRefNumber } from '../api/validateReferenceNumber';
import { mainInfoSchemaFull } from '../validation';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { TextField, VATNumber } from 'components/Form';
import { IconWrapper } from 'components/Icon';
import { validateVAT } from 'lib';
import { useAuth } from 'lib/auth';


const getDefaultFormValues = () => ({
  tenderName: '',
  mainBuyer: '',
  refNumber: '',
  description: '',
  startDate: null,
  endDate: null,
  postingDate: null,
  lastOfferDate: null,
  moreInfoURL: '',
  value: null,

  mainBuyerName: '',
  VAT: '',
  confirmVat: '',
  additionalPeriods: [],
  competenceAreas: [],
});

const NoOptionsFoundButton = ({ onClick, color, sx }) => (
  <Button variant="contained" color={color} onClick={onClick} sx={sx}>
    Add shadow buyer
  </Button>
);

const CustomEndIcon = () => (
  <IconWrapper
    icon="plus"
    sx={{
      height: 24,
      svg: { width: 16, height: 16, color: 'primary.main', stroke: 'white', strokeWidth: 1.33 },
    }}
  />
);

export const Add = ({ refetchTenders }) => {
  const { user } = useAuth();
  const [step, setStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [addingShadowBuyer, setAddingShadowBuyer] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    resetStep();
  };
  const resetStep = () => setStep(0);

  const { mutate } = useCreateTender();
  const methods = useForm({
    defaultValues: getDefaultFormValues(),
    resolver: useYupValidationResolver(mainInfoSchemaFull(step, addingShadowBuyer)),
  });

  const {
    refetch: refetchValidateRefNumber
  } = useValidateRefNumber({
    refNumber: methods.getValues("refNumber"),
    mainBuyerID: methods.getValues("mainBuyer"),
    queryOptions: {
      enabled: false,
    },
  });

  const handleMainBuyerSelect = (mainBuyer) => {
    if (mainBuyer?.company_id) setAddingShadowBuyer(false);
    methods.setValue('mainBuyer', +mainBuyer?.company_id || '', { shouldValidate: true });
  };

  const onSubmit = async (values) => {
    if (step === 0) {
      if (addingShadowBuyer) {
        const isVATNumberValid = await validateVAT('SE', values.VAT);
        if (isVATNumberValid) {
          setStep(step + 1);
        } else {
          methods.setError('VAT', { type: 'custom', message: 'Invalid VAT number' });
        }
      } else {
        await methods.trigger("refNumber");
        await methods.trigger("mainBuyer");
        const { data: refNumberValid } = await refetchValidateRefNumber();
        if (!refNumberValid) {
          methods.setError('refNumber', { type: 'custom', message: 'This Reference Number already exist' });
        } else {
          setStep(step + 1);
        }
      }
    } else {
      values.last_updated_user_id = user.user_id;
      mutate(values, {
        onSuccess: () => {
          refetchTenders();
          handleCloseModal();
          resetStep();
          methods.reset();
        },
      });
    }
  };

  useEffect(() => {
    if (!openModal) {
      methods.reset({ ...getDefaultFormValues() });
      setAddingShadowBuyer(false);
    }
  }, [openModal]);
  useEffect(() => {
    if (addingShadowBuyer) methods.trigger('mainBuyer');
  }, [addingShadowBuyer]);

  useEffect(() => {
    if (step === 1) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    }
  }, [step]);

  const notFound = ({color = "primary", sx = {}}) => (
    <NoOptionsFoundButton
      onClick={() => {
        setAddingShadowBuyer(true);
      }}
      color={color}
      sx = {sx}
    />
  );

  const handleChange = (input) => (e) => {
    methods.setValue(input, e.target.value);
  }

  return (
    <>
      <AddButton
        fullWidth
        onClick={handleOpenModal}
        sx ={({ palette }) => ({
          svg: { mt: -0.5 },
          minWidth: 200,
          border: `1px solid ${palette.grey[200]}`,
        })}
        color='secondary'
      >
        Add Tender
      </AddButton>

      {step === 0 && (
        <MainInfo
          open={openModal}
          handleCloseModal={handleCloseModal}
          methods={methods}
          onSubmit={onSubmit}
          handleMainBuyerSelect={handleMainBuyerSelect}
          NoOptionsFoundButton={notFound}
          mainBuyerDisabled = {addingShadowBuyer ? true : false}
        >
          {addingShadowBuyer ? (
            <>
              <TextField required fullWidth name="mainBuyerName" label="Buyer name" placeholder="Please enter Buyer name" sx={{ mb: 2 }} />
              <VATNumber
                name="VAT"
                label="VAT Number"
                required
                error={Boolean(methods?.formState?.errors?.VAT)}
                helperText={methods?.formState?.errors?.VAT?.message}
                onChange={handleChange("VAT")}
                sx={{ mb: 2 }}
              />
              <VATNumber name="vatNumberConfirmation" label="Verify VAT number" required onChange={handleChange("vatNumberConfirmation")} sx={{ mb: 2 }} />
            </>
          ) : (
            <></>
          )}
        </MainInfo>
      )}
      {step === 1 && (
        <AdditionalDetails
          methods={methods}
          onSubmit={onSubmit}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};
