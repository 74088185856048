import React, { useState } from 'react';

import { Modal } from 'components/Modal';
import { useParams } from 'react-router-dom';
import { useInviteUser } from 'lib/inviteUser';
import { AddButton } from 'components/Button';
import { IconWrapper } from 'components/Icon';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, TextField } from 'components/Form';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { inviteUserSchema } from '../validation';
import { useAuth } from 'lib/auth';

const CustomEndIcon = () => (
  <IconWrapper
    icon="plus"
    sx={{
      height: 24,
      svg: { width: 16, height: 16, color: 'primary.main', stroke: 'white', strokeWidth: 1.33 },
    }}
  />
);

const getDefaultValues = () => ({
  firstName: '',
  lastName: '',
  companyEmail: '',
  companyName: '',
});

export const InviteUserModal = ({handleSuccess}) => {
  const { id } = useParams();
  const { account } = useAuth();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    companyEmail: '',
    accountID: account.account_id
  });

  const handleChange = input => e => {
    setValues({ ...values, [input]: e.target.value });
  }

  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(inviteUserSchema),
    values: values,
  });

  const { mutate: sendInvitation } = useInviteUser(+id);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    methods.reset(setValues(getDefaultValues()));
    setOpen(false);
  };

  const onSuccess = () => {
    handleSuccess();
    handleClose();
  };

  const onSubmit = async (values) => {
    return sendInvitation(values, { onSuccess });
  }; 

  return (
    <>
      <AddButton
        fullWidth
        onClick={handleOpen}
        sx= {({ palette }) => ({
          svg: { mt: -0.5 },
          border: `1px solid ${palette.grey[200]}`,
        })}
        color='secondary'
      >
        Invite Contact
      </AddButton>
      <Modal title="Invite Contact" open={open} handleClose={handleClose}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack>
            <TextField
              name="firstName"
              label="First Name"
              placeholder="Please enter user's first name"
              required={true}
              type="text"
              value={values.firstName}
              onChange={handleChange('firstName')}
              sx={{ mb: 2 }}
              fullWidth
            />
            <TextField
              name="lastName"
              label="Last Name"
              placeholder="Please enter user's last name"
              required={true}
              type="text"
              value={values.lastName}
              onChange={handleChange('lastName')}
              sx={{ mb: 2 }}
              fullWidth
            />
            <TextField
              name="companyEmail"
              label="User's work e-mail"
              placeholder="Please enter user's work e-mail"
              required={true}
              type="email"
              value={values.companyEmail}
              onChange={handleChange('companyEmail')}
              sx={{ mb: 2 }}
              fullWidth
            />
            </Stack>
            <Stack direction="row" gap={3} justifyContent="center">
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Invite
              </Button>
            </Stack>
        </FormProvider>
      </Modal>
    </>
  );
};
