import React, { useEffect, useState, useRef } from 'react';
import { Button, Stack, Typography, Box } from '@mui/material';

import { PaperCard } from 'components/Card';
import { Error } from './Error';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';
import { Loader } from 'components/Loader';

export const EmailSentForm = ({ values, error, setError }) => {
  const { signup } = useAuth();
  const [ loading, setLoading ] = useState(true);
  const initialLoad = useRef(true);
  useEffect(() => {
    const createUser = async () => {
      try {
        await signup(values);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (initialLoad.current) {
      initialLoad.current = false;
      createUser();
    }
  }, []);

  return (
    <>
      {loading && (
        <Box height={233} sx={{alignContent: "center", mb: 5}}>
          <Loader />
        </Box>
      )}
      {!loading && (
        <>
        {error && (
          <Error />
        )}
        {!error && (
          <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }} sx={{mb:5}}>
            <Stack>
              <Typography variant="h5" sx={{ mb: 3, textAlign: "center" }}>
                Almost done!
              </Typography>
              <Stack>
                <Typography variant="body1">We sent an e-mail to Delegon's administrator,</Typography>
                <Typography variant="body1">someone will contact you soon.</Typography>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mb: 0.5,
                    bgcolor: "primary.light",
                    '&:hover': {
                      backgroundColor: "primary.light",
                    },
                  }}
                  href={paths.homepage}
                >
                  Return to home
                </Button>
              </Stack>
            </Stack>
          </PaperCard>
        )}
        </>
      )}
    </>
  );
};
