import React, { useState, useEffect } from 'react';
import { Box, Button, Stack, Typography, Divider, Checkbox, FormControlLabel, FormGroup, InputAdornment } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput, NumericFormat, MultiSelect } from 'components/Form';
import { AdditionalPeriods } from 'components/AdditionalPeriod/AdditionalPeriod';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCompetenceAreas } from 'api/getCompetenceAreas';

export const AdditionalDetails = ({ handleCloseModal, methods, onSubmit }) => {
  const [ additionalPeriods, setAdditionalPeriods ] = useState([]);
  const [ initialPeriod, setInitialPeriod ] = useState([
    {
      label: "Initial",
      startDate: null,
      endDate: null,
      status: "used",
    }
  ]);

  const [ selectedCompetenceAreas , setSelectedCompetenceAreas ] = useState([]);
  const [ competencesArray, setCompetencesArray ] = useState([]);
  
  const { data: competences } = useCompetenceAreas();
  
  const handleAdditionalPeriods = (value) => {
    setAdditionalPeriods(value);
    methods.setValue("additionalPeriods", value);
  }

  const handleInitialPeriod = (value) => {
    setInitialPeriod(value);
    methods.setValue("startDate", value[0].startDate);
    methods.setValue("endDate", value[0].endDate);
  }

  const handleCompetenceAreasChange = (value) => {
    setSelectedCompetenceAreas(value);
    methods.setValue("competenceAreas", value);
    if (value.length > 0) {
      methods.trigger("competenceAreas");
    }
  };

  useEffect(() => {
    if (competences && Array.isArray(competences.competences)) {
      const allCompetences = competences.competences.map(item => ({
        value: item.id,
        label: item.name,
        ...item,
      }));

      setCompetencesArray(allCompetences);
    }
  }, [competences]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: '#F3F4F6',
        justifyContent: 'center',
        display: 'flex',
        zIndex: 500,
        alignItems: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          width: '100%',
          height: `calc(100% - 8 * 26.25px)`,
          marginTop: 26.25,
          overflowY: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PaperCard
            height={69}
            sx={{
              background: '#fff',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              px: 4,
              py: 2,
              border: 0,
              borderRadius: 0,
              boxShadow: 26,
              display: 'flex',
              boxSizing: 'border-box',
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography>Adding ‘{methods.getValues('faName')}’</Typography>
              <Box
                sx={{
                  width: 165,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  size="medium"
                  onClick={handleCloseModal}
                  variant="contained"
                  sx={({ palette }) => ({
                    width: 82,
                    boxShadow: 25,
                    color: palette.primary.main,
                    backgroundColor: '#fff',
                  })}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  type="submit"
                  // onClick={onSubmit}
                  variant="contained"
                  color="primary"
                  sx={{ width: 67 }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </PaperCard>

          <Stack
            direction="row"
            width="100%"
            minHeight={`calc(100vh - 8 * 26.25px)`}
            height="100%"
            bgcolor={'#fff'}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Stack width={'50%'} direction="column" px={8} py={4}>
              <TextField
                name="description"
                label="Description"
                placeholder="Please enter description"
                type="text"
                fullWidth
                sx={{
                  mb: 2,
                  '& .MuiInputBase-root, .MuiInputBase-input': {
                    p: 0,
                  },
                }}
                multiline
                minRows={2}
              />
            </Stack>

            <Stack width={'50%'} direction="column" px={8} py={4}>
              <TextField
                name="faName"
                label="Framework Agreement Name"
                placeholder="Please enter Framework agreement name"
                required
                type="text"
                sx={{ mb: 2 }}
                fullWidth
              />
              <NumericFormat
                name="value"
                label="Value"
                placeholder="Please enter value"
                type="input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      MSEK
                    </InputAdornment>
                  )
                }}
                sx={{ mb: 2 }}
              />
              <MultiSelect multiple value={selectedCompetenceAreas} onChange={handleCompetenceAreasChange} placeholder="Please select" required fullWidth name="competenceAreas" label="Competence Areas" options={competencesArray} sx={{ mb: 2 }} /> 
              <TextField
                name="moreInfoURL"
                label="More Information URL"
                placeholder="Please enter Link to external website"
                type="text"
                sx={{ mb: 2 }}
                fullWidth
              />
              <AdditionalPeriods initialPeriod={initialPeriod} setInitialPeriod={handleInitialPeriod} additionalPeriods={additionalPeriods} handleAdditionalPeriods={handleAdditionalPeriods} methods={methods} />
            </Stack>
          </Stack>
          </LocalizationProvider>
        </FormProvider>
      </Stack>
    </Box>
  );
};
