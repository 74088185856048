import React, { useEffect, useState, useRef } from 'react';
import { Box, Stack, Button, Typography, AppBar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import DelegonMainLogoWhite from '../../website/assets/Delegon_Main_Logo_WHITE.png';

import { paths, ROOT_PATHS } from 'routes';
import { IconWrapper } from 'components/Icon';

export const MobileMenu = ({menuItems, onClose}) => {
  const mobileOnly = [
    { label: "Login", value: "login", path: paths.auth.login },
    { label: "Sign up (free account)", value: "register", path: paths.auth.register }
  ];
  const mobileMenu = [...menuItems, ...mobileOnly];

  return (
    <Stack
      sx={{
        height: "calc(100vh - env(safe-area-inset-bottom))",
        pb: 6,
        px: 1,
        boxSizing: "border-box",
        width: 1,
        backgroundColor: "background.primary",
        position: "fixed",
        right: 0,
        top: 0,
        zIndex: 10,
        display: {xs: "flex", md: "none"},
        overflow: "hidden"
      }}
    >
      <Stack
        display={"flex"}
        direction={"column"}
        sx={{
          height: 1,
          alignItems: "start",
          justifyContent: "flex-start",
        }}
      >
        <Stack
          width={1}
          display={"flex"}
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            boxSizing: "border-box"
          }}
        >
          <Box
            sx={{
              '.small-logo': { marginLeft: "-15px" },
            }}
            component={RouterLink}
            to={ROOT_PATHS.WEBSITE}
            onClick={() => onClose()}
          >
            <img
              className="small-logo"
              src={DelegonMainLogoWhite}
              alt={'Delegon'}
              loading="lazy"
              style={{
                width: 150,
                flexShrink: 0,
              }}
            />
          </Box>
          <Box display={"flex"} height={35} onClick={onClose}>
            <IconWrapper
              icon="xMark"
              sx={{
                height: 35,
                width: 35,
                svg: {
                  color: "white",
                  stroke: 'white',
                  width: 35,
                  height: 35,                
                },
              }}
            />
          </Box>
        </Stack>
        <Stack
          display={{ xs: "flex", md: "flex"}}
          gap={4.5}
          flexDirection={"column"}
          sx={{
            "& > div > a": {
              textDecoration: "none",
              color: "#fff",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: 1.5,
            },
            boxSizing: "border-box",
            px: 3,
            py: 3,
          }}
        >
          {mobileMenu.map((item) => (
            <Box
              gap={1}
              key={item.value}
              sx={{
                height: 32,
                position: "relative",
                "&:hover": {
                  "&::after": {
                    width: "50%",
                  },
                },
              }}
            >
              <Typography
                key={item.value}
                component={RouterLink}
                to={item.path}
                onClick={() => onClose()}
              >
                {item.label}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}