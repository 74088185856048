import React from 'react';
import { Box, Stack, Tooltip } from '@mui/material';

export const Status = ({ status }) => {
  return (
    <Stack justifyContent="center" alignItems={"center"} width={50} /* flex={0.3} */>
      <Tooltip
        arrow
        title={`${status}`}
        placement="top"
        slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
      >
        <Stack direction="row" alignItems="center" gap={1} height={0.5}>
          <Box
            borderRadius="100%"
            width={8}
            height={8}
            border="1px solid"
            borderColor={status === 'used' ? 'success.light' : status === 'NA' ? 'grey.400' : 'error.light'}
            bgcolor={status === 'used' ? 'success.light' : status === 'NA' ? 'grey.400' : 'error.light'}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
