import React from 'react';
import { Pagination as MuiPagination, PaginationItem, Stack, Typography } from '@mui/material';
import { IconWrapper } from 'components/Icon';

const Next = () => (
  <Stack direction="row" gap={1} alignItems="center">
    <Typography variant="caption" color="primary" fontWeight={700}>
      Next
    </Typography>
    <IconWrapper icon="chevronRight" sx={{ svg: { width: 20, height: 20 } }} />
  </Stack>
);
const Previous = (props) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <IconWrapper icon="chevronLeft" sx={{ svg: { width: 20, height: 20 } }} />
      <Typography variant="caption" color="primary">
        Prev
      </Typography>
    </Stack>
  );
};

export const Pagination = ({ handlePageChange, itemCount = 0, itemsPerPage = 10, page = 1 }) => {
  const pageCount =
    itemCount % itemsPerPage === 0
      ? Math.floor(itemCount / itemsPerPage)
      : Math.floor(itemCount / itemsPerPage) + 1;

  return (
    <MuiPagination
      sx={({ palette, shadows }) => ({
        button: { boxShadow: shadows[25], border: 'none' },
        '& > ul .MuiPaginationItem-ellipsis': {
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          pb: 0.3,
          color: palette.grey[500],
          height: '32px',
          boxShadow: shadows[25],
          borderRadius: 1,
        },
        '& > ul > li:first-of-type > button, & > ul > li:last-child > button': {
          border: 'none',
          boxShadow: 'none',
        },
        '& > ul :not(.Mui-selected) > button, & > ul button.Mui-disabled *': {
          color: `${palette.grey[500]} !important`,
        },
        '& > ul button.Mui-selected': {
          color: `${palette.primary.main} !important`,
        },
      })}
      page={page}
      count={pageCount}
      onChange={handlePageChange}
      shape="rounded"
      color="primary"
      variant="outlined"
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: Previous,
            next: Next,
          }}
          {...item}
        />
      )}
    />
  );
};
