import React, { useEffect, useState, useMemo } from 'react';
import { WebsiteRoutes } from 'website/routes';
import { MainLayout } from 'website/components/Layout/components/MainLayout';
import { ROOT_PATHS } from './paths';
import { useLocation } from 'react-router-dom';

function WebsiteWrapper() {
  const location = useLocation();
  const [isPricing, setIsPricing] = useState(false);
  const initialVersion = useMemo(() => {
    if (location.pathname.endsWith('/pricing')) {
      setIsPricing(true);
    } else {
      setIsPricing(false);
    }

    if (location.state && location.state.websiteContext) {
      return location.state.websiteContext;
    }

    if (location.pathname.startsWith(`${ROOT_PATHS.WEBSITE}/buyers`)) {
      return 'buyers';
    }
    return 'suppliers';
  }, [location]);

  const [websiteContext, setWebsiteContext] = useState(initialVersion);

  useEffect(() => {
    setWebsiteContext(initialVersion);
  }, [initialVersion]);

  return (
    <MainLayout websiteContext={websiteContext} isPricing={isPricing}><WebsiteRoutes /></MainLayout>
  );
}

export const websiteRoutes = [
  {
    path: `${ROOT_PATHS.WEBSITE}/*`,
    element: <WebsiteWrapper />,
  },
];